import SharedClient from "#api.shared/client";
import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { GAPInsuranceDecisionInputDto } from "#components/provider/AddonProvider/AddonTypes";
import { restPaths } from "#constants/restPaths";

import { HTTPPostGAPBody, LOSAPIResponse } from "./client.types";

/**
 * Retrieves addons from an application
 * @param applicationId application to specify for GAP
 * @param payload payload for posting to GAP
 * @returns ok/error tuple. Value of tuple is the HTTP response details in either case
 */
const postGAP: (
  applicationId: string,
  payload: GAPInsuranceDecisionInputDto,
) => LOSAPIResponse<HTTPPostGAPBody> = async (
  applicationId: string,
  payload: GAPInsuranceDecisionInputDto,
) => {
  SharedClient.tracking.post({
    event: `${payload.elected ? "SELECTED" : "DECLINED"}_GAP_ADDON`,
    details: { applicationId },
  });
  const response = await makeRequest<HTTPPostGAPBody>(
    HTTPMethod.POST,
    restPaths.application(applicationId).GAP.post,
    { body: payload },
    true,
  );

  if (!response.error) {
    return { response, error: null };
  }

  return { response: null, error: response };
};

export default postGAP;
