import { HTTPGetBETasksRes } from "#api.los/application.getTasks";
import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { LOSAPIResponse } from "./client.types";
/**
 * Gets tasks for current application
 * @param applicationId loan application ID for the current application (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const setTaskAsPending: (
  appId: string,
  taskId: string,
) => LOSAPIResponse<HTTPGetBETasksRes> = async (appId, taskId) => {
  const response = await makeRequest<HTTPGetBETasksRes>(
    HTTPMethod.PUT,
    restPaths.task(appId).setTaskAsPending,
    { body: { taskId } },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default setTaskAsPending;
