/** ******************************************************************************
Get Loan Offers
 * uses the /applications/${applicationId}/offers endpoint to get loan offers
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPModelsIsFinanceSupported, LOSAPIResponse } from "./client.types";

/**
 * Gets all UVCInfo rows
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
export interface Params {
  vin?: string;
}
const isFinanceSupported: (
  params: Params,
) => LOSAPIResponse<HTTPModelsIsFinanceSupported> = async (params) => {
  let queryParams = "?";
  Object.keys(params).map((key) => {
    if (params[key as keyof Params]) {
      queryParams += `${key}=${params[key as keyof Params]}&`;
    }
    return key;
  });

  const url = `${restPaths.models.isFinanceSupported}${queryParams}`;
  const response = await makeRequest<HTTPModelsIsFinanceSupported>(
    HTTPMethod.GET,
    url,
    {},
    false,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default isFinanceSupported;
