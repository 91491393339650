/** ******************************************************************************
Update User Referral information
 * uses the /referral (update referral) endpoint to update a user's referral information
 * will not update if referral information already exists
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";
import ReferralService from "#services/ReferralService";

import { HTTPUpdateUserRes, LOSAPIResponse } from "./client.types";

/**
 * Updates/sets a user's referral info
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const updateReferrer: () => LOSAPIResponse<HTTPUpdateUserRes> = async () => {
  const referralCode = ReferralService.getReferralCode();

  if (!referralCode) {
    return { response: null, error: null };
  }

  const response = await makeRequest<HTTPUpdateUserRes>(
    HTTPMethod.PUT,
    restPaths.user.updateReferrer,
    {
      body: { referralCode },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default updateReferrer;
