/** ******************************************************************************
Update User Email
 * uses the /user/update-email endpoint to update a user's email
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPUpdateEmailRes, LOSAPIResponse } from "./client.types";

/**
 * Updates a user's email address
 * @param newEmail email address to update to (string, format is jordon@tenet.com)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const updateEmail: (newEmail: string) => LOSAPIResponse<HTTPUpdateEmailRes> =
  async (newEmail) => {
    const response = await makeRequest<HTTPUpdateEmailRes>(
      HTTPMethod.PUT,
      restPaths.user.updateEmail,
      {
        body: { newEmail },
      },
      true,
    );
    if (!response.error) {
      return { response, error: null };
    }
    return { response: null, error: response };
  };

export default updateEmail;
