import { experimentsConfigBase } from "#services/ExperimentService/experiments.constants";
import { ExperimentsConfig } from "#services/ExperimentService/experiments.types";

// Extracts the event names from the config

export const experimentsConfig: ExperimentsConfig = experimentsConfigBase;

type EventConfig = {
  [key in keyof typeof experimentsConfigBase]: typeof experimentsConfigBase[key]["events"];
};

export const eventConfig = Object.entries(
  experimentsConfig,
).reduce<EventConfig>((acc, [experiment, config]) => {
  if (!config.events) {
    return acc;
  }

  return {
    ...acc,
    [experiment]: config.events,
  };
}, {} as EventConfig);
