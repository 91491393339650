import { useContext, useEffect, useMemo } from "react";
import { createRegisteredContext } from "react-singleton-context";

import { TypeOfHome } from "#api.los/losDto.types";
import { useAddonProviderFunctions } from "#components/provider/AddonProvider/AddonProvider.function";
import { useAddonsProviderStateController } from "#components/provider/AddonProvider/AddonProvider.state";
import {
  AddonProviderFunctions,
  AddonProviderLoading,
  AddonProviderState,
  AddonProviderStateController,
  CreateChargerPricing,
} from "#components/provider/AddonProvider/AddonTypes";
import { useApplicationContext } from "#components/provider/ApplicationProvider/ApplicationProvider";

type AddonProviderContext = Omit<
  AddonProviderStateController,
  "set" | "setLoading"
> & {
  addonFunctions: AddonProviderFunctions;
};

const defaultProvider: AddonProviderContext = {
  state: {
    initialized: false,
  },
  loading: {
    addonsLoading: false,
    gapLoading: false,
    evppLoading: false,
    chargerInstallLoading: false,
    chargerLoading: false,
    all: false,
  },
  addonFunctions: {
    /* eslint-disable */
    addVehicleVin: async (vin: string) => false,
    clearAllLoading: () => undefined,
    refreshAddons: async () => undefined,
    selectChargerInstallAddon: async () => undefined,
    selectEvppAddon: async () => undefined,
    selectGapAddon: async () => undefined,
    selectChargerAddon: async () => undefined,
    createChargerInstallEstimate: async (_params: {
      typeOfHome: TypeOfHome;
      wireRunLength: number;
      selectedCharger: boolean;
      appliancesInHome: string[];
    }) => await undefined,
    createChargerPricing: async (_params: CreateChargerPricing) =>
      await undefined,
    /* eslint-enable */
  },
};

const AddonContext = createRegisteredContext<AddonProviderContext>(
  "addon-provider-context",
  defaultProvider,
);

export const AddonProvider = ({
  children,
  activeStep,
  alwaysRefresh = false,
}: {
  children: React.ReactNode;
  activeStep?: string;
  alwaysRefresh?: boolean;
}) => {
  const { state: appState } = useApplicationContext();
  const stateController = useAddonsProviderStateController();
  const addonFunctions = useAddonProviderFunctions(stateController);

  const value = useProviderInterface(
    stateController.state,
    addonFunctions,
    stateController.loading,
  );

  useEffect(() => {
    if (activeStep) {
      // eslint-disable-next-line no-console
      console.log("AddonProvider instantiated: ", activeStep);
    }
  }, [activeStep]);

  useEffect(() => {
    if (
      appState.applicationId &&
      stateController.state.initialized &&
      appState.application?.flags.hasPassedPrequal &&
      (!stateController.state?.gap ||
        !stateController.state?.evppPlan ||
        alwaysRefresh)
    ) {
      addonFunctions.refreshAddons();
    }
  }, [
    stateController.state?.initialized,
    appState.applicationId,
    appState.application?.flags.hasPassedPrequal,
  ]);

  return (
    <AddonContext.Provider value={value}>{children}</AddonContext.Provider>
  );
};

const useProviderInterface = (
  state: AddonProviderState,
  addonFunctions: AddonProviderFunctions,
  loading: AddonProviderLoading,
): AddonProviderContext => {
  return useMemo(
    () => ({
      state,
      addonFunctions,
      loading,
    }),
    [
      state.chargerInstall,
      state.charger,
      state.evppPlan,
      state.gap,
      state.selectedChargerInstall,
      state.selectedEvpp,
      state.selectedGap,
      loading.addonsLoading,
      loading.chargerInstallLoading,
      loading.chargerLoading,
      loading.evppLoading,
      loading.gapLoading,
    ],
  );
};

export const useAddonContext = () => useContext(AddonContext);
