import { OfferOption } from "./FinalLoanSummary.types";

export const getTotalInterestWithAddons = (offer?: OfferOption) => {
  return offer?.totalInterestCents || 0;
};

export const getTotalLoanValueWithAddonsWithoutInterest = (
  offer?: OfferOption,
) => {
  return offer?.principalCents || 0;
};
