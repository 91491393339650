export const screenSizes = {
  minWidths: {
    tabletLarge: 1024,
    tabletSmall: 620,
    desktopLarge: 1440,
    desktopSmall: 1080,
  },
  minHeights: {
    desktopSmall: 960,
  },
} as const;

export const media = {
  minPhoneHeight: `(min-height: ${screenSizes.minWidths.tabletSmall}px)`,
  minDesktopHeight: `(min-height: ${screenSizes.minHeights.desktopSmall}px)`,
  minDesktopLarge: `(min-width: ${screenSizes.minWidths.desktopLarge}px)`,
  minDesktopSmall: `(min-width: ${screenSizes.minWidths.desktopSmall}px)`,
  minTabletLarge: `(min-width: ${screenSizes.minWidths.tabletLarge}px)`,
  minTabletSmall: `(min-width: ${screenSizes.minWidths.tabletSmall}px)`,
};

export type Breakpoints = keyof typeof media;
