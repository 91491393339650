/**
 * Fonts files reside in the `/public` directory, and are loaded via
 * `global.css` file in the same folder. The font names used here should
 * correspond to the names of the font-families loaded in `global.css`.
 */
const matter = "MatterSQ";
const matterMono = "MatterSQMono";

const fonts = {
  body: matter,
  headline: matter,
  label: matterMono,
};

export default fonts;
