import { LoanType } from "#components/partial/ApplyPage/ApplyPage.types";

import {
  NEW_APPLICATION_URL,
  NEW_PURCHASE_APPLICATION_URL,
  NEW_REFINANCE_APPLICATION_URL,
} from "./pageNavigationHelpers.constants";
import { Page } from "./pageNavigationHelpers.page";
import { LocationType } from "./pageNavigationHelpers.types";

const pageInfo = [
  {
    key: Page.HOME,
    stub: "",
    type: LocationType.landing,
  },
  {
    key: Page.NEW_LOAN_REDIRECTOR,
    stub: "new-loan",
    type: LocationType.landing,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.APPLY,
    stub: "apply",
    type: LocationType.origination,
  },
  {
    key: Page.APPLY_REDIRECTOR,
    stub: "apply",
    type: LocationType.landing,
  },
  {
    key: Page.NEW_APPLICATION,
    stub: NEW_APPLICATION_URL,
    type: LocationType.landing,
  },
  {
    key: Page.NEW_PURCHASE_APPLICATION,
    stub: NEW_PURCHASE_APPLICATION_URL,
    type: LocationType.landing,
  },
  {
    key: Page.NEW_REFINANCE_APPLICATION,
    stub: NEW_REFINANCE_APPLICATION_URL,
    type: LocationType.landing,
  },
  {
    key: Page.REFI_REDIRECTOR,
    stub: "refinance-redirector",
    type: LocationType.landing,
    flowSpecific: LoanType.Refinance,
  },
  {
    key: Page.PORTAL_OVERVIEW,
    stub: "home",
    type: LocationType.portal,
  },
  {
    key: Page.PORTAL_SETTINGS,
    stub: "settings",
    type: LocationType.portal,
  },
  {
    key: Page.RESUME_LOAN_FORM,
    stub: "resume-loan",
    type: LocationType.origination,
  },
  {
    key: Page.START_APPLICATION,
    stub: "start-application",
    type: LocationType.origination,
  },
  {
    key: Page.SERVICE_UPDATE_FORM,
    stub: "service-update",
    type: LocationType.origination,
  },
  {
    key: Page.SIGNUP_FORM,
    stub: "sign-up",
    type: LocationType.origination,
  },
  {
    key: Page.EMAIL_VERIFICATION,
    stub: "email-verification",
    type: LocationType.origination,
  },
  {
    key: Page.PERSONAL_INFO_FORM,
    stub: "personal-info",
    type: LocationType.origination,
  },
  {
    key: Page.HOUSING_INFO_FORM,
    stub: "housing-info",
    type: LocationType.origination,
  },
  {
    key: Page.BUSINESS_INFO,
    stub: "business-info",
    type: LocationType.origination,
  },
  {
    key: Page.BUSINESS_OWNERSHIP,
    stub: "business-ownership",
    type: LocationType.origination,
  },
  {
    key: Page.EMPLOYMENT_INFO_FORM,
    stub: "employment-info",
    type: LocationType.origination,
  },
  {
    key: Page.APPLICATION_SUMMARY,
    stub: "application-summary",
    type: LocationType.origination,
  },
  {
    key: Page.SOFT_CREDIT_CONSENT,
    stub: "soft-credit-consent",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.SOCIAL_SECURITY_FORM,
    stub: "social-security-form",
    type: LocationType.origination,
  },
  {
    key: Page.SOFT_CREDIT_ERROR,
    stub: "soft-credit-error",
    type: LocationType.origination,
  },
  {
    key: Page.INTENT,
    stub: "intent",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.WAITLIST,
    stub: "waitlist",
    type: LocationType.origination,
  },
  {
    key: Page.ORDER_INFO,
    stub: "order-info",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.MMYT_MAKE,
    stub: "mmyt-make",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.MMYT_MODEL,
    stub: "mmyt-model",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.MMYT_TRIM,
    stub: "mmyt-trim",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.PREAPPROVAL_DECLINE,
    stub: "preapproval-decline",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.PREAPPROVAL_SUCCESS,
    stub: "preapproval-success",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.NEW_VEHICLE_INFO_FORM,
    stub: "new-vehicle-info",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.PASSTHROUGH_OFFERS,
    stub: "passthrough-offers",
    type: LocationType.origination,
  },
  {
    key: Page.OFFER_TERM_EDIT_FORM,
    stub: "offer-term-form",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  { key: Page.NO_LOANS, stub: "no-loans", type: LocationType.origination },
  {
    key: Page.OFFER_DEFERRED_PAYMENT_EDIT_FORM,
    stub: "offer-deferred-payment-form",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.CONGRATULATIONS,
    stub: "congratulations",
    type: LocationType.origination,
    flowSpecific: LoanType.Refinance,
  },
  {
    key: Page.FINAL_LOAN_SUMMARY,
    stub: "final-loan-summary",
    type: LocationType.origination,
  },
  {
    key: Page.HARD_CREDIT_FORM,
    stub: "hard-credit-form",
    type: LocationType.origination,
  },
  {
    key: Page.CONFIRMATION_FORM,
    stub: "confirmation-form",
    type: LocationType.origination,
    flowSpecific: LoanType.Purchase,
  },
  {
    key: Page.LOAN_CONTRACT,
    stub: "loan-contract",
    type: LocationType.origination,
  },
  {
    key: Page.LOAN_APP_COMPLETE,
    stub: "loan-app-complete",
    type: LocationType.origination,
  },
  {
    key: Page.REFI_REDIRECTOR,
    stub: "refinance",
    type: LocationType.landing,
    flowSpecific: LoanType.Refinance,
  },
  {
    key: Page.REFI_SOFT_CREDIT_CONSENT,
    stub: "soft-credit",
    type: LocationType.origination,
    flowSpecific: LoanType.Refinance,
  },
  {
    key: Page.REFI_VEHICLE_INFO_FORM,
    stub: "vehicle-info",
    type: LocationType.origination,
    flowSpecific: LoanType.Refinance,
  },
  {
    key: Page.REFI_VEHICLE_CONFIRMATION,
    stub: "vehicle-confirmation",
    type: LocationType.origination,
    flowSpecific: LoanType.Refinance,
  },
  {
    key: Page.REFI_PREVIOUS_LOANS_LIST,
    stub: "select-existing-loan",
    type: LocationType.origination,
    flowSpecific: LoanType.Refinance,
  },
  {
    key: Page.REFI_PREVIOUS_LOAN_INFO,
    stub: "previous-loan-info",
    type: LocationType.origination,
    flowSpecific: LoanType.Refinance,
  },
  {
    key: Page.REFI_LOAN_OFFERS_FORM,
    stub: "loan-offers",
    type: LocationType.origination,
    flowSpecific: LoanType.Refinance,
  },
  { key: Page.VEHICLE, stub: "vehicle", type: LocationType.origination },
  {
    key: Page.ANONYMOUS_EMPLOYMENT,
    stub: "employment",
    type: LocationType.origination,
  },
  {
    key: Page.ANONYMOUS_HOUSING,
    stub: "housing",
    type: LocationType.origination,
  },
  {
    key: Page.ANONYMOUS_ABOUT_YOURSELF,
    stub: "about-yourself",
    type: LocationType.origination,
  },
  {
    key: Page.PREPOPULATE_SUBMIT,
    stub: "app-created",
    type: LocationType.origination,
  },
];

/**
 * gives info about how and when to navigate to a given page
 * e.g. address stub, order, etc
 * @param page where we want to go (MPR: In the future, we should generalize the step to include all pages)
 * @returns
 */
export const getPageNavigationInfo = (page: Page) => {
  const pageInfoItem = pageInfo.find((v) => {
    return v.key === page;
  });
  const pageInfoIndex = pageInfo.findIndex((v) => {
    return v.key === page;
  });
  return {
    stub: pageInfoItem?.stub,
    key: pageInfoItem?.key,
    type: pageInfoItem?.type,
    flowSpecific: pageInfoItem?.flowSpecific,
    order: pageInfoIndex,
  };
};
