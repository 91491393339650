/* eslint-disable
  @typescript-eslint/no-explicit-any,
  @typescript-eslint/no-unsafe-assignment,
  @typescript-eslint/no-unsafe-call,
  @typescript-eslint/no-unsafe-return,
  dot-notation
*/
import type { CssComponent } from "@stitches/react/types/styled-component";
import React from "react";
import * as ReactJSXRuntime from "react/jsx-runtime";

export const Fragment = ReactJSXRuntime?.["Fragment"];

/**
 * `jsx` is a replacement JSX transform for React's own JSX transform, for
 * components that want to use the `css` prop and Stitches-based styles.
 *
 * It is essentially a wrapper for the regular JSX transform, where a check is
 * done for the presence of a `css` prop. If found, it will call it and return
 * the result to React's `jsx` as a classname.
 *
 * This assumes that the value of the given css prop is a return value of a call
 * to Stitch's `css` function.
 *
 * This component is *not* written with JSX, as it is used by the custom pragma
 * that transforms JSX into function calls.
 */
export const jsx = <T extends React.ElementType, P = Record<string, unknown>>(
  type: T,
  props: P &
    Omit<React.ComponentPropsWithoutRef<T>, keyof P> & {
      className?: string;
      css?: CssComponent<T, P> | (undefined | string | CssComponent<T, P>)[];
      cssProps?: Record<string, unknown> & P;
    },
  key: any,
) => {
  if (!props.css) {
    return ReactJSXRuntime["jsx"]?.(type, props, key);
  }

  const { className, css, cssProps, ...otherProps } = props;
  const stitchesClasses = Array.isArray(css)
    ? css
        .filter((entry) => !!entry)
        .map((entry) => (typeof entry === "string" ? entry : entry?.(cssProps)))
        .join(" ")
    : css(cssProps);

  return ReactJSXRuntime["jsx"]?.(
    type,
    {
      ...otherProps,
      className: className
        ? `${className} ${stitchesClasses}`
        : stitchesClasses,
    },
    key,
  );
};

export const jsxs = jsx;

export default jsx;
