/** ******************************************************************************
Create Optional Document Upload Link
 * Because we cannot anticipate how many optional documents a user may upload,
 * we need an endpoint to create them on the fly. This does so, and gives back
 * an upload link for the new document
 ******************************************************************************* */
import { HTTPMethod, HTTPResponse } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import CauseError from "#components/util/CauseError";
import { restPaths } from "#constants/restPaths";

import { DocumentImageData, HTTPGetDocumentUploadLink } from "./client.types";

/**
 * creates a loan user document and returns a link to upload it
 * @param loanApplicationId
 * @param imageData
 * @returns the document id, the upload link, and its s3 key
 */
const createOptionalUploadLink: (
  loanApplicationId: string,
  imageData: DocumentImageData,
  requirement?: string,
) => Promise<{
  response: null | HTTPResponse<HTTPGetDocumentUploadLink>;
  error: null | HTTPResponse<HTTPGetDocumentUploadLink>;
}> = async (loanApplicationId, imageData, requirement) => {
  try {
    const response = await makeRequest<HTTPGetDocumentUploadLink>(
      HTTPMethod.PUT,
      restPaths.document(loanApplicationId).getOptionalUploadLink,
      {
        body: {
          requirement,
          imageData,
        },
      },
      true,
    );
    if (response.error) {
      return { response: null, error: response };
    }
    return { response, error: null };
  } catch (e) {
    throw new CauseError("Unexpected error when updating document status", {
      cause: e as Error,
    });
  }
};

export default createOptionalUploadLink;
