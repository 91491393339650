import colors from "./colors";
import fonts from "./fonts";
import fontSizes from "./fontSizes";
import fontWeights from "./fontWeights";
import letterSpacings from "./letterSpacings";
import lineHeights from "./lineHeights";
import radii from "./radii";
import sizes from "./sizes";
import space from "./space";
import transitions from "./transitions";

const theme = {
  borderStyles: {},
  borderWidths: {},
  colors,
  fontSizes,
  fontWeights,
  fonts,
  letterSpacings,
  lineHeights,
  radii,
  shadows: {},
  sizes,
  space,
  transitions,
  zIndices: {},
};

export default theme;
