import { useCallback, useEffect, useRef } from "react";

import useDebounce from "#hooks/useDebounce/useDebounce";
import usePrevious from "#hooks/usePrevious/usePrevious";
import { css } from "#themes";

const scrollLockStyles = {
  boxSizing: "border-box",
  /**
   * If the iOS Safari footer is hidden when the modal opens, iOS will reveal
   * the footer if the user makes a vertical pan gesture. The 1px offset here
   * prevents this from happening. I don’t know. 🤷‍♂️
   */
  height: "calc(var(--window-inner-height) - 1px)",
  overflow: "hidden",
};

const documentScrollLock = css({
  ...scrollLockStyles,

  "& body": scrollLockStyles,
});

/**
 * When we show a modal, we need to prevent events inside the modal from
 * interacting with the page behind the modal — for example, scroll events on
 * a modal-related element should not cause the underlying page to scroll.
 *
 * The Modal component we're using is built on top of Headless UI
 * (see https://headlessui.dev/), which includes some built-in methods of
 * scroll-locking, but it does not yet account for changes in iOS 15, which
 * breaks existing methods of scroll-prevention.
 *
 * This hook is mostly for providing compatibility with iOS 15, but should work
 * to prevent scrolling on other platforms as well. If this hook is unused
 * elsewhere, the hook may be deprecated if Headles UI is updated to include
 * this fix out-of-the-box.
 */
function usePreventScroll(active = true) {
  const scrollRef = useRef(0);
  const wasActive = usePrevious(active);
  const [innerHeight, setInnerHeight] = useDebounce(
    typeof window !== "undefined" ? window.innerHeight : 0,
    500,
  );

  const setViewportHeight = useCallback(() => {
    setInnerHeight(window.innerHeight);
  }, [setInnerHeight]);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--window-inner-height",
      `${window.innerHeight}px`,
    );
  }, [innerHeight]);

  useEffect(() => {
    if (active) {
      scrollRef.current = window.scrollY;
      window.addEventListener("resize", setViewportHeight);
      document.documentElement.classList.add(documentScrollLock.toString());
      window.document.body.scrollTop = scrollRef.current;
      setViewportHeight();
    } else if (wasActive) {
      window.scrollTo(0, scrollRef.current);
    }

    return () => {
      window.removeEventListener("resize", setViewportHeight);
      document.documentElement.classList.remove(documentScrollLock.toString());
    };
  }, [active, setViewportHeight]); // eslint-disable-line react-hooks/exhaustive-deps
}

export default usePreventScroll;
