import { config, css } from "#themes";

const { colors } = config.theme;

const color = (Object.keys(colors) as Array<keyof typeof colors>).reduce(
  (currVal, key) => ({ ...currVal, [key]: { color: `$${key}` } }),
  {} as Record<keyof typeof colors, Record<"color", "string">>,
);

export const style = css({
  color: "inherit",

  variants: {
    color,
    fontWeight: {
      bold: { fontWeight: "bold" },
      regular: { fontWeight: "regular" },
    },
    textAlign: {
      center: { textAlign: "center" },
      end: { textAlign: "end" },
      justify: { textAlign: "justify" },
      left: { textAlign: "left" },
      right: { textAlign: "right" },
      start: { textAlign: "start" },
    },
    transform: {
      capitalize: { textTransform: "capitalize" },
      lowercase: { textTransform: "lowercase" },
      uppercase: { textTransform: "uppercase" },
    },
    variant: {
      body1: {
        textStyle: "body1",
      },
      body2: {
        textStyle: "body2",
      },
      body3: {
        textStyle: "body3",
      },
      body4: {
        textStyle: "body4",
      },
      body5: {
        textStyle: "body5",
      },
      body6: {
        textStyle: "body6",
      },
      heading1: {
        textStyle: "heading1",
      },
      heading2: {
        textStyle: "heading2",
      },
      heading3: {
        textStyle: "heading3",
      },
      heading4: {
        textStyle: "heading4",
      },
      label: {
        textStyle: "label",
      },
      title1: {
        textStyle: "title1",
      },
      title2: {
        textStyle: "title2",
      },
    },
  },
  length: 0,
});
