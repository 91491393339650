/** ******************************************************************************
Upsert Vehicle Previous Loan
 * uses the /appId/vehicle/upsert endpoint to upsert the previous loan of the vehicle
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPUpsertPrevLoanRes, LOSAPIResponse } from "./client.types";

/**
 * Upserts a vehicle's previous loan information
 * @param applicationId loan application ID for the current application (string)
 * @param prevLoan vehicle's previous loan properties that are to be updated.
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const upsertPrevLoan: (
  applicationId: string,
  prevLoan: {
    totalPrincipalCents: number;
    remainingPrincipalCents: number;
    monthlyPaymentCents: number;
    termLengthMonths: number;
    remainingTermLengthMonths: number;
    apr: number;
  },
) => LOSAPIResponse<HTTPUpsertPrevLoanRes> = async (
  applicationId,
  {
    totalPrincipalCents,
    remainingPrincipalCents,
    monthlyPaymentCents,
    termLengthMonths,
    remainingTermLengthMonths,
    apr,
  },
) => {
  const response = await makeRequest<HTTPUpsertPrevLoanRes>(
    HTTPMethod.PUT,
    restPaths.application(applicationId).vehicle.upsert,
    {
      body: {
        totalPrincipalCents,
        remainingPrincipalCents,
        monthlyPaymentCents,
        termLengthMonths,
        remainingTermLengthMonths,
        apr,
      },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};
export default upsertPrevLoan;
