import LMSAPIClient from "#api.lms/client";
import { HTTPReferralDetailsResponse } from "#api.lms/client.types";

import CreateRESTContextProvider from "./GenericRESTProvider";

interface Props {
  children: React.ReactNode;
}

const { RESTProvider, useREST } =
  CreateRESTContextProvider<HTTPReferralDetailsResponse>();

/**
 * Provides a referally code and other details to enable referrals
 */
export const ReferralProvider = ({ children }: Props) => {
  return (
    <RESTProvider
      storageKey="referralProvider"
      getData={async () => {
        const { response, error } = await LMSAPIClient.referrals.getDetails();
        const invalidReason = error?.statusText;
        const data = response?.body;
        if (error) {
          return { error: invalidReason };
        }
        return { response: data };
      }}
    >
      {children}
    </RESTProvider>
  );
};

export const useReferral = () => {
  return useREST();
};
