import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

/**
 * Tracks Offer Confirmed
 * @param appID application Id
 * @returns ok/error tuple. Value of tuple is the HTTP response details in either case
 */
const trackOfferConfirmed = async (appId: string, userId: string) => {
  await makeRequest<{ status: string }>(
    HTTPMethod.POST,
    restPaths.tracking.trackOfferConfirmed,
    {
      body: {
        appId,
        userId,
      },
    },
    false,
  );
};

export default trackOfferConfirmed;
