import { keyframes } from "@stitches/react";

import { css } from "#themes";

export const wipeUp = (lines = 1, delay = "500ms") => {
  let fromPolygon = "";
  let toPolygon = "";
  const lineArray = [...Array(lines).keys()];
  const fraction = 100 / lines;

  lineArray.forEach((index) => {
    const fromPercentage = fraction * index + (index > 0 ? 5 : 0);
    const toPercentage = fromPercentage + fraction + 5;

    fromPolygon += `${
      index !== 0 ? "," : ""
    }0% ${fromPercentage}%,100% ${fromPercentage}%,100% ${fromPercentage}%,0% ${fromPercentage}%`;
    toPolygon += `${
      index !== 0 ? "," : ""
    }0% ${fromPercentage}%,100% ${fromPercentage}%,100% ${toPercentage}%,0% ${toPercentage}%`;
  });

  const wipeUpKeyframes = keyframes({
    from: {
      transform: "translateY(30px)",
      clipPath: `polygon(${fromPolygon})`,
    },
    to: {
      transform: "translateY(0)",
      clipPath: `polygon(${toPolygon})`,
    },
  });

  return css({
    animationName: wipeUpKeyframes.name,
    animationDuration: "1s",
    animationDelay: delay,
    animationFillMode: "both",
    animationTimingFunction: "cubic-bezier(.56,.01,.58,.86)",
  });
};

const fadeInKeyframes = keyframes({
  from: {
    transform: "translateY(30px)",
    opacity: 0,
  },
  to: {
    transform: "translateY(0)",
    opacity: 1,
  },
});

export const fadeIn = css({
  animationName: fadeInKeyframes.name,
  animationDuration: "1s",
  animationDelay: "500ms",
  animationFillMode: "backwards",
  animationTimingFunction: "cubic-bezier(.56,.01,.58,.86)",
});

const growFromBottomKeyframes = keyframes({
  from: {
    transform: "translateY(100px) scale(0.8)",
    opacity: 0,
  },
  to: {
    transform: "translateY(0) scale(1)",
    opacity: 1,
  },
});

export const growFromBottom = css({
  animationName: growFromBottomKeyframes.name,
  animationDuration: "700ms",
  animationFillMode: "backwards",
  animationTimingFunction: "cubic-bezier(.56,.01,.58,.86)",
});
