/** ******************************************************************************
Register User
 * Creates a User in our DB and in Firebase and signs that user in to Firebase client-side
 ******************************************************************************* */

import { sendEmailVerification } from "firebase/auth";

import { HTTPUserGet } from "#api.los/client.types";
import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { getAuth } from "#auth/utils";
import { restPaths } from "#constants/restPaths";

/**
 * registers a user with firebase
 * @param email user email
 * @param password user password
 * @returns ok/error object
 */
const register: (
  email: string,
  password: string,
  emailVerificationRedirectUrl?: string,
) => Promise<{
  response: string | null;
  error: null | string;
}> = async (email, password, emailVerificationRedirectUrl) => {
  const auth = await getAuth();
  const authError = !auth.currentUser
    ? "Cannot register user, user is already logged in"
    : null;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const handshake = (() => {
    if (typeof window !== "undefined") {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
      return window.tenet.handshake;
    }
    return "";
  })();
  try {
    if (authError !== null) {
      // Explicit user creation and authentication
      await makeRequest<HTTPUserGet>(
        HTTPMethod.POST,
        restPaths.user.create,
        {
          body: { email, password },
          headers: [{ "X-Tenet-Handshake": handshake as string }],
        },
        false,
      );

      // Client-side sign in to Firebase auth to persist authenticated session:
      await import("firebase/auth").then((module) =>
        module.signInWithEmailAndPassword(auth, email, password),
      );

      if (auth.currentUser) {
        const redirectUrlPayload = emailVerificationRedirectUrl
          ? { url: emailVerificationRedirectUrl }
          : undefined;
        // Send email for verificatoin
        sendEmailVerification(auth.currentUser, redirectUrlPayload);
      }

      return { response: "ok", error: null };
    }
  } catch (e) {
    return { response: authError ? null : "ok", error: authError };
  }
  return { response: authError ? null : "ok", error: authError };
};

export default register;
