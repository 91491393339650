import { LoanType } from "#components/partial/ApplyPage/ApplyPage.types";

// TODO: move these types to a global types file to act as an interface between
// FE/BE
export enum ApplicationStatus {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  CANCELLED_BY_ADMIN = "CANCELLED_BY_ADMIN",
  PASSTHROUGH = "PASSTHROUGH",
}

const Partner = {
  Turo: "Turo",
};

type Partner = typeof Partner[keyof typeof Partner];

export enum LoanStatus {
  Draft = "Draft",
  ApplicationStarted = "ApplicationStarted",
  OfferSelected = "OfferSelected",
  IncomeVerificationCompleted = "IncomeVerificationCompleted",
  OfferConfirmed = "OfferConfirmed",
  Funding = "Funding",
  ApplicationReceived = "ApplicationReceived",
  Open = "Open",
  Closed = "Closed",
}
interface VehicleOutputWithVin {
  vin: string;
}

interface VehicleOutputWithUvc {
  uvc: string;
}

interface VehicleOutputWithYMMT {
  make: string;
  model: string;
  year: number;
  trim: string;
}

type VehicleOutput =
  | VehicleOutputWithVin
  | VehicleOutputWithUvc
  | VehicleOutputWithYMMT;

export type VehiclePartialOutputDto = {
  data: {
    id?: string;
    vin?: string;
    uvc?: string;
    make?: string;
    model?: string;
    year?: number;
    mileage?: number;
    color?: string;
    trim?: string;
    trimOptions?: string[];
    image?: {
      link?: string;
    };
    marketValueCents?: number;
  } & VehicleOutput;
};

export interface VehicleOutputDto {
  id?: string;
  vin: string;
  year: number;
  make: string;
  model: string;
  trim?: string;
  mileage?: number;
  color?: string;
  trimOptions?: string[];
  marketValueCents?: number;
  image?: {
    link: string;
  };
}

export interface ApplicationOutputDto {
  id: string;
  loanProId?: number;
  type: LoanType;
  applicationStatus?: ApplicationStatus;
  loanStatus: LoanStatus;
  partner: Partner | null;
  createdAt: Date;
  vehicle?: VehicleOutputDto;
}

export enum AddonStatus {
  Undecided = "Undecided",
  Selected = "Selected",
  Declined = "Declined",
}

export enum BusinessEntityType {
  LLC = "LLC",
  LLP = "LLP",
}

export interface BusinessInfoParamsDTO {
  businessName: string;
  businessType: BusinessEntityType;
  businessEmail: string;
  businessPhone: string;
  ein: string;
  street: string;
  apartmentUnit?: string;
  city: string;
  state: string;
  postalCode: string;
  registeredDate: Date;
  registeredState: string;
}

export interface BusinessOwnershipParamsDTO {
  businessInfoId: string;
  firstName: string;
  lastName: string;
  ownershipPercentage: number;
}

export interface BusinessInfoResponseDTO {
  id: string;
  loanApplicationId: string;
  createdAt: Date;
  businessName: string;
  businessType: BusinessEntityType;
  businessEmail: string;
  businessPhone: string;
  ein: string;
  line1: string;
  line2?: string;
  city: string;
  state: string;
  postalCode: string;
  registeredDate: Date;
  registeredState: string;
}

export interface BusinessOwnershipResponseDTO {
  id: string;
  businessInfoId: string;
  firstName: string;
  lastName: string;
  ownershipPercentage: number;
}

export type CreateChargerEstimateParamsDTO = {
  street: string;
  apartmentUnit: string;
  city: string;
  state: string;
  postalCode: string;
  typeOfHome: TypeOfHome;
  wireRunLength: number;
  selectedCharger: boolean;
  appliancesInHome: string[];
};

export enum TypeOfHome {
  ResidentialSingleFamily = "ResidentialSingleFamily",
  ResidentialMultiFamilyOrCondo = "ResidentialMultiFamilyOrCondo",
  ResidentialApartment = "ResidentialApartment",
  Commercial = "Commercial",
  Other = "Other",
}

export enum LineItemType {
  Installation = "Installation",
  Equipment = "Equipment",
  Other = "Other",
}

export type ChargerLineItem = {
  amountCents: number;
  name: string;
  itemType: LineItemType;
};

export interface ChargerOutputDto {
  externalChargerProvider: string;
  externalChargerProjectId?: string;
  installationCostCents: number;
  lineItems: ChargerLineItem[];
  monthlyCostCents: number;
  totalCostCents: number;
}

export interface ChargerDecisionOutputDto {
  elected: AddonStatus;
  details?: ChargerOutputDto;
}

export type SelectedAddon = {
  elected: AddonStatus;
};
