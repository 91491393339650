/** ******************************************************************************
Get Documents
 * Gets a list of the currently logged in users active application's document
 * requirements.
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPGetDocumentsResponse, LOSAPIResponse } from "./client.types";

/**
 * Gets an array of loanUserDocuments
 * @param {string} loanApplicationId loan application ID
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const getDocuments: (
  loanApplicationId: string,
) => LOSAPIResponse<HTTPGetDocumentsResponse> = async (loanApplicationId) => {
  const response = await makeRequest<HTTPGetDocumentsResponse>(
    HTTPMethod.GET,
    restPaths.document(loanApplicationId).getDocuments,
    {},
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getDocuments;
