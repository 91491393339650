export enum PrimaryNavigationSection {
  "vehicles",
  "settings",
}
export enum SecondaryNavigationSection {
  "profile",
  "documents",
  "payments",
}

export enum LocationType {
  /* MPR, 2023/2/12: "landing" page routes are used as-is, using only their provided stub. These are effectively normal links. */
  "landing",
  /* MPR, 2023/2/12: "origination" page routes (the default) attempt to preserve the partner and product flow, e.g. /turo/new-loan/{stub} */
  "origination",
  /* MPR, 2023/2/12: "portal" page routes prepend 'dashboard', but do not otherwise modify the provided stub */
  "portal",
}

/* These need to match whatever ends up in the "match group" */
export enum FlowOptions {
  default,
  "new-loan",
  refinance,
  turo,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface HandlerConfig<ParamsType extends any[], Response> {
  flow: FlowOptions[];
  back?: () => void;
  next?: (response: Response) => void;
  send?: (...params: ParamsType) => Promise<Response>;
  delayNextOverride?: boolean;
}
