/** ******************************************************************************
Retrieves a link to be used to upload a document to LoanPro
 ******************************************************************************* */
// import { getAuth } from "#auth/utils";
import { HTTPMethod, HTTPResponse } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import CauseError from "#components/util/CauseError";
import { restPaths } from "#constants/restPaths";

import { DocumentImageData, HTTPGetDocumentUploadLink } from "./client.types";

/**
 * Hits the Tenet API to get an upload link from LoanPro
 * @param loanApplicationId
 * @param loanUserDocument the document id. I dont know why this was named this way
 * @param imageData basic file information needed to provision a link
 * @returns a link to upload a document, and a "key" that is just path in Loan
 * Pro's S3 that will be [loanApplicationId]/[filename]
 */
const getUploadLink: (
  loanApplicationId: string,
  loanUserDocument: string,
  imageData: DocumentImageData,
) => Promise<
  [
    null | HTTPResponse<HTTPGetDocumentUploadLink>,
    null | HTTPResponse<HTTPGetDocumentUploadLink>,
  ]
> = async (loanApplicationId, loanUserDocument, imageData) => {
  try {
    const response = await makeRequest<HTTPGetDocumentUploadLink>(
      HTTPMethod.PUT,
      restPaths.document(loanApplicationId).getUploadLink,
      {
        body: {
          loanApplicationId,
          loanUserDocument,
          imageData,
        },
      },
      true,
    );
    if (response.error) {
      return [null, response];
    }
    return [response, null];
  } catch (e) {
    throw new CauseError(
      "Unexpected error when retrieving document upload link",
      {
        cause: e as Error,
      },
    );
  }
};

export default getUploadLink;
