import SharedClient from "#api.shared/client";
import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import {
  ChargerDecisionInputDto,
  HTTPPostChargerDecisionRes,
  LOSAPIResponse,
} from "./client.types";

/**
 * Posts charger election to an application, elected or declined
 * @param applicationId application to specify for charger
 * @param elected boolean, true = elected, false = declined
 * @returns ok/error tuple. Value of tuple is the HTTP response details in either case
 */
const postChargerDecision: (
  applicationId: string,
  payload: ChargerDecisionInputDto,
) => LOSAPIResponse<HTTPPostChargerDecisionRes> = async (
  applicationId: string,
  payload: ChargerDecisionInputDto,
) => {
  SharedClient.tracking.post({
    event: `${payload.elected ? "SELECTED" : "DECLINED"}_CHARGER_ADDON`,
    details: { applicationId },
  });
  const response = await makeRequest<HTTPPostChargerDecisionRes>(
    HTTPMethod.POST,
    restPaths.application(applicationId).charger.postCharger,
    { body: payload },
    true,
  );

  if (!response.error) {
    return { response, error: null };
  }

  return { response: null, error: response };
};

export default postChargerDecision;
