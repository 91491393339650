import { useState } from "react";
import useStorageState from "react-use-storage-state";

import { Application } from "#api.los/client.types";
import { ApplicationProviderStateController } from "#components/provider/ApplicationProvider/ApplicationTypes";
import safelyParseJSON from "#components/util/safelyParseJson";

export const useApplicationProviderStateController =
  (): ApplicationProviderStateController => {
    // Persisted Data

    const [application, setApplicationBase] = useStorageState<
      Application | undefined
    >(
      "applicationProvider-app",
      safelyParseJSON<Application>(
        sessionStorage.getItem("applicationProvider-app") || "",
      ) || undefined,
      sessionStorage,
    );

    const [applicationId, setApplicationIdBase] = useStorageState<
      string | undefined
    >(
      "applicationProvider-appId",
      safelyParseJSON<string>(
        sessionStorage.getItem("applicationProvider-appId") || "",
      ) || undefined,
      sessionStorage,
    );

    const [rejectedApplication, setRejectedApplicationBase] = useStorageState<
      Application | undefined
    >(
      "applicationProvider-rejectedApp",
      safelyParseJSON<Application>(
        sessionStorage.getItem("applicationProvider-rejectedApp") || "",
      ) || undefined,
      sessionStorage,
    );

    const [rejectedApplicationId, setRejectedApplicationIdBase] =
      useStorageState<string | undefined>(
        "applicationProvider-rejectedAppId",
        safelyParseJSON<string>(
          sessionStorage.getItem("applicationProvider-rejectedAppId") || "",
        ) || undefined,
        sessionStorage,
      );

    const setApplication: typeof setApplicationBase = (app) => {
      setRejectedApplicationBase(undefined);
      return setApplicationBase(app);
    };
    const setRejectedApplication: typeof setRejectedApplicationBase = (app) => {
      setApplicationBase(undefined);
      return setRejectedApplicationBase(app);
    };
    const setApplicationId: typeof setApplicationIdBase = (appId) => {
      setRejectedApplicationIdBase(undefined);
      return setApplicationIdBase(appId);
    };
    const setRejectedApplicationId: typeof setRejectedApplicationIdBase = (
      appId,
    ) => {
      setApplicationIdBase(undefined);
      return setRejectedApplicationIdBase(appId);
    };

    const [applicationError, setApplicationError] = useState<
      string | undefined
    >();

    // loading states
    const [initialized] = useState(true);
    const [applicationLoading, setApplicationLoading] = useState(false);

    const loading = applicationLoading;
    return {
      state: {
        application,
        applicationId,
        rejectedApplication,
        rejectedApplicationId,
        applicationError,
        initialized,
      },
      set: {
        setApplication,
        setApplicationId,
        setRejectedApplication,
        setRejectedApplicationId,
        setApplicationError,
      },
      setLoading: {
        setApplicationLoading,
      },
      loading: {
        applicationLoading,
        all: loading,
      },
    };
  };
