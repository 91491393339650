/** ******************************************************************************
Get Loan Offers Limits
 * uses the /applications/${applicationId}/offers/limits endpoint to get loan offers limits
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPGetLimitsRes, LOSAPIResponse } from "./client.types";

/**
 * Gets loan offer limits for current application
 * @param applicationId loan application ID for the current application (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const getLimits: (
  applicationId: string,
  principalAmountCents?: number,
) => LOSAPIResponse<HTTPGetLimitsRes> = async (
  applicationId,
  principalAmountCents,
) => {
  const response = await makeRequest<HTTPGetLimitsRes>(
    HTTPMethod.GET,
    restPaths.application(applicationId).offers.getLimits(principalAmountCents),
    {},
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getLimits;
