export enum ButtonVariant {
  DARK_FILLED = "DARK_FILLED",
  BLUE_FILLED = "BLUE_FILLED",
  WHITE_FILLED = "WHITE_FILLED",
  DARK_OUTLINE = "DARK_OUTLINE",
  BLUE_OUTLINE = "BLUE_OUTLINE",
  PSEUDO_LINK = "PSEUDO_LINK",
  PSEUDO_LINK_PINK = "PSEUDO_LINK_PINK",
  PSEUDO_LINK_PURPLE = "PSEUDO_LINK_PURPLE",
  PSEUDO_LINK_RED = "PSEUDO_LINK_RED",
  OUTLINED = "OUTLINED",
  DARK_BLUE_FILLED = "DARK_BLUE_FILLED",
  PURPLE_FILLED = "PURPLE_FILLED",
}

export enum ButtonSize {
  FULL_WIDTH = "FULL_WIDTH",
  LARGE = "LARGE",
  MEDIUM = "MEDIUM",
  SMALL = "SMALL",
  ICON = "ICON",
}

export type TenetButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: ButtonVariant;
  label?: string;
  hasLeftArrow?: boolean;
  hasRightArrow?: boolean;
  forceSize?: ButtonSize;
  icon?: React.ReactNode;
  "data-cy": string;
};
