import { css } from "#themes";

export const root = css({
  border: "none",
  margin: 0,
  width: "100%",

  variants: {
    variant: {
      primary: { borderBottom: "2px solid $textPrimary" },
      secondary: { borderBottom: "1px solid $uiSubtle" },
      tertiary: { borderBottom: "1px solid $uiPrimary" },
      uiTertiary: { borderBottom: "1px solid $uiTertiary" },
      charcoal70: { borderBottom: "1px solid $charcoal70" },
      neutralCharcoal: { borderBottom: "1px solid $neutralCharcoal200" },
    },
  },
  length: 0,
});
