/** ******************************************************************************
Verify User With Code 
 * uses the /user/verify endpoint to verify users email or phone
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";
import { VerificationType } from "#v2-components/molecules/VerificationModal/VerificationModal.types";

import { HTTPVerifyWithCode, LOSAPIResponse } from "./client.types";

/**
 * Verifies a user's email address change
 * @param code code for verification
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const verifyWithCode: (
  code: string,
  verificationType: VerificationType,
) => LOSAPIResponse<HTTPVerifyWithCode> = async (code, verificationType) => {
  const response = await makeRequest<HTTPVerifyWithCode>(
    HTTPMethod.PUT,
    restPaths.user.verify,
    {
      body: { code, verificationType },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default verifyWithCode;
