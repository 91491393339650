import { AddonStatus } from "#api.los/losDto.types";
import { Network } from "#components/provider/AddonProvider/AddonProvider.network";
import {
  AddonProviderFunctions,
  AddonProviderStateController,
  ChargerInstallOption,
  ChargerProduct,
  CreateChargerEstimate,
  CreateChargerPricing,
  SelectChargerAddonResult,
  SelectChargerInstallAddonResult,
  SelectEvppResult,
  SelectGapAddonResult,
} from "#components/provider/AddonProvider/AddonTypes";
import { useApplicationContext } from "#components/provider/ApplicationProvider/ApplicationProvider";

/* eslint-disable no-console, consistent-return */
export const useAddonProviderFunctions = (
  stateController: AddonProviderStateController,
): AddonProviderFunctions => {
  const { state: applicationState, applicationFunctions } =
    useApplicationContext();
  const { refreshApplication } = applicationFunctions;
  const { applicationId: appId } = applicationState;

  enum AddonType {
    CHARGER_INSTALL = "CHARGER_INSTALL",
    CHARGER = "CHARGER",
    EVPP = "EVPP",
    GAP = "GAP",
  }

  const refreshAddons = async (): Promise<void> => {
    if (!appId) {
      stateController.set.setAddonError("No active Application Id");
      return;
    }

    stateController.setLoading.setAddonsLoading(true);
    try {
      const { response, error } = await Network.getAddons(appId);

      if (error || !response?.body?.data) {
        console.error(
          "05f91c65-889b-4e81-a999-2fb2fbd028b6",
          error ?? "No get addons response returned",
        );
        stateController.setLoading.setAddonsLoading(false);
        // todo: get correct error messages
        const msg = "Unable to Get Addons";
        stateController.set.setAddonError(msg);

        return;
      }

      const { data } = response.body;
      stateController.set.setChargerInstall(
        data.availableOptions.chargerInstall,
      );
      stateController.set.setEvppPlan(data.availableOptions.warrantyPlan);
      stateController.set.setCharger(data.decisions.charger);
      stateController.set.setGap(data.availableOptions.gapInsurance);
      stateController.set.setSelectedChargerInstall(
        data.decisions.chargerInstall,
      );
      stateController.set.setSelectedGap(data.decisions.gapInsurance);
      stateController.set.setSelectedEvpp(data.decisions.warrantyPlan);
      clearError();
    } catch (e) {
      console.error("31f7784b-0013-459e-a518-f0c788b35f71", e);
      // todo: get correct error messages
      const msg = "Unable to Get Addons";
      stateController.set.setAddonError(msg);
    }

    stateController.setLoading.setAddonsLoading(false);
    // why does this have to be done twice!
    stateController.setLoading.setAddonsLoading(false);
  };

  const clearAllLoading = () => {
    stateController.setLoading.setAddonsLoading(false);
    stateController.setLoading.setChargerInstallLoading(false);
    stateController.setLoading.setEvppLoading(false);
    stateController.setLoading.setGapLoading(false);
    stateController.setLoading.setChargerLoading(false);
  };

  const createChargerInstallEstimate = async (
    params: CreateChargerEstimate,
  ): Promise<ChargerInstallOption | undefined> => {
    if (!appId) {
      stateController.set.setAddonError("No active Application Id");
      return;
    }

    const {
      typeOfHome,
      wireRunLength,
      selectedCharger,
      appliancesInHome,
      street,
      state,
      city,
      postalCode,
      apartmentUnit,
    } = params;

    stateController.setLoading.setChargerInstallLoading(true);
    try {
      const { response, error } = await Network.createChargerEstimate(appId, {
        street,
        apartmentUnit,
        city,
        state,
        postalCode,
        typeOfHome,
        wireRunLength,
        selectedCharger,
        appliancesInHome,
      });

      if (error || !response?.body?.data) {
        const msg = "Unable to Get Charger Estimate";

        console.error("5f6f86c3-a7ea-48b3-bfab-4b006df8fb10", error || msg);
        stateController.setLoading.setChargerInstallLoading(false);
        stateController.set.setAddonError(msg);
        return;
      }

      const { data } = response.body;
      stateController.set.setChargerInstall(data);
      await refreshApplication();
      stateController.setLoading.setChargerInstallLoading(false);
      clearError();
      return data;
    } catch (e) {
      console.error("94d42d62-6fc4-4833-8d01-9ac5172dc182", e);
      // todo: get correct error messages
      const msg = "Unable to Get Charger Estimate";
      stateController.set.setAddonError(msg);
    }
    stateController.setLoading.setChargerInstallLoading(false);
  };

  const selectChargerInstallAddon = async (
    elected: boolean,
  ): Promise<SelectChargerInstallAddonResult | undefined> => {
    if (!appId) {
      stateController.set.setAddonError("No active Application Id");
      return;
    }

    try {
      const state = !elected
        ? optimisticState(AddonType.CHARGER_INSTALL, elected)
        : undefined;
      stateController.setLoading.setChargerInstallLoading(true);
      const makeReq = async (_elected: boolean) => {
        const { response, error } = await Network.selectChargerInstallAddon(
          appId,
          {
            elected: _elected,
          },
        );

        if (error || !response?.body?.data) {
          const msg = "Unable to Select Charger Addon";
          console.error("37f5432e-e788-4063-ae6d-e711c7bb1af5", error || msg);
          stateController.set.setAddonError(msg);
          // roll back optimistic state
          optimisticState(AddonType.CHARGER_INSTALL, !elected);
          // Per Tom and Max there is a weird bug in useStorageState and we have to do this twice until we replace useStorageState
          optimisticState(AddonType.CHARGER_INSTALL, !elected);
          stateController.setLoading.setChargerInstallLoading(false);
          return;
        }

        const { data } = response.body;
        // Per Tom and Max there is a weird bug in useStorageState and we have to do this twice until we replace useStorageState
        stateController.set.setSelectedChargerInstall(data);
        stateController.set.setSelectedChargerInstall(data);

        await Promise.all([refreshApplication(), refreshAddons()]);

        stateController.setLoading.setChargerInstallLoading(false);
        stateController.setLoading.setChargerInstallLoading(false);
        clearError();
        return {
          option: stateController.state.chargerInstall,
          decision: data,
        };
      };

      if (stateController.loading.all) {
        addonQueueProcessor(makeReq, elected);
        return {
          decision: {
            elected: state ?? AddonStatus.Undecided,
          },
        };
      }

      return makeReq(elected);
    } catch (e) {
      console.error("f900887c-1fbe-4c86-a650-22daeac4ebef", e);
      // todo: get correct error messages
      const msg = "Unable to Select Charger Addon";
      stateController.set.setAddonError(msg);
    }
    stateController.setLoading.setChargerInstallLoading(false);
  };

  const selectGapAddon = async (
    elected: boolean,
  ): Promise<SelectGapAddonResult | undefined> => {
    if (!appId) {
      stateController.set.setAddonError("No active Application Id");
      return;
    }
    try {
      const state = optimisticState(AddonType.GAP, elected);
      stateController.setLoading.setGapLoading(true);
      const makeReq = async (_elected: boolean) => {
        const { response, error } = await Network.selectGapAddon(appId, {
          elected: _elected,
        });

        if (error || !response?.body?.data) {
          const msg = "Unable to Select Gap Addon";
          console.error("53a3a3ee-5798-4a21-b7e9-f737cdd21fa5", error || msg);
          stateController.set.setAddonError(msg);
          // roll back optimistic state
          optimisticState(AddonType.GAP, !elected);
          // Per Tom and Max there is a weird bug in useStorageState and we have to do this twice until we replace useStorageState
          optimisticState(AddonType.GAP, !elected);
          stateController.setLoading.setGapLoading(false);
          return;
        }

        const { data } = response.body;

        // there is a weird bug in useStorageState and we have to do this twice until we replace useStorageState
        stateController.set.setSelectedGap(data);
        stateController.set.setSelectedGap(data);

        await Promise.all([refreshApplication(), refreshAddons()]);
        stateController.setLoading.setGapLoading(false);
        clearError();
        return {
          option: stateController.state.gap,
          decision: data,
        };
      };

      if (stateController.loading.all) {
        addonQueueProcessor(makeReq, elected);
        return {
          decision: {
            elected: state ?? AddonStatus.Undecided,
          },
        };
      }

      return makeReq(elected);
    } catch (e) {
      console.error("5647da4b-2c3f-4884-937f-7b5596403131", e);
      // todo: get correct error messages
      const msg = "Unable to Select Gap Addon";
      stateController.set.setAddonError(msg);
    }
    stateController.setLoading.setGapLoading(false);
  };

  const selectChargerAddon = async (
    elected: boolean,
    charger?: ChargerProduct,
  ): Promise<SelectChargerAddonResult | undefined> => {
    if (!appId) {
      stateController.set.setAddonError("No active Application Id");
      return;
    }

    try {
      const state = optimisticState(AddonType.CHARGER, elected);
      stateController.setLoading.setChargerLoading(true);
      stateController.set.setCharger({
        ...stateController.state.charger,
        ...{
          elected: elected ? AddonStatus.Selected : AddonStatus.Declined,
          details: {
            addonId: stateController.state.charger?.details?.addonId,
            selectedCharger: charger,
          },
        },
      });
      const makeReq = async (_elected: boolean) => {
        const { response, error } = await Network.selectChargerAddon(appId, {
          elected: _elected,
        });

        if (error || !response?.body?.data) {
          const msg = "Unable to Select Charger Addon";
          console.error("37f5432e-e788-4063-ae6d-e711c7bb1af5", error || msg);
          stateController.set.setAddonError(msg);
          // roll back optimistic state
          optimisticState(AddonType.CHARGER, !elected);
          // Per Tom and Max there is a weird bug in useStorageState and we have to do this twice until we replace useStorageState
          optimisticState(AddonType.CHARGER, !elected);
          stateController.setLoading.setChargerLoading(false);
          return;
        }

        const { data } = response.body;
        stateController.set.setCharger(data);
        await Promise.all([refreshApplication(), refreshAddons()]);
        stateController.setLoading.setChargerLoading(false);
        clearError();
        return {
          option: stateController.state.chargerInstall,
          decision: data,
        };
      };

      if (stateController.loading.all) {
        addonQueueProcessor(makeReq, elected);
        return {
          decision: {
            elected: state ?? AddonStatus.Undecided,
          },
        };
      }

      return makeReq(elected);
    } catch (e) {
      console.error("f900887c-1fbe-4c86-asdfasd-22daeac4ebef", e);
    }
  };

  const createChargerPricing = async (
    params: CreateChargerPricing,
  ): Promise<void> => {
    if (!appId) {
      stateController.set.setAddonError("No active Application Id");
      return;
    }

    stateController.setLoading.setChargerLoading(true);
    try {
      const { response, error } = await Network.createChargerPricing(appId, {
        ...params,
      });

      if (error || !response?.body?.data) {
        const msg = "Unable to Get Charger Pricing";
        console.error("5f6f86c3-a7ea-48b3-bfab-4b006df8fb10", error || msg);
        stateController.setLoading.setChargerLoading(false);
        stateController.set.setAddonError(msg);
        return;
      }

      const { data } = response.body;
      stateController.set.setToggledCharger(data);
      stateController.setLoading.setChargerLoading(false);
      return;
    } catch (e) {
      console.error("94d42d62-6fc4-4833-8d01-9ac5172dc182", e);
    }
  };

  const selectEvppAddon = async (
    elected: boolean,
  ): Promise<SelectEvppResult | undefined> => {
    if (!appId) {
      stateController.set.setAddonError("No active Application Id");
      return;
    }
    try {
      const state = optimisticState(AddonType.EVPP, elected);
      stateController.setLoading.setEvppLoading(true);
      const makeReq = async (_elected: boolean) => {
        const { response, error } = await Network.selectEvppAddon(appId, {
          elected: _elected,
        });

        if (error || !response?.body?.data) {
          const msg = "Unable to Select EVPP Addon";
          console.error("42122533-715b-44a7-b44d-eb535dd522f9", error || msg);
          stateController.set.setAddonError(msg);

          // roll back optimistic state
          optimisticState(AddonType.EVPP, !elected);
          // there is a weird bug in useStorageState and we have to do this twice until we replace useStorageState
          optimisticState(AddonType.EVPP, !elected);
          stateController.setLoading.setEvppLoading(false);
          return;
        }

        const { data } = response.body;
        // Per Tom and Max there is a weird bug in useStorageState and we have to do this twice until we replace useStorageState
        stateController.set.setSelectedEvpp(data);
        stateController.set.setSelectedEvpp(data);

        refreshApplication();
        stateController.setLoading.setEvppLoading(false);
        clearError();
        return {
          option: stateController.state.evppPlan,
          decision: data,
        };
      };

      if (stateController.loading.all) {
        addonQueueProcessor(makeReq, elected);

        return {
          decision: {
            elected: state ?? AddonStatus.Undecided,
          },
        };
      }

      return makeReq(elected);
    } catch (e) {
      console.error("63e6fc33-262f-4fbf-a4eb-5acfb65b5a01", e);
      // todo: get correct error messages
      const msg = "Unable to Select EVPP Addon";
      stateController.set.setAddonError(msg);
    }
    stateController.setLoading.setEvppLoading(false);
  };

  const addVehicleVin = async (vin: string): Promise<boolean> => {
    if (!appId || !isInitialized()) {
      return false;
    }
    stateController.setLoading.setEvppLoading(true);
    try {
      const resp = await Network.setVehicleVin(appId, vin);

      if (resp.error || !resp.response?.body?.data) {
        console.error(
          "8d7d845c-aa30-4441-a144-3581ff711eed",
          resp.error || "No add vehicle vin response",
        );
        stateController.setLoading.setEvppLoading(false);
        return false;
      }

      await refreshApplication();
      await refreshAddons();
      clearError();
    } catch (e) {
      console.error("a0475046-def5-457e-880f-afb0047c6af1", e);
    }
    stateController.setLoading.setEvppLoading(false);
    return true;
  };

  const isInitialized = (): boolean => {
    return stateController.state.initialized;
  };

  // if we already have a state just update its elected flag
  const optimisticState = (
    addonType: AddonType,
    elected: boolean,
  ): AddonStatus | undefined => {
    const status = elected ? AddonStatus.Selected : AddonStatus.Declined;
    switch (addonType) {
      case AddonType.CHARGER_INSTALL: {
        const update = stateController.state.selectedChargerInstall;
        if (update) {
          update.elected = status;
        }

        stateController.set.setSelectedChargerInstall(update);

        return status;
      }
      case AddonType.EVPP: {
        const update = stateController.state.selectedEvpp;
        if (update) {
          update.elected = status;
        }
        stateController.set.setSelectedEvpp(update);

        return status;
      }
      case AddonType.GAP: {
        const update = stateController.state.selectedGap;
        if (update) {
          update.elected = status;
        }
        stateController.set.setSelectedGap(update);

        return status;
      }
      case AddonType.CHARGER: {
        const update = stateController.state.charger;
        if (update) {
          update.elected = status;
        }
        stateController.set.setCharger({
          ...stateController.state.charger,
          elected: status,
        });

        return status;
      }
      default: {
        console.error("invalid addon type");
      }
    }
  };

  const addonQueue: Array<{
    fn: (added: boolean) => Promise<unknown>;
    elected: boolean;
  }> = [];
  const addonQueueProcessor = (
    request: (elected: boolean) => Promise<unknown>,
    elected: boolean,
  ) => {
    const startProcessing = addonQueue.length < 1;
    addonQueue.push({ fn: request, elected });

    if (startProcessing) {
      const MAX_RETRIES = 10;
      let count = 0;
      const refreshId = setInterval(() => {
        if (!stateController.loading.all || count >= MAX_RETRIES) {
          const event = addonQueue.pop();
          event?.fn(event.elected);
        }
        if (addonQueue.length === 0) {
          clearInterval(refreshId);
        }
        count += 1;
      }, 1000);
    }
  };

  const clearError = () => {
    if (stateController.state.addonError) {
      stateController.set.setAddonError(undefined);
    }
  };

  return {
    addVehicleVin,
    clearAllLoading,
    createChargerInstallEstimate,
    createChargerPricing,
    refreshAddons,
    selectChargerInstallAddon,
    selectChargerAddon,
    selectGapAddon,
    selectEvppAddon,
  };
};
