import { forwardRef } from "react";

import Checkbox from "#components/base/Checkbox/Checkbox";
import Text from "#components/base/Text/Text";
import { flex, layout } from "#styles";

import type { Props } from "./CheckboxField.types";

const CheckboxField = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      errors,
      id: propId,
      label,
      name,
      "aria-label": ariaLabel,
      ...otherProps
    },
    ref,
  ) => {
    const hasError = !!errors?.[name];
    const id = propId || `${name}-input`;

    return (
      <div className={className} css={[flex.flexStartVertical, layout.gap(4)]}>
        <div css={[flex.flexStart, layout.gap(16)]}>
          <Checkbox
            aria-invalid={hasError}
            id={id}
            name={name}
            ref={ref}
            {...otherProps}
            aria-label={ariaLabel}
          />

          {label && (
            <Text as="label" htmlFor={id} variant="body2">
              {label}
            </Text>
          )}
        </div>

        {errors?.[name]?.message && (
          <Text color="textNegative" variant="body2">
            {errors?.[name]?.message}
          </Text>
        )}
      </div>
    );
  },
);

export default CheckboxField;
