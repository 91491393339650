import { Property } from "@stitches/react/types/css";

import { css } from "#themes";

export const inline = css({ display: "inline-flex" });

export const flexStart = css({
  alignItems: "flex-start",
  display: "flex",
  justifyContent: "flex-start",
});

export const alignCenter = css({
  alignItems: "center",
  display: "flex",
});

export const justifyStart = css({
  display: "flex",
  justifyContent: "flex-start",
});

export const justifyEnd = css({
  display: "flex",
  justifyContent: "flex-start",
});

export const flexStartVertical = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
});

export const flexStartHorizontal = css({
  alignItems: "flex-start",
  display: "flex",
});

export const flexEnd = css({
  alignItems: "flex-end",
  display: "flex",
  justifyContent: "flex-end",
});

export const flexEndVertical = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
});

export const flexEndHorizontal = css({
  alignItems: "flex-end",
  display: "flex",
});

export const flexCenter = css({
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
});

export const flexCenterHorizontal = css({
  alignItems: "center",
  display: "flex",
});

export const flexCenterVertical = css({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

export const flexSpaceHorizontal = css({
  display: "flex",
  justifyContent: "space-between",
});

export const flexSpaceVertical = css({
  alignItems: "space-between",
  display: "flex",
  flexDirection: "column",
});

export const flexColumn = css({
  display: "flex",
  flexDirection: "column",
});

export const flexGrow = css({
  flexGrow: 1,
});

export const flexShrink = css({
  flexShrink: 1,
});

export const alignSelf = (value: Property.AlignSelf) =>
  css({ alignSelf: value });

export const flexIntrinsic = css({
  flexGrow: 0,
  flexShrink: 0,
});

export const wrap = (flexWrap: Property.FlexWrap = "wrap") => css({ flexWrap });
