import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPCreateChargerEstimateRes, LOSAPIResponse } from "./client.types";
import { CreateChargerEstimateParamsDTO } from "./losDto.types";

/**
 * Creates an initial price estimate for a Charger Installation package
 * @param applicationId application to specify for charger
 * @param payload payload for posting to charger service
 * @returns ok/error tuple. Value of tuple is the HTTP response details in either case
 */
const createChargerEstimate: (
  applicationId: string,
  payload: CreateChargerEstimateParamsDTO,
) => LOSAPIResponse<HTTPCreateChargerEstimateRes> = async (
  applicationId: string,
  payload: CreateChargerEstimateParamsDTO,
) => {
  const response = await makeRequest<HTTPCreateChargerEstimateRes>(
    HTTPMethod.POST,
    restPaths.application(applicationId).chargerInstall.createEstimate,
    { body: payload },
    true,
  );

  if (!response.error) {
    return { response, error: null };
  }

  return { response: null, error: response };
};

export default createChargerEstimate;
