/** ******************************************************************************
Update User Drivers License Info
 * uses the /drivers-license endpoint to update a user's drivers license info
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPUpdateUserRes, LOSAPIResponse } from "./client.types";

/**
 * Updates/sets a user's drivers license info
 * @param driversLicenseNumber drivers license number to update (string)
 * @param expiryDate expiration date for drivers license (Date)
 * @param issueDate issue date for drivers license (Date)
 * @param issueState state the drivers license is issued in (string, format is 2-character state code)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const updateDriversLicense: ({
  driversLicenseNumber,
  expiryDate,
  issueDate,
  stateAbbreviation,
}: {
  driversLicenseNumber: string;
  expiryDate: Date;
  issueDate: Date;
  stateAbbreviation: string;
}) => LOSAPIResponse<HTTPUpdateUserRes> = async ({
  driversLicenseNumber,
  expiryDate,
  issueDate,
  stateAbbreviation,
}: {
  driversLicenseNumber: string;
  expiryDate: Date;
  issueDate: Date;
  stateAbbreviation: string;
}) => {
  const response = await makeRequest<HTTPUpdateUserRes>(
    HTTPMethod.PUT,
    restPaths.user.updateDriversLicense,
    {
      body: { driversLicenseNumber, expiryDate, issueDate, stateAbbreviation },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default updateDriversLicense;
