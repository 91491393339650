export const AUTH_CODES = {
  IN_USE: "auth/email-already-in-use",
  WEAK_PASS: "auth/weak-password",
  WRONG_PASS: "auth/wrong-password",
  NO_USER: "auth/user-not-found",
};

export enum API_ERROR_NAMES {
  DUPLICATED_VIN_CODE = "Duplicated Vin Error",
  INVALID_PREQUAL_OFFER = "prequall Offer Error",
}

/**
 * When DocuSign redirects to the return URL, it includes an `event` query parameter that can be used by your application.
 */
export enum DOCUSIGN_EVENT_PARAM {
  /**
   * Recipient used incorrect access code.
   */
  ACCESS_CODE_FAILED = `access_code_failed`,
  /**
   * Recipient canceled the signing operation, possibly by using the **Finish Later** option.
   */
  CANCEL = `cancel`,
  /**
   * Recipient declined to sign.
   */
  DECLINE = `decline`,
  /**
   * A system error occurred during the signing process.
   */
  EXCEPTION = `exception`,
  /**
   * Recipient has a fax pending.
   */
  FAX_PENDING = `fax_pending`,
  /**
   * Recipient failed an ID check.
   */
  ID_CHECK_FAILED = `id_check_failed`,
  /**
   * The session timed out. An account can control this timeout by using the **Signer Session Timeout*option.
   */
  SESSION_TIMEOUT = `session_timeout`,
  /**
   * The recipient completed the signing ceremony.
   */
  SIGNING_COMPLETE = `signing_complete`,
  /**
   * The Time To Live token for the envelope has expired. After being successfully invoked, these tokens expire after 5 minutes or if the envelope is voided.
   */
  TTL_EXPIRED = `ttl_expired`,
  /**
   * The recipient completed viewing an envelope that is in a read-only/terminal state, such as completed, declined, or voided.
   */
  VIEWING_COMPLETE = `viewing_complete`,
}
