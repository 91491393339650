/** ******************************************************************************
End Session
 * uses the /end-session endpoint to finish plaid income verification
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPEndIvsRes, LOSAPIResponse } from "./client.types";

/**
 * Completes plaid income verification
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const endSession: () => LOSAPIResponse<HTTPEndIvsRes> = async () => {
  const response = await makeRequest<HTTPEndIvsRes>(
    HTTPMethod.POST,
    restPaths.incomeVerification.endSession,
    {},
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default endSession;
