/** ******************************************************************************
 * @file user.addToExperiment.ts
 * Adds the user to the experiment in a given bucket
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import {
  extractBody,
  HTTPResponseCreateExperimentBucket,
} from "./client.types";

type Response = HTTPResponseCreateExperimentBucket;
type Body = extractBody<Response>;

const addUserToExperiment: (params: {
  experimentName: string;
  bucketName: string;
}) => Response = async ({ experimentName, bucketName }) => {
  const response = await makeRequest<Body>(
    HTTPMethod.PUT,
    restPaths.user.experiment.add,
    {
      body: {
        experimentName,
        bucketName,
      },
    },
    true,
    "los",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { error: response, response: null };
};

export default addUserToExperiment;
