import { HTMLProps, useCallback, useState } from "react";

import { usePaymentMethodProvider } from "#components/provider/PaymentMethods";

import PaymentsAutoPaymentsModal from "../PaymentsAutoPaymentsModal/PaymentsAutoPaymentsModal";
import styles from "./AddPaymentsProvider.module.scss";

export interface AddPaymentsProps {
  addPayments: {
    openModal: () => void;
    AddPaymentsElement: (props: HTMLProps<HTMLButtonElement>) => JSX.Element;
  };
}

interface Props {
  renderComponent: ({ addPayments }: AddPaymentsProps) => JSX.Element;
  onSuccess?: () => void;
  onClose?: () => void;
  header?: string;
  body?: string;
}

// @TODO - remove eslint config that disallows stubbed/empty functions
const impotentFn = () => {
  // eslint-disable-next-line no-console
  console.warn("");
};

const AddPaymentsProvider = ({
  renderComponent,
  onSuccess = impotentFn,
  onClose = impotentFn,
  header,
  body,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const AddPaymentsElement = useCallback(
    (props) => (
      <button
        className={styles.addPaymentsElement}
        type="button"
        onClick={() => setModalOpen(true)}
        {...props}
        aria-label="Add"
      >
        <span>Add</span>
      </button>
    ),
    [setModalOpen],
  );

  const { refresh: refreshPaymentMethods } = usePaymentMethodProvider();

  return (
    <>
      {renderComponent({
        addPayments: {
          openModal: () => setModalOpen(true),
          AddPaymentsElement,
        },
      })}
      <PaymentsAutoPaymentsModal
        open={modalOpen}
        onClose={() => {
          refreshPaymentMethods();
          onClose();
          setModalOpen(false);
        }}
        onSuccess={() => {
          refreshPaymentMethods();
          onSuccess();
          setModalOpen(false);
        }}
        header={header}
        body={body}
      />
    </>
  );
};

export default AddPaymentsProvider;
