// Add to as needed Aiden 12032022

export enum INDIVIDUAL_FICOS {
  MIN_PURCHASE = 660,
  MIN_REFI = 660,
}

export enum INDIVIDUAL_CARSHARING_FICOS {
  MIN_PURCHASE = 660,
  MIN_REFI = 660,
}

export enum COMMERCIAL_FICOS {
  MIN_PURCHASE = 670,
  MIN_REFI = 670,
}

export enum APRS {
  MIN = 6.84,
  MAX = 13.9,
}

export enum COMMERCIAL_APRS {
  MIN = 8.64,
  MAX = 16.35,
}
