/** ******************************************************************************
Create Direct Loan Offer
 * uses the /applications/${applicationId}/offers/create endpoint to get loan offers
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPCreateDirectRes, LOSAPIResponse } from "./client.types";

/**
 * Creates a new loan offer for the current application
 * @param applicationId loan application ID for the current application (string)
 * @param balloonCents preferred deferred payment in cents (number)
 * @param termLengthMonths preferred loan term length in months (number)
 * @param previousOfferHash hash used to compare built loan offer
 * to current loan offer (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const createDirectOffer: (
  applicationId: string,
  balloonCents: number,
  termLengthMonths: number,
  previousOfferHash: string,
) => LOSAPIResponse<HTTPCreateDirectRes> = async (
  applicationId,
  balloonCents,
  termLengthMonths,
  previousOfferHash,
) => {
  const response = await makeRequest<HTTPCreateDirectRes>(
    HTTPMethod.PUT,
    restPaths.application(applicationId).offers.createDirect,
    {
      body: {
        balloonCents,
        termLengthMonths,
        previousOfferHash,
      },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default createDirectOffer;
