import { TextStyles } from "../theme.types";

const getTextStyleForVariant = (variant: TextStyles = "body1") => ({
  fontFamily: "$body",
  fontSize: `$${variant}`,
  fontWeight: `$${variant}`,
  lineHeight: `$${variant}`,
});

export const textStyle = (variant: TextStyles = "body1") => {
  if (variant === "label") {
    return {
      ...getTextStyleForVariant(variant),
      fontFamily: "$label",
      letterSpacing: "$label",
      textTransform: "uppercase",
    };
  }

  return getTextStyleForVariant(variant);
};

export const truncate = (value?: "ellipsis") =>
  value === "ellipsis"
    ? {
        display: "inline-block",
        maxWidth: "100%",
        overflowX: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }
    : {};
