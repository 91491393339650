import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPMakesRes, LOSAPIResponse } from "./client.types";

/**
 * Gets all unique vehicle makes
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const getAllVehicleMakes: () => LOSAPIResponse<HTTPMakesRes> = async () => {
  const response = await makeRequest<HTTPMakesRes>(
    HTTPMethod.GET,
    restPaths.models.getAllVehicleMakes,
    {},
    false,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getAllVehicleMakes;
