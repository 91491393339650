import { css } from "#themes";

export const lineClamp = (lines: number) =>
  css({
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": `${lines}`,
    display: "-webkit-box",
    overflow: "hidden",
  });

export const truncate = css({ truncate: "ellipsis" });

export const variant = (
  textVariant: "body1" | "body2" | "heading1" | "heading2" | "label",
) => css({ textStyle: textVariant });
