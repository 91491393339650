import dynamic from "next/dynamic";
import { useState } from "react";

import { Menu as MenuIcon } from "#components/base/Icon/Icon";
import Link from "#components/base/Link/Link";
import ClientOnly from "#components/util/ClientOnly/ClientOnly";

import styles from "./Header.module.scss";

const Menu = dynamic(() => import("#components/partial/Menu/Menu"));

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const handleToggleMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  return (
    <header className="general-header">
      <div className={styles.header}>
        <div>
          <Link aria-label="Tenet" href="/">
            {/* Eric: Why is this an image and not an Icon? Well, rendering the icon, and only the logo icon here results in 
            fullstory recordings being obstructed, since fullstory records it as much larger than it is. I don't know why. */}
            <img
              height={24}
              width={24}
              src="/img/logo.svg"
              alt="loading-graphic"
            />
          </Link>
          {/* {showRefer && (
            <Button
              id="refer-a-friend"
              aria-label="refer a friend"
              type="button"
              variant={ButtonVariant.DARK_FILLED}
              forceSize={ButtonSize.SMALL}
              style={{ marginLeft: "auto", marginRight: 16 }}
            >
              Refer a Friend
            </Button>
          )} */}
          <button
            id="global-show-menu-button"
            aria-label={showMobileMenu ? "Close menu" : "Open menu"}
            onClick={handleToggleMenu}
            type="button"
          >
            <MenuIcon size={24} />
          </button>
        </div>
      </div>

      <ClientOnly>
        <Menu onClose={handleToggleMenu} open={showMobileMenu} />
      </ClientOnly>
    </header>
  );
};

export default Header;
