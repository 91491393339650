import { BusinessOwnershipParamsDTO } from "#api.los/losDto.types";
import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPUpsertBusinessRes, LOSAPIResponse } from "./client.types";

/**
 * Updates/Creates business ownership for an application (commercial loans)
 * @returns ok/error tuple. Value of tuple is the HTTP response details in either case
 */
const upsertBusinessOwnership: (
  applicationId: string,
  ownerships: BusinessOwnershipParamsDTO[],
) => LOSAPIResponse<HTTPUpsertBusinessRes> = async (
  applicationId: string,
  ownerships: BusinessOwnershipParamsDTO[],
) => {
  const response = await makeRequest<HTTPUpsertBusinessRes>(
    HTTPMethod.POST,
    restPaths.business(applicationId).upsertOwnership,
    {
      body: ownerships,
    },
    true,
  );

  if (!response.error) {
    return { response, error: null };
  }

  return { response: null, error: response };
};

export default upsertBusinessOwnership;
