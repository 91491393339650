import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { CreateApplicationParams } from "#components/provider/ApplicationProvider/ApplicationTypes";
import { restPaths } from "#constants/restPaths";
import { parseCentsFromCurrencyInputString } from "#util/number/number";

import { HTTPcreateApplicationRes, LOSAPIResponse } from "./client.types";

/**
 * creates a loan application asscociated to the currentUser
 * @param draftId draft application ID from Refinance flow
 * @param applicationType type of application (purchase | refi)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */

const createApplication: ({
  type,
  draftId,
  partner,
  usage,
  loanClass,
  eligibilityData,
}: CreateApplicationParams) => LOSAPIResponse<HTTPcreateApplicationRes> = async ({
  type,
  draftId,
  partner,
  usage,
  loanClass,
  eligibilityData,
}) => {
  const typeQuery = `?type=${type}`;
  const draftQuery = draftId ? `&draft=${draftId}` : "";
  const partnerQuery = partner ? `&partner=${partner.toLowerCase()}` : "";
  const response = await makeRequest<HTTPcreateApplicationRes>(
    HTTPMethod.POST,
    `${restPaths.application().create}${typeQuery}${draftQuery}${partnerQuery}`,
    {
      body: {
        vehicleUsage: usage,
        loanClass,
        hostingEligibility: {
          ...eligibilityData,
          avgRevenueCents: eligibilityData?.avgRevenue
            ? parseCentsFromCurrencyInputString(eligibilityData.avgRevenue)
            : undefined,
          monthsHosted:
            (eligibilityData?.years || 0) * 12 + (eligibilityData?.months || 0),
        },
      },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default createApplication;
