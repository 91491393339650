import noop from "lodash/noop";
import { useCallback, useState } from "react";
import type {
  PlaidAccount,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
} from "react-plaid-link";

import Modal from "#components/base/Modal/Modal";
import usePlaidIntegration from "#hooks/usePlaidIntegration/usePlaidIntegration";

import AccountSelectionPage from "./AccountSelectionPage/AccountSelectionPage";
import type { Props } from "./PaymentsAutoPaymentsModal.types";

export default function PaymentsAutoPaymentsModal({
  open: openProp,
  onClose: onCloseProp,
  onSuccess: onSuccessProp,
  plaidPublicTokenProp,
  plaidMetadataProp,
}: Props) {
  const [plaidPublicToken, setPlaidPublicToken] = useState<string>();
  const [plaidMetadata, setPlaidMetadata] =
    useState<PlaidLinkOnSuccessMetadata>();
  const [hasCompletedFlow, setHasCompletedFlow] = useState(false);

  const onClose = useCallback(() => {
    onCloseProp();
  }, [onCloseProp]);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
      console.info("plaid link success");
      setPlaidPublicToken(publicToken);
      setPlaidMetadata(metadata);
      setHasCompletedFlow(true);
    },
    [setPlaidPublicToken, setPlaidMetadata, hasCompletedFlow],
  );

  const { openPlaidFlow } = usePlaidIntegration({
    onSuccess,
  });

  if (!hasCompletedFlow && openProp) {
    openPlaidFlow();
    return null;
  }

  const accounts = (() => {
    if (plaidMetadataProp) {
      return plaidMetadataProp?.accounts;
    }
    return plaidMetadata?.accounts;
  })();

  return (
    <Modal
      open={openProp}
      onClose={noop}
      forceClose={onClose}
      variant="fullScreenMobile"
    >
      <AccountSelectionPage
        accounts={accounts as PlaidAccount[]}
        publicToken={plaidPublicTokenProp || (plaidPublicToken as string)}
        onCancel={onClose}
        onSuccess={() => {
          onSuccessProp();
          setHasCompletedFlow(false);
        }}
      />
    </Modal>
  );
}
