/** ******************************************************************************
REST HTTP Requester Client (internal)
 * The actual internal REST client that makes the HTTP calls.
 * If you are importing this directly, ask yourself why you are not adding a
 * public method to the client wrapper (client.ts, in this directory)
 ******************************************************************************* */
import Cookies from "js-cookie";

import {
  HTTPMethod,
  HTTPOptions,
  HTTPResponse,
} from "#api.shared/client.types";
import { getAuth } from "#auth/utils";
import CauseError from "#components/util/CauseError";
import { stack } from "#util/env";

interface AuthCheck {
  canProceed: boolean;
  isAuthenticated: boolean | undefined;
  token: string | undefined;
}

interface OptionsBuilder {
  body?: string;
  headers: Headers;
}

export const currentBackendDomainBase = () =>
  ((v: string | undefined) => {
    switch (v) {
      case "local":
        return "localhost";
      case "prod":
        return "api.tenet.com";
      case "sandbox":
        return "sandbox.api.tenet.com";
      case undefined:
        throw new CauseError("Missing STACK configuration.");
      default:
        return `${process.env.NEXT_PUBLIC_STACK}.api.nonprodtenet.com`;
    }
  })(stack);

export const currentFrontendDomain = () =>
  ((v: string | undefined) => {
    switch (v) {
      case "local":
        return "localhost:3000";
      case "prod":
        return "tenet.com";
      case "sandbox":
        return "sandbox.tenet.com";
      case undefined:
        throw new CauseError("Missing STACK configuration.");
      default:
        return `${process.env.NEXT_PUBLIC_STACK}.nonprodtenet.com`;
    }
  })(stack);

export const currentHTTPProtocol = () =>
  ((v: string | undefined) => {
    switch (v) {
      case "local":
        return "http";
      case "prod":
      case "sandbox":
        return "https";
      case undefined:
        throw new CauseError("Missing STACK configuration.");
      default:
        return `https`;
    }
  })(stack);

const losapiURL = ((v: string | undefined) => {
  switch (v) {
    case "local":
      return `${currentHTTPProtocol()}://${currentBackendDomainBase()}:3002`;
    case "prod":
      return `${currentHTTPProtocol()}://${currentBackendDomainBase()}/los`;
    case undefined:
      throw new CauseError("Missing STACK configuration.");
    default:
      return `${currentHTTPProtocol()}://${currentBackendDomainBase()}/los`;
  }
})(stack);
const lmsapiURL = ((v: string | undefined) => {
  switch (v) {
    case "local":
      return `${currentHTTPProtocol()}://${currentBackendDomainBase()}:3009`;
    case "prod":
      return `${currentHTTPProtocol()}://${currentBackendDomainBase()}/lms`;
    case undefined:
      throw new CauseError("Missing STACK configuration.");
    default:
      return `${currentHTTPProtocol()}://${currentBackendDomainBase()}/lms`;
  }
})(stack);
export const visapiURL = ((v: string | undefined) => {
  switch (v) {
    case "local":
      return `${currentHTTPProtocol()}://${currentBackendDomainBase()}:3004`;
    case "prod":
      return `${currentHTTPProtocol()}://${currentBackendDomainBase()}/insights`;
    case undefined:
      throw new CauseError("Missing STACK configuration.");
    default:
      return `${currentHTTPProtocol()}://${currentBackendDomainBase()}/insights`;
  }
})(stack);
const claimAPIURL = ((v: string | undefined) => {
  switch (v) {
    case "local":
      return `${currentHTTPProtocol()}://${currentBackendDomainBase()}:3025`;
    case "prod":
      return `${currentHTTPProtocol()}://${currentBackendDomainBase()}/claims`;
    case undefined:
      throw new CauseError("Missing STACK configuration.");
    default:
      return `${currentHTTPProtocol()}://${currentBackendDomainBase()}/claims`;
  }
})(stack);
const boardingClaimAPIURL = ((v: string | undefined) => {
  switch (v) {
    case "local":
      return `${currentHTTPProtocol()}://${currentBackendDomainBase()}:3026`;
    case "prod":
      return `${currentHTTPProtocol()}://${currentBackendDomainBase()}/boardingclaims`;
    case undefined:
      throw new CauseError("Missing STACK configuration.");
    default:
      return `${currentHTTPProtocol()}://${currentBackendDomainBase()}/boardingclaims`;
  }
})(stack);

/**
 * reasonably unopinionated HTTP requester. Throws errors on 5XX responses, returns normally on
 * all other status codes.
 * @param method HTTP Method, e.g. GET
 * @param path Path fragment. Prefixes path, `los`, and leading slash. For example,
 * to call localhost:3002/los/user/pr, this value would be `user/pr`
 * @param options HTTP Request Options. See https://developer.mozilla.org/en-US/docs/Web/API/Request/Request#options
 * @param isAuthenticatedRequest specifies whether this request requires the user to be authenticated
 * @returns an object containing response data and information about the response. 'data' is the body,
 * 'error' indicates non-2XX, 'ok' indicates non-5XX
 */
const makeRequest: <ResType>(
  method: HTTPMethod,
  path: string,
  options?: HTTPOptions,
  isAuthenticatedRequest?: boolean,
  service?: "los" | "lms" | "insights" | "claims" | "boardingclaims",
) => Promise<HTTPResponse<ResType>> = async (
  method,
  path,
  options = {},
  isAuthenticatedRequest = false,
  service = "los",
) => {
  let error = false;
  let statusText = "";
  const apiURL = (() => {
    if (service === "lms") {
      return lmsapiURL;
    }
    if (service === "insights") {
      return visapiURL;
    }
    if (service === "claims") {
      return claimAPIURL;
    }
    if (service === "boardingclaims") {
      return boardingClaimAPIURL;
    }
    return losapiURL;
  })();

  // Check Auth Status
  const { canProceed, isAuthenticated, token }: AuthCheck = await (async (
    auth,
  ): Promise<AuthCheck> => {
    try {
      if (isAuthenticatedRequest) {
        const innerToken = await auth.currentUser?.getIdToken();
        const innerIsAuthenticated = !!innerToken;
        if (!innerIsAuthenticated) {
          error = true;
          statusText =
            "Unauthorized user attempted to access authenticated resource. Abandoning request attempt.";
        }

        return {
          canProceed: !isAuthenticatedRequest || innerIsAuthenticated,
          isAuthenticated: innerIsAuthenticated,
          token: innerToken,
        };
      }
      return {
        canProceed: true,
        isAuthenticated: undefined,
        token: undefined,
      };
    } catch (e) {
      console.warn(`Request to ${path} failed.`); // eslint-disable-line no-console
      console.log(e); // eslint-disable-line no-console
      throw new CauseError(`Request failed.`, { cause: e as Error });
    }
  })(await getAuth());

  // Make Request
  try {
    if (canProceed) {
      const optionsBuilder: OptionsBuilder = {
        headers: new Headers(),
      };
      if (options.body != null) {
        optionsBuilder.body = JSON.stringify(options.body);
      }
      /* MPR, 2022/8/19: "/user/pr 422", you got me there Steve!
       * We do this first so that, if needed, it can be overwritten
       * in the next section. That _might_ cause it to appear twice
       * though, which might be undesirable. If that comes up we can
       * switch to a detection strategy. */
      optionsBuilder.headers.append("content-type", "application/json");
      if (options.headers != null) {
        options.headers.forEach((v) => {
          const k =
            Object.keys(
              v,
            )[0]; /* MPR, 2022/8/18 safe because we know this is a single record */
          optionsBuilder.headers.append(k, v[k]);
        });
      }
      if (isAuthenticated) {
        optionsBuilder.headers.append("authorization", `Bearer ${token}`);
      }

      // admin impersonation guard
      const ignoredPaths = [
        "experiment",
        "user/income-verification/auth-token",
      ];
      if (method !== HTTPMethod.GET && !ignoredPaths.includes(path)) {
        const isAdmin = Cookies.get("ips") != null;
        const isAdminOnBehalf = Cookies.get("ips") === "true";
        const isWarningDisabled = Cookies.get("ipsWarningDisabled") != null;
        if (isAdmin && isAdminOnBehalf && !isWarningDisabled) {
          if (
            // eslint-disable-next-line no-alert
            window.confirm(
              "You are currently acting on behalf of a customer. Are you sure you want to continue? (Clicking continue will disable this warning for 10 minutes)",
            )
          ) {
            const now = new Date();
            const time = now.getTime();
            const expireTime = time + 1000 * 60 * 10;
            now.setTime(expireTime);
            document.cookie = `ipsWarningDisabled=true;expires=${now.toUTCString()};path=/`;
          } else {
            throw new CauseError("Admin cancelled impersonation request.");
          }
        }
      }

      const rawResponse: Response = await fetch(
        new Request(`${apiURL}/${path}`, {
          method,
          mode: "cors",
          credentials: "include",
          ...optionsBuilder,
        }),
      );
      const { ok, status, redirected, type, url } = rawResponse;
      ({ statusText } = rawResponse);
      let body;
      if (rawResponse.ok) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        body = await rawResponse.json();
      } else if (rawResponse.status < 500) {
        error = true;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        body = await rawResponse.json();
      } else {
        throw new CauseError(`${status}: ${statusText}`);
      }
      return {
        error,
        status,
        ok,
        statusText,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        body,
        redirected,
        type,
        url,
      };
    }
    return {
      error,
      statusText,
    };
  } catch (e) {
    console.warn(`Request to ${path} failed.`); // eslint-disable-line no-console
    console.log(e); // eslint-disable-line no-console
    throw new CauseError(`Request failed.`, { cause: e as Error });
  }
};

export default makeRequest;
