/** ******************************************************************************
Get All Loans
 * Get all Loans associated with the current user, including vehicles
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPAllLoansResponse, LMSAPIResponse } from "./client.types";

const getLoans: () => LMSAPIResponse<HTTPAllLoansResponse> = async () => {
  const response = await makeRequest<HTTPAllLoansResponse>(
    HTTPMethod.GET,
    restPaths.loans.get,
    {},
    true,
    "lms",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getLoans;
