/** ******************************************************************************
Get Prequal
 * uses the /applications/${applicationId}/offers endpoint to get min and max APR
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPGetPrequalRes, LOSAPIResponse } from "./client.types";

/**
 * Gets prequal min and max APRs for current application
 * @param applicationId loan application ID for the current application (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const getPrequal: (applicationId: string) => LOSAPIResponse<HTTPGetPrequalRes> =
  async (applicationId) => {
    const response = await makeRequest<HTTPGetPrequalRes>(
      HTTPMethod.GET,
      restPaths.application(applicationId).offers.prequal,
      {},
      true,
    );
    if (!response.error) {
      return { response, error: null };
    }
    return { response: null, error: response };
  };

export default getPrequal;
