import merge from "lodash/merge";
import noop from "lodash/noop";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useStorageState } from "react-use-storage-state";
import { v4 as uuidv4 } from "uuid";

import globalShim from "#components/util/nextGlobalShim";

const { sessionStorage } = globalShim;

function CreateRESTContextProvider<
  ResponseType = unknown,
  ErrorType = string,
>() {
  const DataContext = createContext<ResponseType | null>(null);
  const DataRefreshContext = createContext<() => void>(noop);
  const DataUpdateContext = createContext<
    ((updateVal: Partial<ResponseType>) => void) | null
  >(null);
  const ErrorContext = createContext<ErrorType | null>(null);
  const InvalidContext = createContext<ErrorType | null>(null);
  const LoadingContext = createContext<boolean>(true);
  const ToggleHaltRequestsContext = createContext<(arg?: boolean) => void>(
    (arg?: boolean) => noop(arg),
  );

  type RESTProvderProps = {
    children: React.ReactNode;
    getData: () => Promise<{
      response?: ResponseType;
      error?: ErrorType;
    }>;
    storageKey: string;
    signal?: string;
    paused?: boolean;
  };

  const RESTProvider = ({
    children,
    getData,
    signal,
    storageKey,
    paused,
  }: RESTProvderProps) => {
    const [data, setData] = useStorageState<ResponseType | null>(
      `${storageKey}-data`,
      null,
      sessionStorage,
    );
    const [invalid, setInvalid] = useStorageState<ErrorType | null>(
      `${storageKey}-invalid`,
      null,
      sessionStorage,
    );
    const [error, setError] = useStorageState<ErrorType | null>(
      `${storageKey}-error`,
      null,
      sessionStorage,
    );
    const [loading, setLoading] = useStorageState<boolean>(
      `${storageKey}-loading`,
      true,
      sessionStorage,
    );
    const [fetchId, setFetchId] = useStorageState<string | null>(
      `${storageKey}-fetchId`,
      uuidv4(),
      sessionStorage,
    );
    /* this ones a little weird. when this is "true", we do not make calls */
    const [haltRequests, setHaltRequests] = useState<boolean>(false);

    useEffect(() => {
      if (haltRequests || paused) {
        setLoading(false);
        return;
      }
      setLoading(true);
      (async () => {
        try {
          const { response, error: err } = await getData();
          if (response != null) {
            setData(response);
            setError(null); // Clear the sessionStorage Error if fetch is successful
          }
          if (err) {
            setInvalid(err);
          }
        } catch (e) {
          setError(e as ErrorType);
        }
        setLoading(false);
      })();
    }, [fetchId, signal, haltRequests, paused]);

    const refresher = useCallback(() => {
      setLoading(true);
      setFetchId(uuidv4());
    }, []);

    const updater = useCallback((updatedData: Partial<ResponseType>) => {
      setData(merge(data, updatedData as ResponseType));
    }, []);

    const halter = useCallback((shouldHalt?: boolean) => {
      if (shouldHalt == null) {
        setHaltRequests(!haltRequests);
        return;
      }
      setHaltRequests(shouldHalt);
    }, []);

    return (
      <ToggleHaltRequestsContext.Provider value={halter}>
        <DataUpdateContext.Provider value={updater}>
          <DataRefreshContext.Provider value={refresher}>
            <DataContext.Provider value={data}>
              <InvalidContext.Provider value={invalid}>
                <ErrorContext.Provider value={error}>
                  <LoadingContext.Provider value={loading}>
                    {children}
                  </LoadingContext.Provider>
                </ErrorContext.Provider>
              </InvalidContext.Provider>
            </DataContext.Provider>
          </DataRefreshContext.Provider>
        </DataUpdateContext.Provider>
      </ToggleHaltRequestsContext.Provider>
    );
  };

  const useREST = () => {
    return {
      data: useContext(DataContext),
      refresh: useContext(DataRefreshContext),
      update: useContext(DataUpdateContext),
      error: useContext(ErrorContext),
      loading: useContext(LoadingContext),
      invalid: useContext(InvalidContext),
      haltRequests: useContext(ToggleHaltRequestsContext),
    };
  };

  return {
    useREST,
    RESTProvider,
  };
}

export default CreateRESTContextProvider;
