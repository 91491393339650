/** ******************************************************************************
USA States
 * Gets a list of all US States, and whether Tenet supports that state
 ******************************************************************************* */

import { HTTPMethod, HTTPResponse } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPUSStatesResponse } from "./client.types";

const getStates: () => Promise<{
  response: null | HTTPResponse<HTTPUSStatesResponse>;
  error: null | HTTPResponse<HTTPUSStatesResponse>;
}> = async () => {
  const response = await makeRequest<HTTPUSStatesResponse>(
    HTTPMethod.GET,
    restPaths.usStates,
    {},
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getStates;
