import getStringFromQuery from "#components/util/getStringFromQuery";

import CreateInitialPageContextProvider from "./GenericInitialPageInfoProvider";
import { useRouterContext } from "./Router";

interface Props {
  children: React.ReactNode;
}

const { InitialPageContextProvider, useInitialPageContext } =
  CreateInitialPageContextProvider("initialPageProvider");

export const InitialPageInfoProvider = ({ children }: Props) => {
  const { query } = useRouterContext();
  // MPR, ^, remember, this query is the weird amalgamation of all network
  // properties from nextJS. It includes both actual query parameters as
  // well as path parameters. Be careful!

  // MPR, 2023/6/15: This ID is bad. This is the old way of doing things.
  // this exists in the origination flows, and is bad because all sorts
  // of things like to clear the query params, making this brittle and
  // flaky. We should eventually remove this, but at the very least do
  // not use this for new pages.
  const absoluteOldOriginationApplicationId = getStringFromQuery(query.id);

  // MPR: These IDs are good. They are currently used in the portal, and other
  // pages that have nice, structured urls like /loans/<loanId>/
  // We like this. Do more of this.
  const absoluteApplicationId = getStringFromQuery(query.appId);
  const absoluteLoanId = getStringFromQuery(query.loanId);
  return (
    <InitialPageContextProvider
      absoluteApplicationId={
        absoluteApplicationId || absoluteOldOriginationApplicationId
      }
      absoluteLoanId={absoluteLoanId}
    >
      {children}
    </InitialPageContextProvider>
  );
};

export const useInitialPageInfo = () => {
  return useInitialPageContext();
};
