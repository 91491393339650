/** ******************************************************************************
Get Payments
 * Gets a list of the currently logged in user's payment history
 ******************************************************************************* */

import { HTTPMethod, HTTPResponse } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPGetPaymentsResponse } from "./client.types";

const getPayments: (loanId: string) => Promise<{
  response: null | HTTPResponse<HTTPGetPaymentsResponse>;
  error: null | HTTPResponse<HTTPGetPaymentsResponse>;
}> = async (loanId) => {
  const response = await makeRequest<HTTPGetPaymentsResponse>(
    HTTPMethod.GET,
    restPaths.payments(loanId).get,
    {},
    true,
    "lms",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getPayments;
