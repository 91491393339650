import { HTTPPutPaymentPreference } from "#api.los/client.types";
import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

/**
 * Posts a user's payment preference for their application (autopay vs manual payments)
 * @param applicationId application to specify for payment preference
 * @param hasOptedInForAutopay boolean, true to opt in to Autopay
 * @returns ok/error tuple. Value of tuple is the HTTP response details in either case
 */
const postAutopayPreference = async (
  applicationId: string,
  hasOptedInForAutopayParam: boolean,
) => {
  const hasOptedInForAutopay = {
    hasOptedInForAutopay: hasOptedInForAutopayParam,
  };
  const response = await makeRequest<HTTPPutPaymentPreference>(
    HTTPMethod.POST,
    restPaths.application(applicationId).payments.postAutopayPreference,
    { body: hasOptedInForAutopay },
    true,
    "los",
  );

  if (!response.error) {
    return { response, error: null };
  }

  return { response: null, error: response };
};

export default postAutopayPreference;
