import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { BudgetUserParams } from "./client.types";

/**
 * Tracks a FE user who entered their email on the budget flow
 * @param payload payload for event
 * @returns ok/error tuple. Value of tuple is the HTTP response details in either case
 */
const trackBudgetUser = async (payload: BudgetUserParams) => {
  await makeRequest<{ status: string }>(
    HTTPMethod.PUT,
    restPaths.budget.track,
    {
      body: {
        ...payload,
        zipCode: payload.zipCode ? +payload.zipCode : undefined,
      },
    },
    false,
  );
};

export default trackBudgetUser;
