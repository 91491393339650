/** ******************************************************************************
Shared HTTP Client Wrapper
 * Package Root - import this!
 * Structures the REST API client. Client is organized primarily by entity,
 * though there are a handful of top level functions like `isAuthenticated`
 * This shared client exists to be able to be called by other clients for meta
 * tasks such as tracking calls
 ******************************************************************************* */

import type { Auth } from "firebase/auth";

import trackEvent from "#api.shared/tracking.post";
import subscribeEmail from "#api.shared/tracking.subscribeEmail";
import trackOfferConfirmed from "#api.shared/tracking.trackOfferConfirmed";
import { getAuth } from "#auth/utils";

const authKey = Symbol("auth");
const tokenKey = Symbol("token");

interface APIClientCommon {
  [authKey]: undefined | Auth;
  [tokenKey]: undefined | string;
  isAuthenticated: () => Promise<boolean>;
  getToken: () => undefined | string;
  logOut: () => void;
}

/**
 * Structures the API client
 */
const SharedClientCommon: APIClientCommon = {
  [authKey]: undefined,
  [tokenKey]: undefined,
  /** Checks to see if the user is logged in
   * Note that this will return true even if
   * the user's session has expired.
   * MPR, 2022/8/19: This could concievably live under "user" but
   * given that it will be one of the most utilized client methods,
   * I favored discoverability over consistency with "register"
   */
  isAuthenticated: async () => {
    if (SharedClient[tokenKey]) {
      return true;
    }
    const auth = await getAuth();
    const token = await auth.currentUser?.getIdToken();
    SharedClient[authKey] = auth;
    SharedClient[tokenKey] = token;
    return !!token;
  },
  getToken: () => {
    /* MPR, 2022/8/19: note that we do _not_ invoke isAuthenticated here.
     * it is the responsibility of the consumer to ensure that this token
     * exists before reading it. Additonally, it not being present is not
     * explicitly an error - it may be expected. */
    return SharedClient[tokenKey];
  },
  logOut: async (shouldRedirect = true) => {
    const auth = await getAuth();
    auth.signOut();
    document.cookie = "";
    localStorage.clear();
    sessionStorage.clear();
    const url = new URL(window.location.href);
    url.searchParams.delete("id");
    if (shouldRedirect) document.location = url.href;
  },
};
const SharedClientMethods = {
  tracking: {
    post: trackEvent,
    trackOfferConfirmed,
    subscribeEmail,
  },
};

const SharedClient = {
  ...SharedClientCommon,
  ...SharedClientMethods,
};

export default SharedClient;

export type APIClient = typeof SharedClient;
