/** ******************************************************************************
Get User
 * Gets the current user
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPUserGet, LOSAPIResponse } from "./client.types";

const getUser: () => LOSAPIResponse<HTTPUserGet> = async () => {
  const response = await makeRequest<HTTPUserGet>(
    HTTPMethod.GET,
    restPaths.user.get,
    {},
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getUser;
