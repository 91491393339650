import Cookies from "js-cookie";
import random from "random";
import { useEffect, useState } from "react";
import useStorageState from "react-use-storage-state";

import LOSClient from "#api.los/client";
import { useInitialPageInfo } from "#components/provider/InitialPageInfo";
import safelyParseJSON from "#components/util/safelyParseJson";
import { experimentsConfig } from "#services/ExperimentService/experiments.config";
import {
  BucketConfig,
  Experiment,
  ExperimentEventType,
} from "#services/ExperimentService/experiments.types";

export const COOKIE_FALLBACK_KEY = "optimisticExperiments";

export const assignABucket = (experimentName: string): BucketConfig => {
  const config = (() => {
    const expermimentKey = Object.keys(experimentsConfig).find(
      (k) => k === experimentName,
    );
    if (!expermimentKey) {
      throw new Error(`No experiment config found for ${experimentName}`);
    }
    return experimentsConfig[expermimentKey as Experiment];
  })();
  const { buckets } = config;
  const defaultedBuckets = buckets.map((bucket) => ({ weight: 1, ...bucket }));
  const totalBuckets = defaultedBuckets.reduce(
    (acc, bucket) => acc + bucket.weight,
    0,
  );
  const randomBucket = random.int(0, totalBuckets - 1);

  let sum = 0;
  const assignedBucket = defaultedBuckets.find((bucket) => {
    sum += bucket.weight;
    return randomBucket < sum;
  });

  if (!assignedBucket) {
    throw new Error(`No bucket was assigned for experiment ${experimentName}`);
  }

  return assignedBucket;
};

export const registerExperiment = async (
  experiment: string,
  bucket: string,
) => {
  const config = (() => {
    const expermimentKey = Object.keys(experimentsConfig).find(
      (k) => k === experiment,
    );
    if (!expermimentKey) {
      throw new Error(`No experiment config found for ${experiment}`);
    }
    return experimentsConfig[expermimentKey as Experiment];
  })();

  const bucketConfig = config.buckets.find((b) => b.bucketName === bucket);
  if (!bucketConfig) {
    throw new Error(
      `Invalid bucket requested (${bucket}) for experiment ${experiment}`,
    );
  }

  try {
    await LOSClient.user.experiments.add({
      experimentName: experiment,
      bucketName: bucket,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }
};

export const getCookieExperiments = () => {
  const allCookies = Cookies.get();
  const experimentCookieKeys = Object.keys(allCookies).filter((cookie) =>
    cookie.startsWith("xp-"),
  );
  return experimentCookieKeys.reduce((acc, cookieName) => {
    const experimentName = cookieName.replace("xp-", "");
    acc[experimentName] = allCookies[cookieName];
    return acc;
  }, {});
};

export const useExperiments = () => {
  const { data, loading } = useInitialPageInfo();
  const [experiments, setExperiments] = useState<Record<string, string>>({});

  // #region fallback experiment loading pattern
  const fallbackRawExperimentString =
    sessionStorage.getItem(COOKIE_FALLBACK_KEY);

  const [fallbackOptimisticExperiments] = useStorageState<
    Record<string, string>
  >(
    COOKIE_FALLBACK_KEY,
    fallbackRawExperimentString
      ? (safelyParseJSON(fallbackRawExperimentString) as Record<string, string>)
      : {},
  );
  // #endregion

  useEffect(() => {
    // these two experiment objects should be exactly identical. The response body
    // should be removed once migrated to cookies
    const bodyExperiments = data?.experiments;
    const cookieExperiments = getCookieExperiments();

    setExperiments({
      ...fallbackOptimisticExperiments,
      ...bodyExperiments,
      ...cookieExperiments,
    });
  }, [data, loading, fallbackOptimisticExperiments]);

  return experiments;
};

export const useExperimentEvents = () => {
  const experiments = useExperiments();
  const track = async <X extends Experiment>(
    experiment: X,
    event: ExperimentEventType<X>,
  ) => {
    try {
      // MPR, 2023/08/15: There seems to be a very short, but impactful delay between
      // request recieved -> loading updated -> cookies updated. This just makes sure were always right.
      const cookieExperiments = getCookieExperiments();
      const latestExperiments = {
        ...experiments,
        ...cookieExperiments,
      } as typeof experiments;
      const response = await LOSClient.user.experiments.track({
        experimentName: experiment,
        eventName: event,
        bucketName: latestExperiments[experiment],
      });

      return !response.error;
    } catch (e) {
      return false;
    }
  };

  return track;
};
