export const keys = {
  end: "end",
  enter: "enter",
  enterEnd: "enterEnd",
  enterStart: "enterStart",
  leave: "leave",
  leaveEnd: "leaveEnd",
  leaveStart: "leaveStart",
  start: "start",
  transition: "transition",
};
