import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { LOSAPIResponse } from "./client.types";
/**
 * Sets tasks to in review for ops
 * @param applicationId loan application ID for the current application (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const setTasksToFinalized: (appId: string) => LOSAPIResponse<any> = async (
  appId,
) => {
  const response = await makeRequest<void>(
    HTTPMethod.PUT,
    restPaths.task(appId).setTasksToReview,
    undefined,
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default setTasksToFinalized;
