/** ******************************************************************************
 Gets Payoff Document download link and status
******************************************************************************* */

import { HTTPMethod, HTTPResponse } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import {
  HTTPGetPayoffDocument,
  HTTPGetPayoffDocumentError,
} from "./client.types";

const getPayoffDocumentLink: (loanId: string) => Promise<{
  response: null | HTTPResponse<HTTPGetPayoffDocument>;
  error: null | HTTPResponse<HTTPGetPayoffDocumentError>;
}> = async (loanId) => {
  const response = await makeRequest<
    HTTPGetPayoffDocument | HTTPGetPayoffDocumentError
  >(HTTPMethod.GET, restPaths.payoff(loanId).getDocument, {}, true, "lms");
  if (!response.error) {
    return {
      response: response as HTTPResponse<HTTPGetPayoffDocument>,
      error: null,
    };
  }
  return {
    response: null,
    error: response as HTTPResponse<HTTPGetPayoffDocumentError>,
  };
};

export default getPayoffDocumentLink;
