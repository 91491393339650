import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

/**
 * Tracks email subscription status
 * @param email user's email
 * @param url pages url
 * @returns ok/error tuple. Value of tuple is the HTTP response details in either case
 */
const subscribeEmail = async (email: string, url: string) => {
  await makeRequest<{ status: string }>(
    HTTPMethod.POST,
    restPaths.tracking.subscribeEmail,
    {
      body: {
        email,
        url,
      },
    },
    false,
  );
};

export default subscribeEmail;
