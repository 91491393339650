/** ******************************************************************************
Email Offer
 * uses the /applications/${applicationId}/offers/email endpoint to send an email to the user with their offer details
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPEmailOfferRes, LOSAPIResponse } from "./client.types";

/**
 * Sends an email detailing selected offer details for the user's current application
 * @param applicationId loan application ID for the current application (string)
 * @returns an object containing response and error properties (HTTPEmailOFferRes | null)
 */
const emailOffer: (
  applicationId: string,
  offerId: string,
  resumeUrl: string,
) => LOSAPIResponse<HTTPEmailOfferRes> = async (
  applicationId,
  offerId,
  resumeUrl,
) => {
  const response = await makeRequest<HTTPEmailOfferRes>(
    HTTPMethod.POST,
    restPaths.application(applicationId).offers.emailOffer,
    {
      body: {
        offerId,
        resumeUrl,
      },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default emailOffer;
