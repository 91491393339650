import { useEffect, useState } from "react";

const docEl =
  typeof document === "undefined" ? undefined : document?.documentElement;

/**
 * useInputType is a hook that may be used to implement similar functionality to
 * the CSS `:focus-visible` psuedo-selector that is not yet widely supported.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
 * for more information. The intent is to provide different focus indicators
 * based on the userʼs input modality (IE, mouse or keyboard).
 *
 * This works by adding a global event listeners to the document element for
 * `mousedown` and `keydown` events. When an event is triggered, we add an
 * 'input-type' data-attribute to the document element indicating the current
 * input type. The hook also returns the current input type as a string.
 *
 * This attribute can then be used as a CSS selector, which can be used
 * to provide custom styles based on the current input modality.
 */
function useInputType() {
  const [inputMode, setInputMode] = useState<"keyboard" | "mouse">("keyboard");

  const setAttr = (ev: { type: string }) => {
    const inputType = ev.type === "keydown" ? "keyboard" : "mouse";
    docEl?.setAttribute("data-input-type", inputType);
    setInputMode(inputType);
  };

  useEffect(() => {
    setAttr({ type: "keydown" });
    docEl?.addEventListener("mousedown", setAttr);
    docEl?.addEventListener("keydown", setAttr);

    return () => {
      docEl?.removeEventListener("mousedown", setAttr);
      docEl?.removeEventListener("keydown", setAttr);
    };
  }, []);

  return inputMode;
}

export default useInputType;
