/** ******************************************************************************
Update Autopay
 * Updates an AutopayConfig and ApplicationRefAutopay join
 ******************************************************************************* */

import {
  HTTPMethod,
  HTTPResponse,
  PaymentDayOfMonth,
} from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPCreateAutopay } from "./client.types";

const updateAutopay: (
  appId: string,
  autopayId: string,
  dayOfMonth: PaymentDayOfMonth,
  paymentMethodId: string,
  overrideAmountCents?: number,
) => Promise<{
  response: null | HTTPResponse<HTTPCreateAutopay>;
  error: null | HTTPResponse<HTTPCreateAutopay>;
}> = async (
  appId,
  autopayId,
  dayOfMonth,
  paymentMethodId,
  overrideAmountCents,
) => {
  const response = await makeRequest<HTTPCreateAutopay>(
    HTTPMethod.PUT,
    restPaths.application(appId, autopayId).payments.updateAutopay,
    {
      body: {
        dayOfMonth,
        paymentMethodId,
        overrideAmountCents,
        paymentSetting: "MonthlyPaymentAmount",
      },
    },
    true,
    "los",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default updateAutopay;
