import * as css from "./Divider.styles";
import { Props } from "./Divider.types";

/**
 * Dividers are used to provide a visual break between sections of content.
 *
 * This is styled version of an `<hr>` element, and accepts all DOM attributes
 * the `<hr>` element does, as well as a `variant` prop for setting the UI
 * variant to use.
 */
const Divider = ({ variant = "primary", ...otherProps }: Props) => (
  <hr css={css.root} cssProps={{ variant }} {...otherProps} />
);

export default Divider;
