import { css } from "#themes";

export const root = css({
  background: "$backgroundPrimary",
  color: "$textPrimary",
  display: "flex",

  variants: {
    variant: {
      inverted: {
        background: "$backgroundInverse",
        color: "$textInverse",
      },
    },
  },
});
