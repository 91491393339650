export enum DOCUSIGN_CALLBACK_RESPONSES {
  COMPLETE = "DOCUSIGN_CALLBACK_COMPLETE",
  MANUAL_INTERVENTION = "DOCUSIGN_CALLBACK_MANUAL_INTERVENTION",
  REFRESH = "DOCUSIGN_CALLBACK_REFRESH",
}

/* This gets called in _app.tsx */
const interperetAndMessageDocusignCallback = () => {
  /* possible events: (from https://developers.docusign.com/docs/esign-rest-api/esign101/concepts/embedding/)
        signing_complete
        cancel
        decline
        exception
        fax_pending
        session_timeout
        ttl_expired
        viewing_complete
    */
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search);
    const event = urlParams.get("event");
    if (event === "signing_complete") {
      console.info("Docusign signing: signing complete");
      window.parent.postMessage(DOCUSIGN_CALLBACK_RESPONSES.COMPLETE, "*");
    } else if (event === "cancel" || event === "decline") {
      console.info("Docusign signing: signing manually declined or canceled");
      window.parent.postMessage(
        DOCUSIGN_CALLBACK_RESPONSES.MANUAL_INTERVENTION,
        "*",
      );
    } else {
      console.info("Docusign signing: refreshing document");
      /* there very well may be cases (like cancel?) where we dont want to refresh.
            That said, all of these would be unrecoverable errors */
      window.parent.postMessage(DOCUSIGN_CALLBACK_RESPONSES.REFRESH, "*");
    }
  }
};

export default interperetAndMessageDocusignCallback;
