/** ******************************************************************************
Get Docusign Link
 * uses the /applications/${applicationId}/applicant/docusign endpoint
 * to get a link for document signing
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPDocusignRes, LOSAPIResponse } from "./client.types";

/**
 * Gets docusign link for the current applicant
 * @param applicationId loan application ID for the current application (string)
 * @param documentId id for the loanUser document (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const getDocusignLink: (
  applicationId: string,
  documentId: string,
) => LOSAPIResponse<HTTPDocusignRes> = async (applicationId, documentId) => {
  const query = `?documentId=${documentId}`;
  const response = await makeRequest<HTTPDocusignRes>(
    HTTPMethod.GET,
    `${restPaths.application(applicationId).docusign}${query}`,
    {},
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getDocusignLink;
