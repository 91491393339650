import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPWaitlistRes, LOSAPIResponse } from "./client.types";

const postWaitlist: (
  name: string,
  email: string,
  type: "NewLoan" | "Refi",
) => LOSAPIResponse<HTTPWaitlistRes> = async (
  name: string,
  email: string,
  type: "NewLoan" | "Refi",
) => {
  const response = await makeRequest<HTTPWaitlistRes>(
    HTTPMethod.POST,
    restPaths.application().waitlist,
    {
      body: {
        name,
        email,
        type,
      },
    },
    false,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default postWaitlist;
