import { createContext, useMemo, useState } from "react";

import { Modal } from "./Modal";
import { ModalConfigType } from "./Modal.types";
import { ModalMap, ModalType } from "./ModalConfig";

export const ModalContext = createContext<{
  openModal: (config: ModalConfigType) => void;
  closeModal: (modalType: ModalType) => void;
}>({
  openModal: () => undefined,
  closeModal: () => undefined,
});

const defaultOpenModals: ModalConfigType[] = [];

export const ModalProvider: React.FC = ({ children }) => {
  const [openModals, setOpenModals] =
    useState<ModalConfigType[]>(defaultOpenModals);
  const providerValue = useMemo(
    () => ({
      openModal: (modalConfig: ModalConfigType) => {
        setOpenModals((prevOpenModals) => [...prevOpenModals, modalConfig]);
      },
      closeModal: (modalType: ModalType) => {
        setOpenModals((prevOpenModals) =>
          prevOpenModals.filter((modal) => modal.type !== modalType),
        );
      },
    }),
    [setOpenModals],
  );

  // Array of Open Modals

  return (
    <ModalContext.Provider value={providerValue}>
      {openModals.map((modal) => {
        const Component = ModalMap[modal.type];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <Modal key={modal.type} {...modal} Component={Component} />;
      })}
      {children}
    </ModalContext.Provider>
  );
};

// Example
// const ComponentThatAccessesSignUpModal = () => {
//   const { openModal } = useContext(ModalContext);

//   return (
//     <Button
//       aria-label="Sign Up"
//       onClick={() =>
//         openModal({
//           type: ModalType.SIGNUP,
//           componentProps: {
//             postImage: "" as unknown as Asset,
//             title: "Sign Up",
//             description: "Sign Up",
//             date: "Sign Up",
//             minRead: 0,
//             link: "Sign Up",
//           },
//           modalProps: {
//             title: "Sign Up",
//             content: "Sign Up",
//             onClose: noop,
//           },
//         })
//       }
//     >
//       Sign Up
//     </Button>
//   );
// };
