import isEmpty from "lodash/isEmpty";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

/**
 * A hook for triggering actions when a query is present.
 *
 * @param queryKey Key which triggers a callback
 * @param callback A function called when a query parameter is present. This optionally takes the value of the query parameter.
 */
export const useConsumeQuery = (
  queryKey: string,
  callback?: (queryValue: string) => void,
  options?: { preserve?: boolean },
) => {
  const router = useRouter();
  const [state, setState] = useState<string>();

  useEffect(() => {
    const param = router.query[queryKey] as unknown as string | undefined;

    // if no param, or param unchanged don't do anything
    if (!param || state === param) {
      return;
    }

    callback?.(param);
    setState(param);

    if (options?.preserve) {
      return;
    }

    const newQuery = router.query;
    delete newQuery[queryKey];

    router.replace(
      {
        pathname: router.pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true },
    );
  }, [router.query]);

  return state;
};

type ReturnObj<T extends readonly string[]> = {
  [key in T[number]]: string | undefined;
};

/**
 * A hook for triggering actions when a query is present.
 *
 * @param queryKeys an array of keys which trigger a callback
 * @param callback A function called when one or more of the query parameters is present.
 * This takes a single object with the query keys as keys and the query values as values.
 *
 * @returns An object with the query keys as keys and the query values as values.
 *
 * ex.
 * const { apple, baseball, caterpillar } = useConsumeQueries(["apple", "baseball", "caterpillar"] as const);
 *
 * NOTE:
 * It's important that you typecase the array of keys `as const`, otherwise the type of the
 * return object will be a string
 *
 * USAGE NOTE:
 * It is not safe (as written) to use this hook across multiple components while watching
 * the same query param value. This hook currently depends on a side effect in global state
 * (the querystring). We cannot share this component's internal state across instances
 * naively, because they are associated with the rest of the page's url, or even more
 * particularly, with the individual _render_ of the page in which a set of components
 * might have been present. Barring other QS manipulation by JS, the earliest we can know
 * that our internal state is stale is on navigate away. If we want/need to have multiple
 * components watching the same query param, we will need to track the path, store it with
 * the QS values, and clear it on navigate.
 */
export const useConsumeQueries = <T extends readonly string[]>(
  queryKeys: T,
  callback?: (queryValue: ReturnObj<T>) => void,
): ReturnObj<T> => {
  const router = useRouter();
  const [state, setState] = useState<ReturnObj<T>>({} as ReturnObj<T>);

  useEffect(() => {
    const params = queryKeys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: router.query[key] as string | undefined,
      }),
      {} as ReturnObj<T>,
    );

    // if no param, don't do anything
    if (!isEmpty(Object.values(params).filter(Boolean))) {
      callback?.(params);
      setState(params);

      const newQuery = router.query;
      queryKeys.forEach((key) => {
        delete newQuery[key];
      });

      router.replace(
        {
          pathname: router.pathname,
          query: newQuery,
        },
        undefined,
        { shallow: true },
      );
    }
    return () => {
      // we need to cleanup this effect on unmount. Its state becomes stale on route change
      setState({} as ReturnObj<T>);
    };
  }, [router.query]);

  return state;
};
