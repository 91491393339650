import { createStitches } from "@stitches/react";

import media, { screenSizes } from "./breakpoints";
import defaultTheme from "./default";
import * as spacing from "./util/spacing";
import * as textStyles from "./util/textStyle";

export const { config, css, getCssText, globalCss, theme } = createStitches({
  media,
  theme: defaultTheme,
  utils: { ...spacing, ...textStyles },
});

export const globalThemeStyles = globalCss({
  body: { color: "$textPrimary", fontSize: "$body1" },
});

export const breakpoints = screenSizes;
