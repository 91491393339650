/** ******************************************************************************
Select Direct Offer
 * uses the /applications/${applicationId}/offers/${offerId} endpoint to select a loan offer
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import {
  HTTPSelectOfferRes,
  LOSAPIResponse,
  OfferAction,
} from "./client.types";

/**
 * Confirms  loan offer for the current application
 * @param applicationId loan application ID for the current application (string)
 * @param offerId loan offer ID (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const selectDirectOffer: (
  applicationId: string,
  offerId: string,
) => LOSAPIResponse<HTTPSelectOfferRes> = async (applicationId, offerId) => {
  const action = { action: OfferAction.Select };
  const response = await makeRequest<HTTPSelectOfferRes>(
    HTTPMethod.PUT,
    restPaths.application(applicationId).offers.select(offerId),
    {
      body: action,
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default selectDirectOffer;
