import Head from "next/head";

import Header from "#components/partial/Header/Header";
import { meta } from "#constants/meta";
import { flex, layout } from "#styles";
import { css as cssFn } from "#themes";

import * as css from "./Wide.styles";
import { Props } from "./Wide.types";

const Wide = ({
  children,
  header,
  footer,
  variant = "primary",
  showHeader = true,
}: Props) => {
  return (
    <>
      <Head>
        <title key={meta.W3_TITLE}>
          Affordable Electric Vehicle Financing | Tenet
        </title>
      </Head>

      {showHeader && (header || <Header />)}

      <div
        css={[flex.flexGrow, flex.flexShrink, css.root]}
        cssProps={{ variant }}
      >
        {children}
      </div>

      <div
        css={[
          layout.fullWidthPercent,
          flex.flexColumn,
          cssFn({
            margin: "0 auto",
            length: 0,
          }),
        ]}
      >
        {footer}
      </div>
    </>
  );
};

export default Wide;
