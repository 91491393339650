/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { ArrowBack } from "@mui/icons-material";
import classNames from "classnames";
import { useContext, useEffect } from "react";

import { Close, Line22 } from "#components/base/Icon/Icon";

import s from "./Modal.module.scss";
import { ModalConfigType } from "./Modal.types";
import { ModalMap } from "./ModalConfig";
import { ModalContext } from "./ModalProvider";

type AllModalComponentProps = {
  [Key in keyof typeof ModalMap]: {
    Component: typeof ModalMap[Key];
  } & ModalConfigType;
}[keyof typeof ModalMap];

type AnyComponent = (...args: unknown[]) => JSX.Element;

export const Modal = (props: AllModalComponentProps) => {
  const { Component, componentProps, modalProps, type } = props;
  const { closeModal } = useContext(ModalContext);

  /**
   * This function applies the modalProps.onClose() function
   * at component unmount. This makes it so that it will run
   * no matter how the modal is closed
   */
  const handleUnmount = () => {
    modalProps.onUnmount();
  };
  useEffect(() => {
    return handleUnmount;
  }, []);

  // Was unable to get the typing right on this guy - didn't want to waste any additional time on it
  // This should always be correct, because we're constraining the type to be the component
  // that's returned from the ModalMap within the props
  const Comp = Component as AnyComponent;

  const titleDivider: React.CSSProperties = {
    borderBottom: "1px solid #E6E6E8",
  };

  const modalComponent = (
    <>
      <div
        className={classNames("modal-wrapper", s.container)}
        onClick={() => {
          closeModal(type);
        }}
      >
        <div
          className={classNames(s.modal, modalProps.containerClassName || "")}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className={s.title}
            style={modalProps.titleDivider ? titleDivider : undefined}
          >
            <div className={s.span}>
              {modalProps.onBack && (
                <>
                  <span>
                    <ArrowBack />
                    <button type="button">Back</button>
                  </span>
                  <Line22 />
                </>
              )}
              {modalProps.title && modalProps.title}
            </div>
            <button
              type="button"
              id="close-button"
              aria-label="close-button"
              onClick={() => {
                closeModal(type);
              }}
            >
              <Close />
            </button>
          </div>
          <div
            className={classNames(modalProps.className, {
              [s.childElement]: modalProps.removeChildPadding,
              [s.childElementWithPadding]: !modalProps.removeChildPadding,
            })}
          >
            <Comp
              {...({
                ...componentProps,
                /* extension props for all modals */
                buttonWrapperClass: s.stickyButton,
              } as object)}
            />
          </div>
        </div>
      </div>
      <div
        className={classNames(s.backdrop, { [s.showModalBackdrop]: false })}
      />
    </>
  );

  if (modalProps.providers) {
    const Providers = modalProps.providers;
    return <Providers>{modalComponent}</Providers>;
  }

  return modalComponent;
};

// Below will fail
// const CallModal = () => {
//   return <Modal type={ModalType.CONFIRM} Component={<div />} />;
// };
