/** ******************************************************************************
 * Get apr configuration for the homepage calculator
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { losRestPaths } from "#constants/restPaths";

import { extractBody, ResponseLOSGetCalculator } from "./client.types";

type Response = ResponseLOSGetCalculator;
type Body = extractBody<Response>;

const getCalculatorConfig: () => Response = async () => {
  const response = await makeRequest<Body>(
    HTTPMethod.GET,
    losRestPaths.calculator.get,
    {},
    false,
    "los",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getCalculatorConfig;
