const radii = {
  0: "0px",
  10: "10px",
  24: "24px",

  // Named aliases for border radii.
  button: "$24",
  card: "$10",
  modalDesktop: "$0",
  modalMobile: "$10",
};

export default radii;
