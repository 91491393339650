import classNames from "classnames";

import LottieLogo from "../LottieLogo/LottieLogo";
import s from "./LoadingState.module.scss";
import type { Props } from "./LoadingState.types";
import { Dot } from "./LoadingState.util";

const LoadingState = ({
  copy,
  dots = true,
  modal = false,
  fullHeight = true,
}: Props) => {
  return (
    <div
      className={classNames({
        [s.modalLoadingContainer]: modal,
        [s.loadingContainer]: !modal,
        [s.fullHeight]: fullHeight,
      })}
    >
      <LottieLogo height={49} width={65} />
      <p>
        {copy ?? "Loading"}
        {dots && (
          <>
            <Dot className={s.loaderDot} />
            <Dot className={s.loaderDot} />
            <Dot className={s.loaderDot} />
          </>
        )}
      </p>
    </div>
  );
};

export default LoadingState;
