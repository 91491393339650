import { forwardRef } from "react";

import { layout } from "#styles";

import * as css from "./Input.styles";
import type { Component as ComponentType, Props } from "./Input.types";

const Input: ComponentType = forwardRef(
  <T extends React.ElementType = "input">(
    {
      after,
      as,
      before,
      className,
      disabled = false,
      error = false,
      valid = false,
      variant = "primary",
      ...otherProps
    }: Props<T>,
    ref?: PolymorphicRef<T>,
  ) => {
    const Component = as || "input";

    return (
      <div className={className} css={[layout.gap(8), css.root]}>
        {before && <div children={before} css={css.contentWrapper} />}

        <Component
          aria-invalid={error}
          css={css.input}
          cssProps={{ error, valid, variant }}
          disabled={disabled}
          ref={ref}
          type="text"
          {...otherProps}
        />

        <div
          css={[layout.absoluteFill, css.background]}
          cssProps={{ variant }}
        />
        {after && <div children={after} css={css.contentWrapper} />}
      </div>
    );
  },
);

export default Input;
