import { toast, ToastOptions } from "react-toastify";

import { dispatchError } from "#analytics/dispatchError";

export const alert = (text: string, props?: ToastOptions) =>
  toast(text, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    ...props,
  });

export const trackedErrorAlert = (
  error: Error,
  errorCode?: string,
  props?: ToastOptions,
  displayText?: string,
  displayAlert?: boolean,
) => {
  dispatchError(error, errorCode);

  if (displayAlert) {
    toast(displayText || error.message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      ...props,
    });
  }
};

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error;
  return Error("Unknown Error");
}

export const tenetAlert = alert;
