const lineHeights = {
  0: "0px",
  1: "1px",
  12: "12px",
  18: "18px",
  20: "20px",
  24: "24px",
  28: "28px",
  32: "32px",
  36: "36px",
  46: "46px",
  54: "54px",
  60: "64px",

  // Named aliases for line heights.
  body1: "$24",
  body2: "$20",
  body3: "$28",
  body4: "$32",
  body6: "$12",
  heading1: "$54",
  heading2: "$46",
  heading3: "$36",
  label: "$18",
};

export default lineHeights;
