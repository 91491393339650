/** ******************************************************************************
Get Active Loan Applicant ("LoanUser")
 * Retrieves the Applicant entity, which joins a "User" and a "LoanApplication"
 * hence "LoanUser" in DB
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPApplicantRes, LOSAPIResponse } from "./client.types";

const getApplicant: (
  applicationId: string,
) => LOSAPIResponse<HTTPApplicantRes> = async (applicationId) => {
  const response = await makeRequest<HTTPApplicantRes>(
    HTTPMethod.GET,
    restPaths.application(applicationId).applicant,
    {},
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getApplicant;
