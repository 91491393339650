import { LoanType } from "#components/partial/ApplyPage/ApplyPage.types";
import { RefiStep } from "#components/partial/RefiLoanWizard/RefiLoanFormFlow.types";
import { Page } from "#util/pageNavigationHelpers.page";

/**
 * Design-specified progress count for loan applications do not correspond to
 * the actual amount of distinct steps in the wizard/application process, so use
 * a pre-set value for the progress total.
 */
export const STEP_TOTAL = {
  [LoanType.Purchase]: Object.keys(Page ?? {})
    .length /* MPR, 2023/5/19: Page is undefined in test environments? */,
  [LoanType.Refinance]: Object.keys(RefiStep).length,
};

/**
 * These are the indexes of the various entries in the CoBorrower Wizard. If the
 * wizard's order is updated, these must be changed as well.
 */
export const COBORROWER_STEPS = {
  WELCOME: 0,
  SIGNUP: 1,
  PERSONAL_INFO: 2,
  ADDRESS: 3,
  HOUSING: 4,
  EMPLOYMENT: 5,
  SUMMARY: 6,
  SOFT_CREDIT: 7,
  AWAIT_PRIMARY: 8,
  HARD_CREDIT: 9,
  PLAID_VERIFICATION: 10,
  SIGN_CONTRACT: 11,
  SUCCESS: 12,
};

/**
 * These are the indexes of the various entries in the Direct Purchase Loan
 * Application Wizard. If the wizard's order is updated, these must be changed
 * as well.
 */
export const DIRECT_STEPS = {
  STATE: 0,
  SIGNUP: 1,
  PERSONAL_INFO: 2,
  ADDRESS: 3,
  HOUSING: 4,
  EMPLOYMENT: 5,
  SUMMARY: 6,
  SOFT_CREDIT: 7,
  PREAPPROVAL: 8,
  VEHICLE_INFO: 9,
  LOAN_DETAILS: 10,
  HARD_CREDIT: 11,
  PLAID_VERIFICATION: 12,
  CONFIRM_OFFER: 13,
  LOAN_CONTRACT: 14,
  ECHECK: 15,
  SUCCESS: 16,
};

/**
 * These are the indexes of the various entries in the Refi Loan Application
 * Wizard. If the wizard's order is updated, these must be changed as well.
 */
export const REFI_STEPS = {
  STATE: 0,
  CAR_INFO: 1,
  SIGNUP: 2,
  PERSONAL_INFO: 3,
  ADDRESS: 4,
  SOFT_CREDIT: 5,
  PREVIOUS_LOAN: 6,
  EMPLOYMENT: 7,
  HOUSING: 8,
  SUMMARY: 9,
  LOAN_OFFERS: 10,
  HARD_CREDIT: 11,
  PLAID_VERIFICATION: 12,
  FINAL_OFFER: 13,
  LOAN_CONTRACT: 14,
  SUCCESS: 15,
};
