import { createContext, useCallback, useContext } from "react";
import { useStorageState } from "react-use-storage-state";

import LOSAPIClient from "#api.los/client";
import { HTTPInitPageResponse } from "#api.los/client.types";
import { LoanStatus } from "#api.los/losDto.types";
import globalShim from "#components/util/nextGlobalShim";

import CreateRESTContextProvider from "./GenericRESTProvider";
import { useReadyContext } from "./Ready";

const { sessionStorage } = globalShim;

interface Props {
  children: React.ReactNode;
  absoluteLoanId?: string;
  absoluteApplicationId?: string;
}

function CreateInitialPageContextProvider(key: string) {
  const { RESTProvider, useREST } =
    CreateRESTContextProvider<HTTPInitPageResponse>();

  const SelectedApplicationContext = createContext<string | null>(null);
  const SetSelectedApplicationContext = createContext<
    ((id: string | null) => void) | null
  >(null);

  const SelectedLoanContext = createContext<string | null>(null);
  const SetSelectedLoanContext = createContext<
    ((id: string | null) => void) | null
  >(null);

  const InitialPageContextProvider = ({
    children,
    absoluteApplicationId,
    absoluteLoanId,
  }: Props) => {
    const { data: ready } = useReadyContext();
    const [selectedApplication, setSelectedApplication] = useStorageState<
      string | null
    >(
      `${key}-selectedApplication`,
      absoluteApplicationId || null,
      sessionStorage,
    );
    const [selectedLoan, setSelectedLoan] = useStorageState<string | null>(
      `${key}-selectedLoan`,
      absoluteLoanId || null,
      sessionStorage,
    );

    const wrappedSetSelectedApplication = useCallback(
      (id: string | null) => {
        /* MPR, 2023/2/16: It is absolutely critical that this check not be changed,
         * and occur first. The absolute value coming from the URL must always win.
         */
        if (absoluteApplicationId) {
          return setSelectedApplication(absoluteApplicationId);
        }
        return setSelectedApplication(id);
      },
      [absoluteApplicationId],
    );
    const wrappedSetSelectedLoan = useCallback(
      (id: string | null) => {
        /* MPR, 2023/2/16: It is absolutely critical that this check not be changed,
         * and occur first. The absolute value coming from the URL must always win.
         */
        if (absoluteLoanId) {
          return setSelectedLoan(absoluteLoanId);
        }
        return setSelectedLoan(id);
      },
      [absoluteLoanId],
    );

    return (
      <SetSelectedApplicationContext.Provider
        value={wrappedSetSelectedApplication}
      >
        <SelectedApplicationContext.Provider
          value={absoluteApplicationId || selectedApplication}
        >
          <SetSelectedLoanContext.Provider value={wrappedSetSelectedLoan}>
            <SelectedLoanContext.Provider
              value={absoluteLoanId || selectedLoan}
            >
              <RESTProvider
                signal={selectedApplication ?? undefined}
                storageKey={key}
                getData={async () => {
                  if (!ready) return {};
                  const { response, error } = await LOSAPIClient.init();
                  const invalidReason = error?.statusText;
                  const data = response?.body;
                  if (error) {
                    return { error: invalidReason };
                  }

                  // don't expose closed applications
                  if (data?.applications) {
                    data.applications = data.applications.filter(
                      (app) => app.status !== LoanStatus.Closed,
                    );
                  }

                  return { response: data };
                }}
              >
                {children}
              </RESTProvider>
            </SelectedLoanContext.Provider>
          </SetSelectedLoanContext.Provider>
        </SelectedApplicationContext.Provider>
      </SetSelectedApplicationContext.Provider>
    );
  };

  const useInitialPageContext = () => {
    const restContexts = useREST();

    return {
      ...restContexts,
      setSelectedApplication: useContext(SetSelectedApplicationContext),
      selectedApplication: useContext(SelectedApplicationContext),
      setSelectedLoan: useContext(SetSelectedLoanContext),
      selectedLoan: useContext(SelectedLoanContext),
    };
  };

  return {
    useInitialPageContext,
    InitialPageContextProvider,
  };
}

export default CreateInitialPageContextProvider;
