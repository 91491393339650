/** ******************************************************************************
Update User Attribution
 * uses the /user/attribution endpoint to update
 * a user's self-reported attribution
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPUpdateUserRes, LOSAPIResponse } from "./client.types";

/**
 * Updates/sets a user's first name, last name, and date of birth
 * @param firstName first name to update to (string)
 * @param lastName last name to update to (string)
 * @param dateOfBirth date of birth to update to (this is of type Date NOT string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const updateAttribution: (
  attribution: string,
) => LOSAPIResponse<HTTPUpdateUserRes> = async (attribution) => {
  const response = await makeRequest<HTTPUpdateUserRes>(
    HTTPMethod.PUT,
    restPaths.user.updateAttribution,
    {
      body: { selfReported: attribution },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};
export default updateAttribution;
