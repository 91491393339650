/** ******************************************************************************
Get Vehicle
 * Gets the vehicle associated with this loan, if any
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { LOSAPIResponse } from "./client.types";
import { VehiclePartialOutputDto } from "./losDto.types";

const getVehicle: (
  applicationId: string,
) => LOSAPIResponse<VehiclePartialOutputDto | null> = async (applicationId) => {
  const response = await makeRequest<VehiclePartialOutputDto | null>(
    HTTPMethod.GET,
    restPaths.vehicle(applicationId).get,
    {},
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getVehicle;
