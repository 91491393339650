import { useContext, useEffect } from "react";

import { useInitialPageInfo } from "#components/provider/InitialPageInfo";
import { ModalType } from "#components/provider/UIProviders/ModalProvider/ModalConfig";
import { ModalContext } from "#components/provider/UIProviders/ModalProvider/ModalProvider";
import { gexaEnabled } from "#util/env";
import Button from "#v2-components/molecules/Button/Button";
import { ButtonVariant } from "#v2-components/molecules/Button/Button.types";

import s from "./style.module.scss";

const NEW_TRACKING_LOCAL_STORAGE_NAME = "gexaModalAnnouncementSeen";

const getGexaModalSeen = () => {
  return localStorage.getItem(NEW_TRACKING_LOCAL_STORAGE_NAME);
};

const setGexaModalSeen = () => {
  localStorage.setItem(NEW_TRACKING_LOCAL_STORAGE_NAME, "true;path=/");
};

/**
 * Gexa announcement for Texas
 * Add to a page to automatically display a popup informing
 * users of the Gexa Texas program if they meet certain criteria
 * specifically being from texas
 */
const GexaAnnounce = () => {
  const { data: initDataReq } = useInitialPageInfo();
  const isErcot = initDataReq?.ercot;
  const hasSeenModal = (() => {
    if (typeof window === "undefined") return false;
    const hasSeenBanner = getGexaModalSeen();
    return hasSeenBanner != null && hasSeenBanner !== "";
  })();
  const { openModal, closeModal } = useContext(ModalContext);
  const showModal = () => {
    openModal({
      type: ModalType.GEXA,
      componentProps: {
        closeModal: () => {
          setGexaModalSeen();
          closeModal(ModalType.GEXA);
        },
      },
      modalProps: {
        title: "",
        removeChildPadding: true,
        // className: s.modalContentWrapper,
        containerClassName: s.modalWrapper,
        onUnmount: setGexaModalSeen,
      },
    });
  };
  useEffect(() => {
    if (gexaEnabled && isErcot && !hasSeenModal) {
      showModal();
    }
  }, [isErcot, hasSeenModal]);
  return <div style={{ display: "none" }}>&nbsp;</div>;
};

export default GexaAnnounce;

/**
 * The actual modal that displays the Gexa program announcement
 * This could probably live in another file but it will literally only
 * ever be used in one other place, hopefully not a circular dep!
 * It makes sense to keep it here for now, as they do need to share
 * interrelated styles.
 */
export const GexaModal = ({ closeModal }: { closeModal: () => void }) => {
  return (
    <div className={s.modalContentWrapper}>
      <img
        src="https://images.ctfassets.net/w0ps5rolaaeh/3Mi4HWqjCRk3kxf68Uw8gq/30a708fb09458c750aae1d45f391f3ee/gexa.png"
        alt="Gexa Energy Logo"
      />
      <h3>Pay ZERO energy charges on nighttime EV Charging!</h3>
      <p>
        As an EV owner, you&apos;ve elevated the way you drive. Now it&apos;s
        time to elevate the way you power your EV. This offer is eligible for
        all Tenet customers based in ERCOT zipcodes in Texas. Switch your power
        plan for zero energy charges on nighttime EV charging today!
      </p>
      <div className={s.modalButtonWrapper}>
        <Button
          variant={ButtonVariant.DARK_FILLED}
          type="button"
          hasRightArrow
          onClick={() => {
            document.location = "https://www.gexaenergy.com/tenet";
            closeModal();
          }}
          data-cy="gexa-link"
        >
          FIND OUT MORE
        </Button>
      </div>
    </div>
  );
};
