import { css } from "#themes";

export const button = css({
  borderRadius: 24,
  color: "$textInverse",
  display: "inline-flex",
  height: 48,
  paddingX: 24,
  paddingY: 12,
  textStyle: "body1",
  transition: "all 200ms ease, transform 100ms ease",

  "&:active": {
    transform: "scale3D(0.98, 0.99, 1)",
  },

  "&:disabled": {
    background: "$uiPrimary",
    color: "$textInverse",
  },

  variants: {
    size: {
      small: {
        height: 24,
        paddingX: 12,
        paddingY: 0,
        textStyle: "body2",
      },
      medium: {
        height: "auto",
        paddingX: 12,
        paddingY: 8,
        textStyle: "body1",
      },
    },
    variant: {
      gradient: {
        background: "$brandGradient",
        color: "$textPrimary",
      },
      inverted: {
        background: "$backgroundPrimary",
        color: "$textPrimary",

        "@media (hover:hover)": {
          "&:hover": {
            background: "$uiSubtle",
          },
        },
      },
      outline: {
        background: "#D9D9D964",
        boxShadow: "inset 0 0 0 1px currentColor",
        color: "$textPrimary",

        "@media (hover:hover)": {
          "&:hover": {
            color: "$uiSecondary",
          },
        },

        "&:disabled": {
          background: "none",
          color: "$uiPrimary",
        },
      },
      invertedOutline: {
        background: "transparent",
        boxShadow: "inset 0 0 0 1px $colors$textInverse",
        color: "$textInverse",

        "@media (hover:hover)": {
          "&:hover": {
            color: "$uiSubtle",
            boxShadow: "inset 0 0 0 1px $colors$uiSubtle",
          },
        },
      },
      outlineInverted: {
        background: "transparent",
        boxShadow: "inset 0 0 0 1px currentColor",
        color: "$textInverse",

        "&:disabled": {
          background: "none",
          color: "$uiPrimary",
        },
      },
      primary: {
        background: "$backgroundInverse",
        color: "$textInverse",

        "@media (hover:hover)": {
          "&:hover": {
            background: "$uiSecondary",
          },
        },
      },
      secondary: {
        background: "$backgroundPrimary",
        color: "$textPrimary",
        "@media (hover:hover)": {
          "&:hover": {
            background: "$uiPrimary",
          },
        },
      },
      tertiary: {
        background: "$uiTertiary",
        color: "$textPrimary",
        "@media (hover:hover)": {
          "&:hover": {
            background: "$uiTertiary",
          },
        },
      },
      teal: {
        background: "$linearData",
        "@media (hover:hover)": {
          "&:hover": {
            background: "#1C80A0",
          },
        },
      },
      warning: {
        background: "$statusNegative",
      },
      warningOutline: {
        background: "$backgroundPrimary",
        boxShadow: "inset 0 0 0 1px $colors$statusNegative",
        color: "$textPrimary",

        "@media (hover:hover)": {
          "&:hover": {
            background: "$statusNegative",
            color: "$textInverse",
          },
        },

        "&:disabled": {
          background: "none",
          boxShadow: "inset 0 0 0 1px currentColor",
          color: "$uiPrimary",
        },
      },
    },
    width: {
      flex: { flexGrow: 1 },
      percent: { width: "100%" },
    },
  },
});
