import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPGetAddonsBody, LOSAPIResponse } from "./client.types";

/**
 * Retrieves addons from an application
 * @param applicationId application to specify for addons
 * @returns ok/error tuple. Value of tuple is the HTTP response details in either case
 */

const getAddons: (applicationId: string) => LOSAPIResponse<HTTPGetAddonsBody> =
  async (applicationId: string) => {
    const response = await makeRequest<HTTPGetAddonsBody>(
      HTTPMethod.GET,
      restPaths.application(applicationId).addons.get,
      {},
      true,
    );

    if (!response.error) {
      return { response, error: null };
    }

    return { response: null, error: response };
  };

export default getAddons;
