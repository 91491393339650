import { nper, PaymentDueTime, rate } from "financial";
/**
 * Assumptions:
 *
 * ParseCentsFromCurrencyInputString will recieve
 * decimal and integer values.
 *
 * The maximum decimal value parseCents will recieve
 * is 2 decimal places (that's the limit on the inputs)
 */

export const parseCentsFromCurrencyInputString = (currency: string) => {
  if (currency === "0") return 0;
  const parsedCurrency = Number.parseInt(
    currency.replace(/,|\./g, "").replace("$", ""),
    10,
  );

  const hasNoCents = !currency.includes(".");
  if (hasNoCents) return parsedCurrency * 100;

  const singularDecimalInput =
    currency.length - (currency.indexOf(".") + 1) === 1 ? 1 : 0;
  if (singularDecimalInput) return parsedCurrency * 10;
  return parsedCurrency;
};

export const centsToDollarString = (
  cents: number,
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
) =>
  (cents / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits,
    maximumFractionDigits,
  });

export const numberToPercentString = (number: number) => {
  return (+number).toLocaleString("en-US", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

// Reference: https://github.com/tenethq/tenet/blob/staging/api/app/refi/refi_application_service.py#L680
// Example: https://financialjs.netlify.app/modules/_financial_.html#nper
export const getRemainingMonthsUnrounded = (
  apr?: number | null,
  monthlyPaymentCents?: number | null,
  remainingPrincipal?: number | null,
) => {
  if (!apr || !monthlyPaymentCents || !remainingPrincipal) return null;

  return nper(apr / 12, monthlyPaymentCents, -remainingPrincipal);
};

// Reference: https://github.com/metalabdesign/tenet-api/blob/dev/src/modules/userCreditReport/UserCreditReportService.ts#L217-L227
// Example: https://financialjs.netlify.app/modules/_financial_.html#rate
export const getAprDecimal = (
  termLengthMonths?: number | null,
  monthlyPaymentCents?: number | null,
  totalPrincipalCents?: number | null,
) => {
  if (!termLengthMonths || !monthlyPaymentCents || !totalPrincipalCents)
    return null;
  return parseFloat(
    (
      rate(
        termLengthMonths,
        monthlyPaymentCents,
        -totalPrincipalCents,
        0,
        PaymentDueTime.End,
        0.03, // This "guess" helps to reduce the number of iterations
      ) * 12
    ).toFixed(4),
  );
};

export const getEstimatedInterestCents = (
  monthlyPayment?: number | null,
  estimatedApr?: number | null,
  remainingPrincipal?: number | null,
) => {
  if (!monthlyPayment || !estimatedApr || !remainingPrincipal) return null;

  const remainingMonths =
    getRemainingMonthsUnrounded(
      estimatedApr,
      monthlyPayment,
      remainingPrincipal,
    ) || 0;

  const total = monthlyPayment * remainingMonths;

  return Math.round(total - remainingPrincipal);
};

export const numberToStringWithCommas = (number: number) =>
  number.toLocaleString("en-US");
