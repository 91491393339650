import isArray from "lodash/isArray";
import isString from "lodash/isString";

const getStringFromQuery = (queryItem: string | string[] | undefined) => {
  if (isString(queryItem)) {
    return queryItem;
  }
  if (isArray(queryItem) && queryItem.length) {
    return queryItem[0];
  }
  return undefined;
};

export default getStringFromQuery;
