/** ******************************************************************************
Cancel Autopay
 * deletes a recurring payment
 ******************************************************************************* */

import { HTTPMethod, HTTPResponse } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPCancelAutopay } from "./client.types";

const setupAutopay: (loanId: string) => Promise<{
  response: null | HTTPResponse<HTTPCancelAutopay>;
  error: null | HTTPResponse<HTTPCancelAutopay>;
}> = async (loanId) => {
  const response = await makeRequest<HTTPCancelAutopay>(
    HTTPMethod.PUT,
    restPaths.payments(loanId).cancelAutopay,
    {},
    true,
    "lms",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default setupAutopay;
