import { css } from "#themes";

export const root = css({
  alignItems: "stretch",
  display: "flex",
  position: "relative",
  zIndex: 0,
});

export const contentWrapper = css({
  alignItems: "center",
  display: "flex",
});

export const input = css({
  flexGrow: 1,

  "&:disabled": {
    opacity: 0.7,

    "& + div": {
      opacity: 0.7,
    },
  },

  compoundVariants: [
    {
      variant: "primary",
      error: true,
      css: {
        "& + div": {
          boxShadow: "inset 0 -2px 0 -1px $colors$statusNegative",
        },
      },
    },
    {
      variant: "secondary",
      error: true,
      css: {
        "& + div": {
          boxShadow: "inset 0 -2px 0 -1px $colors$statusNegative",
        },
      },
    },
  ],
  variants: {
    error: {
      true: {},
    },
    variant: {
      primary: {
        paddingX: 0,
        paddingY: 8,
        textStyle: "body1",

        "&::placeholder": {
          color: "$uiPrimary",
          opacity: 1,
        },

        "&:focus:not(:disabled)": {
          outline: "none",

          "& + div": {
            boxShadow: "inset 0 -2px 0 -1px $colors$uiSelected",
          },
        },
      },
      secondary: {
        paddingX: 0,
        paddingY: 16,
        textStyle: "heading2",

        "&::placeholder": {
          color: "$uiPrimary",
          opacity: 1,
        },

        "&:focus:not(:disabled)": {
          outline: "none",

          "& + div": {
            boxShadow: "inset 0 -2px 0 -1px $colors$uiSelected",
          },
        },
      },
    },
  },
});

export const background = css({
  transition: "$easeAll",
  zIndex: -1,

  variants: {
    variant: {
      primary: { boxShadow: "inset 0 -2px 0 -1px $colors$uiPrimary" },
      secondary: { boxShadow: "inset 0 -2px 0 -1px $colors$uiPrimary" },
    },
  },
});
