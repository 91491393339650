import { forwardRef } from "react";

import { text } from "#styles";
import { config } from "#themes";
import { TextStyles } from "#themes/theme.types";

import { style } from "./Text.styles";

type Props<T extends React.ElementType> = PolymorphicComponentPropsWithRef<
  T,
  {
    className?: string;
    color?: keyof typeof config.theme.colors | string;
    fontWeight?: "bold" | "regular";
    textAlign?: "center" | "end" | "justify" | "start" | "right" | "left";
    transform?: "capitalize" | "lowercase" | "uppercase";
    truncate?: boolean;
    variant?:
      | TextStyles
      | { "@initial": TextStyles; "@minTabletLarge": TextStyles };
  }
>;

type TextComponent = <T extends React.ElementType = "span">(
  props: Props<T>,
) => React.ReactElement | null;

/**
 * The Text component is used to present text content with specific,
 * design-defined typographic styles and scales.
 *
 * By default, it will render as an inline `<span>` element, though this can
 * be overridden by providing a valid elementType via the `as` prop.
 *
 * Text accepts a `variant` prop, which may be used as a shorthand for
 * rendering text with commonly used combined sets of styles (eg. font-sizes,
 * weights, families, text-transforms, etc) for different text scales (eg.
 * for various heading levels or body copy types).
 */
const Text: TextComponent = forwardRef(
  <T extends React.ElementType = "span">(
    {
      as,
      color,
      fontWeight,
      textAlign,
      transform,
      truncate,
      variant = "body1",
      ...otherProps
    }: Props<T>,
    ref?: PolymorphicRef<T>,
  ) => {
    const Component = as || "span";

    return (
      <Component
        css={[style, truncate ? text.truncate : undefined]}
        cssProps={{
          color,
          fontWeight,
          textAlign,
          transform,
          truncate,
          variant,
        }}
        ref={ref}
        {...otherProps}
      />
    );
  },
);

export default Text;
