import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPSoftCreditRes, LOSAPIResponse } from "./client.types";

/**
 * Gets an applicant's credit report information
 * @param applicationId loan application ID for the current application (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const getHardCredit: (
  applicationId: string,
) => LOSAPIResponse<HTTPSoftCreditRes> = async (applicationId) => {
  const response = await makeRequest<HTTPSoftCreditRes>(
    HTTPMethod.GET,
    `${restPaths.application(applicationId).credit}/hard`,
    {},
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getHardCredit;
