import CheckboxField from "#components/base/CheckboxField/CheckboxField";
import Text from "#components/base/Text/Text";
import { flex } from "#styles";

import { AccountRendererProps } from "./PaymentsAccountSelector.types";

export const AccountRenderer = ({
  onChange,
  checked,
  account,
}: AccountRendererProps) => (
  <>
    <CheckboxField
      id={account.id}
      name={account.name ?? account.id}
      onChange={(event) => onChange(event, account, checked)}
      checked={checked}
      aria-label="select"
    />

    <Text css={flex.flexGrow} style={{ marginLeft: "8px" }}>
      {account.name}
    </Text>

    {account.mask ? <Text>****{account.mask}</Text> : null}
  </>
);
