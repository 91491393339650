import NextLink from "next/link";
import { forwardRef } from "react";

import * as css from "./Link.styles";
import { Component as LinkComponent, Props } from "./Link.types";

/**
 * A link provides an interactive reference to a resource.
 *
 * This `Link` component renders a themed version of the next.js/reach-routers's
 * own `Link` component. If you need to renders a different HTML element using
 * this component's styles, you may pass an `as` prop value to determine the
 * rendered output, eg: `<Link as="a" href={someUrl}>`.
 *
 * All unhandled props are forwarded to the root element. This includes all
 * appropriate HTML attributes or aria tags.
 */
const Link: LinkComponent = forwardRef(
  <T extends React.ElementType = typeof NextLink>(
    {
      as,
      children,
      href,
      variant = "primary",
      "aria-label": ariaLabel,
      ...otherProps
    }: Props<T>,
    ref?: PolymorphicRef<T>,
  ) => {
    const Component = as || NextLink;

    if (Component !== NextLink) {
      return (
        <Component
          css={css.link}
          href={href || ""}
          cssProps={{ variant }}
          ref={ref}
          {...otherProps}
          aria-label={ariaLabel}
        >
          {children}
        </Component>
      );
    }

    return (
      <NextLink href={href || ""}>
        <a css={css.link} cssProps={{ variant }} ref={ref} {...otherProps}>
          {children}
        </a>
      </NextLink>
    );
  },
);

export default Link;
