/** ******************************************************************************
Start Session
 * uses the /start-session endpoint to begin plaid income verification
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPIvsRes, LOSAPIResponse } from "./client.types";

/**
 * Begins plaid income verification
 * @param plaidPublicToken public plaid token for income verification (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const startSession: (plaidPublicToken: string) => LOSAPIResponse<HTTPIvsRes> =
  async (plaidPublicToken) => {
    const response = await makeRequest<HTTPIvsRes>(
      HTTPMethod.POST,
      restPaths.incomeVerification.startSession,
      { body: { plaidPublicToken } },
      true,
    );
    if (!response.error) {
      return { response, error: null };
    }
    return { response: null, error: response };
  };

export default startSession;
