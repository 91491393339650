/** ******************************************************************************
Shared HTTP Structures
 * You know what these are.
 ******************************************************************************* */

// Common http types

export interface HTTPResponse<ResType> {
  error: boolean;
  statusText: string;
  status?: number;
  ok?: boolean;
  body?: ResType;
  redirected?: boolean;
  type?: string;
  url?: string;
}

export enum HTTPMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export interface HTTPOptions {
  // Temporarily disabled. I will create a type that can be passed in based on expected Payload = Jordon
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body?: any;
  headers?: Record<string, string>[];
}

export interface HTTPTokenRes {
  data: {
    token: string;
  };
}

export enum PaymentSetting {
  AmountDue = "AmountDue", // the total outstanding balance
  MonthlyPaymentAmount = "MonthlyPaymentAmount", // the monthly payment amount
  Override = "Override", // an override amount that must be greater than the monthly payment amount
}

export enum PaymentDateSetting {
  DueDate = "DueDate", // Make payments on the DueDate
  Override = "Override", // this is ALWAYS the First, unless it has been overridden. DO NOT USE THIS UNLESS PAYMENTSETTING === Override. -MT
}

export enum PaymentMethodVerificationStatus {
  Pending = "Pending",
  Verified = "Verified",
  Failed = "Failed",
}

export type PaymentMethod = {
  id: string;
  accountName: string;
  accountNumberLast4Digits: string;
  userId: string;
  createdAt: string;
  nickname: string;
  paymentVerification?: {
    accessToken: string;
    status: PaymentMethodVerificationStatus;
  };
};

// Don't change order of this enum:
export enum PaymentDayOfMonth {
  First = "First",
  Second = "Second",
  Third = "Third",
  Fourth = "Fourth",
  Fifth = "Fifth",
  Sixth = "Sixth",
  Seventh = "Seventh",
  Eighth = "Eighth",
  Ninth = "Ninth",
  Tenth = "Tenth",
  Eleventh = "Eleventh",
  Twelfth = "Twelfth",
  Thirteenth = "Thirteenth",
  Fourteenth = "Fourteenth",
  Fifteenth = "Fifteenth",
  Sixteenth = "Sixteenth",
  Seventeenth = "Seventeenth",
  Eighteenth = "Eighteenth",
  Nineteenth = "Nineteenth",
  Twentieth = "Twentieth",
  TwentyFirst = "TwentyFirst",
  TwentySecond = "TwentySecond",
  TwentyThird = "TwentyThird",
  TwentyFourth = "TwentyFourth",
  TwentyFifth = "TwentyFifth",
  // We only support payment dates through the 25th to allow 3 days for payment resolution before EOM.
}
