import { ApplyConfig } from "#components/provider/ProvidersOrchestration/Apply";

import { Page } from "./pageNavigationHelpers.page";

export const NEW_APPLICATION_URL = "/apply/";
export const NEW_STANDARD_INDIVIDUAL_REFINANCE_APPLICATION_URL = `/refinance/intent/?applyConfig=${ApplyConfig.STANDARD_NEW_LOAN}`;
export const NEW_STANDARD_INDIVIDUAL_PURCHASE_APPLICATION_URL = `/new-loan/intent/?applyConfig=${ApplyConfig.STANDARD_NEW_LOAN}`;
export const NEW_REFINANCE_APPLICATION_URL =
  "/apply/?loanClass=Individual&loanType=Refinance";
export const NEW_PURCHASE_APPLICATION_URL =
  "/apply/?loanClass=Individual&loanType=Purchase";

/* MPR, 2022/11/10: This const can be removed once all pages provide their own
 * navigation functions */
// Once the user passes certain steps in the wizard they
// should no longer be allowed to navigate back.
export const DIRECT_STEPS_WITH_BACK_DISABLED = [
  Page.REFI_VEHICLE_INFO_FORM,
  Page.EMAIL_VERIFICATION,
  Page.PERSONAL_INFO_FORM,
  Page.INTENT,
  Page.PASSTHROUGH_OFFERS,
  /** I commented this out because going backwards all the way
   * to the vin screen doesn't cause any issuses - Jordon 12/21/22 */
  // Page.CONFIRMATION_FORM,
];
