/** ******************************************************************************
Cancel Autopay
 * Deletes the autopay configuration for applications
 ******************************************************************************* */

import { HTTPMethod, HTTPResponse } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPCancelAutopay } from "./client.types";

const cancelAutopay: (applicationId: string) => Promise<{
  response: null | HTTPResponse<HTTPCancelAutopay>;
  error: null | HTTPResponse<HTTPCancelAutopay>;
}> = async (applicationId) => {
  const response = await makeRequest<HTTPCancelAutopay>(
    HTTPMethod.PUT,
    restPaths.application(applicationId).payments.cancelAutopay,
    {},
    true,
    "los",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default cancelAutopay;
