/** ******************************************************************************
Edit Payment Method
 * Edits a Payment Method
 ******************************************************************************* */

import { HTTPMethod, HTTPResponse } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPEditPaymentMethodResponse } from "./client.types";

const editPaymentMethod: (
  paymentMethodId: string,
  nickname: string,
) => Promise<{
  response: null | HTTPResponse<HTTPEditPaymentMethodResponse>;
  error: null | HTTPResponse<HTTPEditPaymentMethodResponse>;
}> = async (paymentMethodId, nickname: string) => {
  const response = await makeRequest<HTTPEditPaymentMethodResponse>(
    HTTPMethod.PUT,
    restPaths.paymentMethods(paymentMethodId).editPaymentMethod,
    {
      body: {
        nickname,
      },
    },
    true,
    "lms",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default editPaymentMethod;
