import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { UpdateTermsParams } from "#components/provider/ApplicationProvider/ApplicationTypes";
import { restPaths } from "#constants/restPaths";

import { HTTPLoanApplicationResponse, LOSAPIResponse } from "./client.types";

const updateTerms: ({
  applicationId,
  principalAmountCents,
  termLengthMonths,
  deferredPaymentCents,
}: UpdateTermsParams) => LOSAPIResponse<HTTPLoanApplicationResponse> = async ({
  applicationId,
  principalAmountCents,
  termLengthMonths,
  deferredPaymentCents,
}: UpdateTermsParams) => {
  const response = await makeRequest<HTTPLoanApplicationResponse>(
    HTTPMethod.PUT,
    restPaths.application(applicationId).updateTerms.put,
    {
      body: { termLengthMonths, deferredPaymentCents, principalAmountCents },
    },
    true,
  );

  if (!response.error) {
    return { response, error: null };
  }

  return { response: null, error: response };
};

export default updateTerms;
