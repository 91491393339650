import { useEffect, useState } from "react";
import { PlaidLinkOnSuccess, usePlaidLink } from "react-plaid-link";

import LMSAPIClient from "#api.lms/client";

const usePlaidIntegration = ({
  onSuccess,
  accessToken,
}: {
  onSuccess: PlaidLinkOnSuccess;
  accessToken?: string;
}) => {
  const [loading, setLoading] = useState(false);
  const [privateToken, setPrivateToken] = useState<string | undefined>(
    undefined,
  );

  // Gets and sets the private token
  const setPaymentAuthToken = async () => {
    setLoading(true);
    const { response } = await LMSAPIClient.payments.getPaymentAuthToken(
      accessToken,
    );
    setPrivateToken(response?.body?.data.token);
    setLoading(false);
  };

  // Runs the function above upon initialization
  useEffect(() => {
    setPaymentAuthToken();
  }, []);

  // Generates the open function for the plaid flow
  const { open, ready } = usePlaidLink({
    onSuccess,
    token: privateToken || "",
  });

  // opens plaid flow
  const openPlaidFlow = () => {
    if (privateToken && ready) {
      open();
    }
  };

  // Returns wether or not this is loading and a function to open plaid flow
  return { openPlaidFlow, loading };
};

export default usePlaidIntegration;
