/** ******************************************************************************
Get Loan Details
 * retrieves the loan high-level information (amount paid, remaining balance, etc)
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import {
  HTTPDeprecatedLoanDetailsResponse,
  LMSAPIResponse,
} from "./client.types";

const getDetails: (
  loanId: string,
) => LMSAPIResponse<HTTPDeprecatedLoanDetailsResponse> = async (loanId) => {
  const response = await makeRequest<HTTPDeprecatedLoanDetailsResponse>(
    HTTPMethod.GET,
    restPaths.loan(loanId).getDetails,
    {},
    true,
    "lms",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getDetails;
