import { css } from "#themes";

export const dialog = css({ zIndex: 1000 });

export const modalBody = css({
  overflowY: "auto",
  paddingX: "$expanded",
  width: "100%",

  "@minTabletSmall": {
    paddingX: 40,
  },

  "@minTabletLarge": {
    paddingX: "$40",
  },

  variants: {
    variant: {
      fullScreen: {
        maxWidth: "$maxWidth",
        margin: "0 auto",
        flexGrow: 1,

        "@minTabletLarge": {
          paddingX: 40,
        },
      },
    },
  },
});

export const closeButtonDesktop = css({
  display: "none",

  "@minTabletLarge": {
    alignSelf: "flex-end",
    display: "flex",
    paddingX: 20,
    paddingY: 16,
  },

  variants: {
    variant: {
      primary: {
        "@minTabletLarge": {
          display: "flex",
          padding: "$16",
          position: "absolute",
          right: "$16",
          top: "$16",
          zIndex: 1000,
        },
      },
      fullScreenMobile: {
        "@minTabletLarge": {
          display: "flex",
          padding: "$16",
          position: "absolute",
          right: "$16",
          top: "$16",
          zIndex: 1000,
        },
      },
      fullScreen: {
        "@minTabletSmall": {
          right: 40,
          paddingRight: 0,
          paddingTop: 26,
        },

        "@minTabletLarge": {
          right: 60,
          position: "absolute",
          top: 0,
        },
      },
    },
  },
});

export const closeButtonMobile = css({
  "@minTabletLarge": {
    display: "none",
  },

  variants: {
    variant: {
      primary: {
        color: "transparent",
        position: "relative",
        width: "100%",
        zIndex: 1000,

        "&:after": {
          background: "$backgroundInverse",
          content: "''",
          height: 4,
          width: 40,
          position: "absoluteCenter",
        },
      },
      fullScreen: {
        color: "$textPrimary",
        right: 0,
        paddingX: 20,
        paddingY: 16,
        position: "fixed",
        top: 0,

        "@minTabletSmall": {
          right: 40,
          paddingRight: 0,
          paddingTop: 26,
        },
      },
      fullScreenMobile: {
        alignSelf: "flex-end",
        color: "$textPrimary",
        paddingX: "$20",
        paddingY: "$16",

        "@minTabletSmall": {
          paddingTop: 26,
          paddingRight: 40,
        },
      },
    },
  },
});

export const headerContainer = css({
  display: "flex",
  paddingX: "$expanded",
  position: "relative",
  width: "100%",

  "@minTabletSmall": {
    paddingX: 40,
  },

  "@minTabletLarge": {
    paddingTop: "$40",
    paddingX: "$80",
  },

  variants: {
    variant: {
      fullScreen: {
        width: "1110px",
        margin: "0 auto",

        "@minTabletLarge": {
          paddingX: 60,
        },
      },
    },
  },
});

export const footerContainer = css({
  paddingX: "$expanded",
  paddingBottom: "$expanded",
  width: "100%",
  background: "#FFFFFF",

  "@minTabletSmall": {
    paddingX: 40,
  },

  "@minTabletLarge": {
    paddingX: "$80",
  },

  variants: {
    variant: {
      fullScreen: {
        maxWidth: "$maxWidth",
        margin: "0 auto",

        "@minTabletLarge": {
          paddingX: 60,
        },
      },
    },
  },
});

export const modalContainer = css({
  variants: {
    variant: {
      primary: {
        backgroundColor: "$backgroundPrimary",
        borderRadius: "10px 10px 0 0",
        bottom: 0,
        gap: "$24",
        maxHeight: "80vh",
        overflow: "hidden",
        position: "absolute",
        textStyle: "body1",
        width: "100%",

        "@minTabletLarge": {
          bottom: "unset",
          maxHeight: "calc(100vh - 80px)",
          maxWidth: 1200,
          borderRadius: "8px",
        },
      },
      fullScreen: {
        backgroundColor: "$backgroundPrimary",
        gap: "$24",
        overflow: "hidden",
        position: "absoluteFill",
        textStyle: "body1",
      },
      fullScreenMobile: {
        backgroundColor: "$backgroundPrimary",
        gap: "$24",
        overflow: "hidden",
        position: "absoluteFill",
        textStyle: "body1",

        "@minTabletLarge": {
          height: "auto",
          inset: "unset",
          maxHeight: "calc(100vh - 80px)",
          maxWidth: 1200,
          position: "absolute",
          width: "auto",
        },
      },
    },
  },
});

export const overlay = css({ backgroundColor: "$uiOverlay" });
