import ArrowLeftIcon from "@heroicons/react/24/solid/ArrowLeftIcon";
import ArrowRightIcon from "@heroicons/react/24/solid/ArrowRightIcon";
import ChevronLeftIcon from "@heroicons/react/24/solid/ChevronLeftIcon";
import ChevronRightIcon from "@heroicons/react/24/solid/ChevronRightIcon";
import classNames from "classnames";
import snakeCase from "lodash/snakeCase";
import toUpper from "lodash/toUpper";

import s from "./Button.module.scss";
import { ButtonVariant, TenetButtonProps } from "./Button.types";

export const ModuleName = "Button";

/**
 * Base Button Component (NOT A LINK)
 * @param {string} label A string to label the button.
 * @param {ButtonVariant} variant A enum property that dictates the styling of the button. Passing a className will override the variant.
 * @param {ButtonSize} forceSize A enum property that forces the button to be a particular size despite screen breakpoints.
 * Passing a className will override the forceSize
 * @param {"button"|"submit"|"reset"} type Defaults to "button". Pass a different type to override.
 * @returns A custom button component.
 */
const Button = ({
  label,
  variant = ButtonVariant.DARK_OUTLINE,
  hasLeftArrow = false,
  hasRightArrow = false,
  forceSize,
  children,
  icon,
  className,
  ...otherProps
}: TenetButtonProps) => {
  const buttonClass = classNames(
    s.buttonWrapper,
    s[variant],
    { [s[forceSize || ""]]: forceSize != null },
    className,
  );
  return (
    <button
      className={buttonClass}
      type="button"
      {...otherProps}
      data-cy={`${otherProps["data-cy"]}-button`}
    >
      <span>
        {hasLeftArrow && <ChevronLeftIcon className={s.chevronLeft} />}
        {hasLeftArrow && <ArrowLeftIcon className={s.leftArrow} />}
        {icon}
        {label && <sub>{label}</sub>}
        {children}
        {hasRightArrow && <ArrowRightIcon className={s.rightArrow} />}
        {hasRightArrow && <ChevronRightIcon className={s.chevronRight} />}
      </span>
    </button>
  );
};

export const toButtonVariant = (
  variant?: string,
): ButtonVariant | undefined => {
  if (!variant) return undefined;
  return ButtonVariant[toUpper(snakeCase(variant))] as ButtonVariant;
};

export default Button;
