import type { FirebaseApp } from "firebase/app";
import {
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth as getAuthFromFirebase,
  indexedDBLocalPersistence,
  initializeAuth,
} from "firebase/auth";

let initialized = false;
let initializedApp: FirebaseApp;

/**
 * Here we override firebase's native getAuth. The native getAuth function has a `browserPopupRedirectResolver` dependency, which causes
 * a large iframe to load in on the initial fetch on mobile devices. This is a huge performance sink for mobile.
 *
 * Here we explicitly list dependencies on initialization.
 *
 * Assumptions:
 * 1. we don't initialize more than one firebase app
 * 2. we dont need `signInWithPopup` or `signInWithRedirect`
 *
 * If we want to use either of these at some point, instead of reintroducing the mobile iframe, pass in the dependency `browserPopupRedirectResolver`
 * when you call the function.
 *
 * ex. const result = await signInWithPopup(auth, new GoogleAuthProvider(), browserPopupRedirectResolver);
 */
export const getAuth = async (app?: FirebaseApp) => {
  /* MPR, 2023/11/23: What are we doing here? We need an actual
   * global to persist across independent dynamic loads of this module.
   * It's a little ugly with the ts-ignores, but fortunately
   * we don't, and shouldn't, need to do this very often. */
  if (typeof window !== "undefined") {
    const currentApp = await (async () => {
      if (app) return Promise.resolve(app);
      const App = await import("firebase/app");

      return App.getApp();
    })();

    /* eslint-disable */
    /* @ts-ignore */
    window._tenet = window._tenet || {};
    /* eslint-disable */
    /* @ts-ignore */
    if (!window._tenet.initialized) {
      /* eslint-disable */
      /* @ts-ignore */
      window._tenet.initialized = true;

      return initializeAuth(currentApp, {
        persistence: [
          indexedDBLocalPersistence,
          browserLocalPersistence,
          browserSessionPersistence,
        ],
      });
    }
    /* eslint-disable */
    /* @ts-ignore */
    return getAuthFromFirebase(currentApp);
  }

  // ***** MPR, 2023/11/23: The code below is pre-dynamic logic. We leave it
  // for typing and nextjs reasons. It should never run under normal circumstances
  // and should be left alone.
  console.warn("Non browser code reached in a browser context");
  if (!initialized) {
    initialized = true;

    const currentApp = await (async () => {
      if (app) return Promise.resolve(app);
      const App = await import("firebase/app");
      return App.getApp();
    })();

    initializedApp = currentApp;

    return initializeAuth(currentApp, {
      persistence: [
        indexedDBLocalPersistence,
        browserLocalPersistence,
        browserSessionPersistence,
      ],
    });
  }

  return getAuthFromFirebase(initializedApp);
};
