/** ******************************************************************************
Get Vehicle Basic
 * gets non-pii make model year etc info about a vehicle
 * this exists solely for the refi vehicle screen, which needs
 * this data but is not authenticated yet
 * As such, this is an unauthenticated route
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { LOSAPIResponse } from "./client.types";
import { VehiclePartialOutputDto } from "./losDto.types";

const getVehicleBasic: (
  applicationId: string,
) => LOSAPIResponse<VehiclePartialOutputDto | null> = async (applicationId) => {
  const response = await makeRequest<VehiclePartialOutputDto | null>(
    HTTPMethod.GET,
    restPaths.vehicle(applicationId).getBasic,
    {},
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getVehicleBasic;
