import LMSAPIClient from "#api.lms/client";
import { HTTPGetPaymentMethodsResponse } from "#api.lms/client.types";

import CreateRESTContextProvider from "./GenericRESTProvider";

interface Props {
  children: React.ReactNode;
}

const { RESTProvider, useREST } =
  CreateRESTContextProvider<HTTPGetPaymentMethodsResponse>();

export const PaymentMethodProvider = ({ children }: Props) => {
  return (
    <RESTProvider
      storageKey="paymentMethodProvider"
      getData={async () => {
        const { response, error } =
          await LMSAPIClient.payments.getPaymentMethods();
        const invalidReason = error?.statusText;
        const data = response?.body;
        if (error) {
          console.error("get pm error", error);
          return { error: invalidReason };
        }
        return { response: data };
      }}
    >
      {children}
    </RESTProvider>
  );
};

export const usePaymentMethodProvider = () => {
  return useREST();
};
