import { css } from "#themes";

export const link = css({
  variants: {
    variant: {
      primary: {
        color: "$textPrimary",
        textDecoration: "underline",

        "&:visited": {
          color: "$textPrimary",
          textDecoration: "underline",
        },
      },
      inherit: {
        color: "$inherit",
        fontSize: "inherit",
        lineHeight: "inherit",
        textDecoration: "inherit",
      },
    },
  },
});
