import { useState } from "react";
import { useStorageState } from "react-use-storage-state";

export function useBigState<T extends { [x: string]: unknown }>(
  initialState: T = {} as T,
): [T, (arg: Partial<T>) => void] {
  const [state, proto] = useState(initialState);

  const setState = (obj: Partial<T>) =>
    proto((prevState) => ({ ...prevState, ...obj }));

  return [state, setState];
}

export function useBigSessionState<T extends { [x: string]: unknown }>(
  key: string,
  initialState: T = {} as T,
): [T, (arg: Partial<T>) => void] {
  const [state, proto] = useStorageState(key, initialState, sessionStorage);

  const setState = (obj: Partial<T>) =>
    proto((prevState) => ({ ...prevState, ...obj }));

  return [state, setState];
}
