/**
 * Removed typing to allow typescript to infer.
 * The base object should always be a string type, as this is a url builder.
 */
export const restPaths = {
  init: "init",
  usStates: "states",
  user: {
    create: "user",
    get: "user",
    init: "user/initUser",
    updateAddressDetails: (userAddressId: string) =>
      `user/address/${userAddressId}`,
    createAddress: "user/address",
    updateDriversLicense: "user/drivers-license",
    updatePhone: "user/pr",
    updateNameAndDob: "user/dfl",
    updateAttribution: "user/attribution",
    updateSsn: "user/ssn",
    updateEmail: "user/update-email",
    verifyEmail: "user/verify-email",
    verify: "user/verify",
    resend: "user/resend",
    getUserEmailVerificationStatus: "user/email-verification-status",
    getUserPhoneVerificationStatus: "user/phone-verification-status",
    updateReferrer: "user/referral",
    experiment: {
      add: "experiment",
      track: "experiment/event",
    },
  },
  incomeVerification: {
    getPaymentAuthToken: "user/income-verification/auth-token",
    importPlaidAccounts: "user/income-verification/plaid-accounts",
    getToken: "user/income-verification/token",
    startSession: "user/income-verification/start-session",
    endSession: "user/income-verification/end-session",
  },
  offer: (applicationId?: string) => ({
    build: `applications/${applicationId}/offers/purchase/build`,
    select: `applications/${applicationId}/offers/purchase/select`,
  }),
  tracking: {
    post: "tracking",
    trackOfferConfirmed: "tracking/offer-confirmed",
    subscribeEmail: "tracking/email-subscription",
  },
  applications: {
    get: "applications",
  },
  application: (applicationId?: string, autopayConfigId?: string) => ({
    get: `applications/${applicationId}`,
    create: "applications",
    draft: "drafts",
    unassociatedVehicle: "drafts/vehicle",
    updateTerms: { put: `applications/${applicationId}/terms` },
    cancelApplication: {
      put: `applications/${applicationId}`,
    },
    addons: {
      get: `applications/${applicationId}/addons`,
    },
    warranty: {
      post: `applications/${applicationId}/addons/warranty`,
    },
    GAP: {
      post: `applications/${applicationId}/addons/gap`,
      prelimPost: `applications/${applicationId}/addons/prelim-gap`,
    },
    chargerInstall: {
      createEstimate: `applications/${applicationId}/addons/charger-estimate`,
      postChargerInstall: `applications/${applicationId}/addons/charger-install`,
      getChargerEligibility: `applications/${applicationId}/addons/charger-eligibility`,
    },
    charger: {
      postCharger: `applications/${applicationId}/addons/charger`,
      postChargerPricing: `applications/${applicationId}/addons/charger-pricing`,
    },
    dealershipInfo: {
      create: `applications/${applicationId}/dealershipInfo/create`,
      get: `applications/${applicationId}/dealershipInfo`,
      update: `applications/${applicationId}/dealershipInfo`,
    },
    offers: {
      createDirect: `applications/${applicationId}/offers/direct-loan-offer`,
      confirm: (offerId: string) =>
        `applications/${applicationId}/offers/${offerId}`,
      select: (offerId: string) =>
        `applications/${applicationId}/offers/${offerId}`,
      get: `applications/${applicationId}/offers`,
      getSelectedOffer: `applications/${applicationId}/offers/selected`,
      prequal: `applications/${applicationId}/offers/prequal`,
      emailOffer: `applications/${applicationId}/offers/email`,
      getLimits: (amountCents?: number) =>
        `applications/${applicationId}/offers/limits?${
          amountCents ? `amountCents=${amountCents}` : ""
        }`,
    },
    payments: {
      createAutopay: `applications/${applicationId}/payments/autopay`,
      postAutopayPreference: `applications/${applicationId}/payments/autopay-preference`,
      getAutopay: `applications/${applicationId}/payments/autopay`,
      updateAutopay: `applications/${applicationId}/payments/autopay/${autopayConfigId}`,
      cancelAutopay: `applications/${applicationId}/payments/autopay/cancel`,
    },
    credit: `applications/${applicationId}/creditReport`,
    docusign: `applications/${applicationId}/applicant/docusign`,
    applicant: `applications/${applicationId}/applicant`,
    tasks: {
      get: `applications/${applicationId}/tasks`,
    },
    vehicle: {
      post: `applications/${applicationId}/vehicle`,
      upsert: `applications/${applicationId}/vehicle/upsert`,
    },
    waitlist: "applications/waitlist",
    updateApplicantNameAndDOB: (applicantId?: string) =>
      `applications/${applicationId}/applicant/${applicantId}/dfl`,
    verifyIdentity: (applicantId: string) =>
      `applications/${applicationId}/applicant/${applicantId}/identity-verification`,
  }),
  business: (applicationId?: string) => ({
    get: "business",
    getInfo: `business/${applicationId}/info`,
    getOwnership: `business/${applicationId}/ownership`,
    upsertInfo: `business/${applicationId}/info`,
    upsertOwnership: `business/${applicationId}/ownership`,
  }),
  loans: {
    get: "loans",
  },
  loan: (loanId: string) => ({
    getDetails: `loans/${loanId}/details`,
    get: `loans/${loanId}`,
  }),
  vehicle: (applicationId: string) => ({
    get: `applications/${applicationId}/vehicle`,
    getBasic: `applications/${applicationId}/vehicle/basic`,
    compareVins: `applications/${applicationId}/vehicle/compare-vin`,
  }),
  payments: (loanId: string) => ({
    get: `loans/${loanId}/payments/payments`,
    getPaymentMethods: `loans/${loanId}/payments/payment-methods`,
    oneTimePayment: `loans/${loanId}/payments/payment`,
    setupAutopay: `loans/${loanId}/payments/autopay`,
    updateAutopay: `loans/${loanId}/payments/autopay`,
    cancelAutopay: `loans/${loanId}/payments/autopay/cancel`,
  }),
  payoff: (loanId: string) => ({
    getDocument: `loans/${loanId}/payoff/document`,
    initDocument: `loans/${loanId}/payoff/init-document`,
  }),
  paymentMethods: (paymentMethodId?: string) => ({
    verifyPaymentMethod: `payment-methods/verify`,
    createPaymentMethods: `payment-methods/create`,
    getPaymentMethods: `payment-methods`,
    deletePaymentMethod: `payment-methods/${paymentMethodId}`,
    editPaymentMethod: `payment-methods/${paymentMethodId}`,
  }),
  document: (applicationId: string, documentId?: string) => ({
    getUploadLink: `applications/${applicationId}/documents/upload-link`,
    getDocuments: `applications/${applicationId}/documents/`,
    updateUploaded: `applications/${applicationId}/documents/${documentId}/set-uploaded`,
    getOptionalUploadLink: `applications/${applicationId}/documents/optional-upload-link`,
    createTaskDocUploadLink: `applications/${applicationId}/documents/upload-url`,
  }),
  states: {
    get: `states`,
    waitList: `states/wait-list`,
  },
  task: (appId: string) => ({
    setTaskAsPending: `applications/${appId}/tasks/set-task-as-pending`,
    setTasksToReview: `applications/${appId}/tasks/set-tasks-to-review`,
    deprecatedDocusign: `applications/${appId}/tasks/complete-docusign`,
  }),
  models: {
    getAllByMake: "models",
    getAllVehicleMakes: "models/makes",
    search: "models/search",
    isFinanceSupported: "models/isFinanceSupported",
  },
  smartCar: {
    sendToken: "tokens/link",
  },
  insights: {
    linkUser: "tokens/link",
    getInsightsForVehicle: (vin: string) => `cars/${vin}/insights`,
    getVehicleOverview: (vin: string) => `cars/${vin}/overview`,
    getProfile: (vin?: string) => `profile/${vin || ""}`,
    getSessionHistory: (vin: string) => `smart-charging/${vin}/session-history`,
    getVehicles: "cars",
    createSnapshot: "cars/fetch",
    chargingLocationVerification: "enable/address",
    updateChargingLocation: "enable/charging-location",
    unlinkUser: (vin: string) => `profile/${vin}/unlink/`,
    smartCharging: {
      setPolicy: "smart-charging/policy",
      setTariff: (masterTariffId: string, chargingLocationId?: string) =>
        `smart-charging/tariff/${masterTariffId}/${chargingLocationId || ""}`,
      setSchedule: (vin: string) => `smart-charging/${vin}/schedule`,
    },
    tariffs: {
      getLSEs: (zipCode: string) => `tariffs/lses/${zipCode}`,
      getTariffs: (zipCode: string, lseId: string) =>
        `tariffs/${lseId}/${zipCode}`,
    },
    tracking: {
      post: "tracking",
    },
  },
  claims: {
    verify: "verify",
    claim: "claim",
  },
  boardingClaims: {
    refreshTerms: "sign-terms",
    verify: "verify",
    claim: "claim",
    terms: "terms",
  },
  referrals: {
    get: "referrals",
  },
  /*
  claims: (claimId: string, offerId?: string) => ({
    verify: `${claimId}/verify`,
    claim: `${claimId}/claim/?o=${offerId}`,
  }),
  */
  budget: {
    track: "budget",
  },
};

export const losRestPaths = {
  calculator: {
    get: "calculator",
  },
};
