/** ******************************************************************************
Import Plaid Accounts
 * uses the plaid client to import account information
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPPlaidAccountsResponse, LMSAPIResponse } from "./client.types";

/**
 * Imports account information via the plaid client
 * @param accountIds an array of account Ids
 * @param publicToken a plaid public token
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const importPlaidAccounts: (
  accountIds: string[],
  publicToken: string,
) => LMSAPIResponse<HTTPPlaidAccountsResponse> = async (
  accountIds,
  publicToken,
) => {
  const response = await makeRequest<HTTPPlaidAccountsResponse>(
    HTTPMethod.PUT,
    restPaths.incomeVerification.importPlaidAccounts,
    {
      body: { accountIds, publicToken },
    },
    true,
    "lms",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default importPlaidAccounts;
