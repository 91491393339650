const fontSizes = {
  0: "0px",
  12: "12px",
  14: "14px",
  16: "16px",
  20: "20px",
  28: "28px",
  34: "34px",
  40: "40px",
  48: "48px",
  60: "60px",
  100: "100px",
  120: "120px",
  160: "160px",

  // Named aliases for font sizes.
  body1: "$16",
  body2: "$14",
  body3: "$20",
  body4: "$28",
  body5: "$34",
  body6: "$12",
  heading1: "$48",
  heading2: "$40",
  heading3: "$28",
  heading4: "$60",
  label: "$12",
  title1: "$100",
  title2: "$120",
};

export default fontSizes;
