import LOSClient from "#api.los/client";
import {
  ChargerDecisionInputDto,
  ChargerInstallDecisionInputDto,
  ChargerPricingInputDto,
} from "#api.los/client.types";
import { CreateChargerEstimateParamsDTO } from "#api.los/losDto.types";
import {
  GAPInsuranceDecisionInputDto,
  WarrantyPlanDecisionInputDto,
} from "#components/provider/AddonProvider/AddonTypes";

const Network = {
  getAddons: (applicationId: string) => {
    return LOSClient.application.getAddons(applicationId);
  },
  createChargerPricing: (
    applicationId: string,
    payload: ChargerPricingInputDto,
  ) => {
    return LOSClient.application.charger.createChargerPricing(
      applicationId,
      payload,
    );
  },
  createChargerEstimate: (
    applicationId: string,
    payload: CreateChargerEstimateParamsDTO,
  ) => {
    return LOSClient.application.chargerInstall.createEstimate(
      applicationId,
      payload,
    );
  },
  selectGapAddon: (
    applicationId: string,
    payload: GAPInsuranceDecisionInputDto,
  ) => {
    return LOSClient.application.postGAP(applicationId, payload);
  },
  selectChargerInstallAddon: (
    applicationId: string,
    payload: ChargerInstallDecisionInputDto,
  ) => {
    return LOSClient.application.chargerInstall.postChargerInstall(
      applicationId,
      payload,
    );
  },
  selectChargerAddon: (
    applicationId: string,
    payload: ChargerDecisionInputDto,
  ) => {
    return LOSClient.application.charger.postCharger(applicationId, payload);
  },
  selectEvppAddon: (
    applicationId: string,
    payload: WarrantyPlanDecisionInputDto,
  ) => {
    return LOSClient.application.postWarranty(applicationId, payload);
  },
  setVehicleVin: (applicationId: string, vin: string) => {
    return LOSClient.application.vehicle.update(applicationId, {
      vin,
    });
  },
};

export { Network };
