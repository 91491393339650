import { useCallback, useMemo, useState } from "react";
import type { PlaidAccount } from "react-plaid-link";

import LMSAPIClient from "#api.lms/client";
import Button from "#components/base/Button/Button";
import Text from "#components/base/Text/Text";
import { flex, layout } from "#styles";
import { alert } from "#util/alerts/alerts";

import PaymentsAccountSelector from "../../PaymentsAccountSelector/PaymentsAccountSelector";

const AccountSelectionPage = ({
  onCancel,
  publicToken,
  accounts,
  onSuccess,
}: {
  onCancel: () => void;
  publicToken: string;
  accounts: PlaidAccount[];
  onSuccess: () => void;
}) => {
  const [IPALoading, setLoading] = useState(false);

  const [selectedAccounts, updateSelectedAccounts] = useState<PlaidAccount[]>(
    [],
  );

  const onImportAccounts = useCallback(async () => {
    setLoading(true);
    try {
      await LMSAPIClient.payments.createPaymentMethods(
        selectedAccounts,
        publicToken,
      );
      onSuccess();
    } catch (e) {
      alert("Error: We were unable to update your payment method.");
    }
    setLoading(false);
  }, [publicToken, selectedAccounts, onSuccess]);

  const normalizedAccounts = useMemo(
    () =>
      accounts?.map((account) => ({
        ...account,
        name: account.name ?? account.type,
      })),
    [accounts],
  );

  return (
    <div css={[flex.flexStartVertical, layout.gap(20), layout.paddingX(46)]}>
      <Text as="p" variant="heading2" style={{ marginBottom: "40px" }}>
        Payment method
      </Text>

      <PaymentsAccountSelector
        accounts={normalizedAccounts}
        onChange={(newSelectedAccounts) =>
          updateSelectedAccounts(newSelectedAccounts)
        }
      />

      <Button
        disabled={selectedAccounts.length === 0 || IPALoading}
        onClick={onImportAccounts}
        aria-label={`Import ${selectedAccounts.length} Accounts`}
      >
        {`Import ${selectedAccounts.length} Accounts`}
      </Button>

      <Button
        variant="outline"
        onClick={onCancel}
        disabled={IPALoading}
        aria-label="Cancel"
      >
        Cancel
      </Button>
    </div>
  );
};

export default AccountSelectionPage;
