import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPGetBusinessRes, LOSAPIResponse } from "./client.types";

/**
 * Retrieves business info from an application (for a commercial loan)
 * @returns ok/error tuple. Value of tuple is the HTTP response details in either case
 */
const getBusiness: ({
  applicationId,
}: {
  applicationId: string;
}) => LOSAPIResponse<HTTPGetBusinessRes> = async ({
  applicationId,
}: {
  applicationId: string;
}) => {
  const response = await makeRequest<HTTPGetBusinessRes>(
    HTTPMethod.GET,
    restPaths.business(applicationId).get,
    undefined,
    true,
  );

  if (!response.error) {
    return { response, error: null };
  }

  return { response: null, error: response };
};

export default getBusiness;
