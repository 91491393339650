import ChatBubble from "@heroicons/react/24/outline/ChatBubbleOvalLeftEllipsisIcon";

import { Vehicle } from "#api.los/client.types";
import Button from "#v2-components/molecules/Button/Button";
import { ButtonVariant } from "#v2-components/molecules/Button/Button.types";

import s from "./NextSteps.module.scss";

const getMakeModelTextFromVehicle = (vehicle?: Vehicle): string => {
  switch (true) {
    case !!vehicle && (vehicle?.make === "Tesla" || vehicle?.make === "Rivian"):
      return `Sign your loan agreement for Tenet to release the funds to
      ${vehicle?.make} to complete the purchase of your ${vehicle?.model}.`;
    case !!vehicle:
      return `Sign your loan agreement for Tenet to release the funds to the
      dealership to complete the purchase of your ${vehicle?.make} ${vehicle?.model}.`;
    default:
      return `Sign your loan agreement for Tenet to release the funds to the
      dealership to complete the purchase of your vehicle.`;
  }
};

const Steps = ({ vehicle }: { vehicle?: Vehicle }) => {
  const makeModelStep = getMakeModelTextFromVehicle(vehicle);

  return (
    <ol>
      <li>Upload your income and ID documents</li>
      <li>
        Complete your vehicle details and upload the Purchase confirmation /
        Bill of Sale with{" "}
        <a
          href="https://intercom.help/tenetenergy/en/articles/7220217-what-does-the-loan-application-process-look-like"
          target="_blank"
          rel="noreferrer"
        >
          Tenet Energy Inc listed as the Lienholder
        </a>
      </li>
      <li>
        Do a hard credit check within 30 days of your vehicle delivery date
      </li>
      <li>{makeModelStep}</li>
    </ol>
  );
};

export const NextSteps = ({
  vehicle,
  onSubmit,
  firstName,
}: {
  vehicle?: Vehicle;
  onSubmit: () => void;
  firstName?: string;
}) => {
  return (
    <div className={s.container}>
      <h1>Hi {firstName}, almost done! Just a few steps left:</h1>
      <Steps vehicle={vehicle} />
      <Button
        variant={ButtonVariant.DARK_FILLED}
        onClick={onSubmit}
        data-cy="get-started-button"
      >
        Continue
      </Button>
      <hr />
      <div className={s.footer}>
        <p>
          We&apos;re here to help if you have any questions.{" "}
          <button
            type="button"
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              if (window.Intercom) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                window.Intercom("show");
              }
            }}
          >
            Chat now <ChatBubble />
          </button>
        </p>
        <div>
          <img alt="support team" src="/support-team.webp" />
          Tenet support team
        </div>
      </div>
    </div>
  );
};
