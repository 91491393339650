/** ******************************************************************************
Get Plaid Authentication Token
 * Gets an auth token from the plaid client
 ******************************************************************************* */

import { HTTPMethod, HTTPTokenRes } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { LMSAPIResponse } from "./client.types";

/**
 * Gets plaid token for payment authentication NOT INCOME VERIFICATION
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const getPaymentAuthToken: (
  accessToken?: string,
) => LMSAPIResponse<HTTPTokenRes> = async (accessToken) => {
  const response = await makeRequest<HTTPTokenRes>(
    HTTPMethod.PUT,
    restPaths.incomeVerification.getPaymentAuthToken,
    { body: { accessToken } },
    true,
    "lms",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getPaymentAuthToken;
