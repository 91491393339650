/** ******************************************************************************
Delete Payment Method
 * Deletes a user's payment method
 ******************************************************************************* */

import { HTTPMethod, HTTPResponse } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPGetPaymentMethodsResponse } from "./client.types";

const deletePaymentMethod: (paymentMethodId: string) => Promise<{
  response: null | HTTPResponse<HTTPGetPaymentMethodsResponse>;
  error: null | HTTPResponse<HTTPGetPaymentMethodsResponse>;
}> = async (paymentMethodId) => {
  const response = await makeRequest<HTTPGetPaymentMethodsResponse>(
    HTTPMethod.DELETE,
    restPaths.paymentMethods(paymentMethodId).deletePaymentMethod,
    {},
    true,
    "lms",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default deletePaymentMethod;
