/** ******************************************************************************
Get Loan By Id
 * uses the /loans/${loanId} endpoint to get loan 
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPLoanResponse, LMSAPIResponse } from "./client.types";

/**
 * Gets the active loan application for current application
 * @param applicationId loan application ID for the current application (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const getLoan: (loanId: string) => LMSAPIResponse<HTTPLoanResponse> = async (
  loanId,
) => {
  const response = await makeRequest<HTTPLoanResponse>(
    HTTPMethod.GET,
    restPaths.loan(loanId).get,
    {},
    true,
    "lms",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getLoan;
