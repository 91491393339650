/** ******************************************************************************
Verify User Email
 * uses the /user/update-email endpoint to update a user's email
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPUpdateEmailRes, LOSAPIResponse } from "./client.types";

/**
 * Verifies a user's email address change
 * @param emailVerificationId verification ID for an email change (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const verifyEmail: (
  emailVerificationId: string,
) => LOSAPIResponse<HTTPUpdateEmailRes> = async (emailVerificationId) => {
  const response = await makeRequest<HTTPUpdateEmailRes>(
    HTTPMethod.PUT,
    restPaths.user.verifyEmail,
    {
      body: { emailVerificationId },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default verifyEmail;
