/* eslint-disable @typescript-eslint/no-unsafe-argument */
export default class CauseError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(message?: string, options?: any) {
    /* MPR, 2022/8/23: Why do this? Because TS's Error type definition is wrong,
     * and I don't know how to extend it! If you do, go ahead and make it so it
     * correctly allows an optional second paramater with a cause (see
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/cause)
     * and remove this extension class.
     */
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    /* @ts-ignore */
    super(message, options);
    this.name = "Error";
    /* eslint-enable @typescript-eslint/ban-ts-comment */
  }
}
