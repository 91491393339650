const space = {
  0: "0",
  4: "4px",
  8: "8px",
  10: "10px",
  12: "12px",
  16: "16px",
  20: "20px",
  24: "24px",
  32: "32px",
  38: "38px",
  40: "40px",
  46: "46px",
  48: "48px",
  50: "50px",
  64: "64px",
  75: "75px",
  80: "80px",
  100: "100px",
  120: "120px",
  135: "135px",
  160: "160px",
  // Named aliases for semantic spacing values.
  base: "$4",
  expanded: "$24",
  gutter: "$16",
  header: "56px",
  auto: "auto",
};

export default space;
