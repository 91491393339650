/** ******************************************************************************
Get Loan Offers
 * uses the /applications/${applicationId}/offers endpoint to get loan offers
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPModelsRes, LOSAPIResponse } from "./client.types";

/**
 * Gets all UVCInfo rows for a given make
 * Note that we no longer can return all UVCs globally, because carAPI requires that we filter by make or year
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const getAllVehicleProfilesForMake: (
  make: string,
) => LOSAPIResponse<HTTPModelsRes> = async (make) => {
  const response = await makeRequest<HTTPModelsRes>(
    HTTPMethod.GET,
    `${restPaths.models.getAllByMake}?make=${make}`,
    {},
    false,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getAllVehicleProfilesForMake;
