/** ******************************************************************************
Get Plaid Token
 * uses the /token endpoint to get a plaid token for income verification
 ******************************************************************************* */

import { HTTPMethod, HTTPTokenRes } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { LOSAPIResponse } from "./client.types";

/**
 * Gets plaid token for income verification
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const getPlaidToken: () => LOSAPIResponse<HTTPTokenRes> = async () => {
  const response = await makeRequest<HTTPTokenRes>(
    HTTPMethod.GET,
    restPaths.incomeVerification.getToken,
    {},
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getPlaidToken;
