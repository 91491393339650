/** ******************************************************************************
  Initializes payoff document 
******************************************************************************* */

import { HTTPMethod, HTTPResponse } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPInitPayoffDocument } from "./client.types";

const initPayoffDocument: (loanId: string) => Promise<{
  response: null | HTTPResponse<HTTPInitPayoffDocument>;
  error: null | HTTPResponse<HTTPInitPayoffDocument>;
}> = async (loanId) => {
  const response = await makeRequest<HTTPInitPayoffDocument>(
    HTTPMethod.GET,
    restPaths.payoff(loanId).initDocument,
    {},
    true,
    "lms",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default initPayoffDocument;
