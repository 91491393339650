import { useCallback, useEffect, useRef, useState } from "react";

/**
 * This hook returns a tuple consisting of a state value, and an update function
 * for changing the state value. The update function is debounced to force
 * update requests to wait a certain amount of time before running again.
 */
function useDebounce<State>(
  initialValue: State | (() => State),
  delay?: number,
): [State, React.Dispatch<React.SetStateAction<State>>] {
  const [value, updateValue] = useState(initialValue);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const callback: React.Dispatch<React.SetStateAction<State>> = (nextValue) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      timeoutRef.current = undefined;
      updateValue(nextValue);
    }, delay);
  };

  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = undefined;
    },
    [initialValue, delay],
  );

  return [value, useCallback(callback, [updateValue, delay])];
}

export default useDebounce;
