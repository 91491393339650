import { css } from "#themes";

export const root = css({
  variants: {
    variant: {
      inverted: {
        color: "$textInverse",

        boxShadow: "0 1px 0 0 var(--colors-charcoal70) !important",

        div: {
          boxShadow: "none",
        },
        svg: {
          color: "$textInverse",
        },
      },
    },
  },
});
