import { BusinessInfoParamsDTO } from "#api.los/losDto.types";
import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPUpsertBusinessRes, LOSAPIResponse } from "./client.types";

/**
 * Updates/Creates a business info for an application (commercial loans)
 * @returns ok/error tuple. Value of tuple is the HTTP response details in either case
 */
const upsertBusinessInfo: (
  applicationId: string,
  payload: BusinessInfoParamsDTO,
) => LOSAPIResponse<HTTPUpsertBusinessRes> = async (
  applicationId: string,
  payload: BusinessInfoParamsDTO,
) => {
  const response = await makeRequest<HTTPUpsertBusinessRes>(
    HTTPMethod.POST,
    restPaths.business(applicationId).upsertInfo,
    {
      body: {
        businessName: payload.businessName,
        businessType: payload.businessType,
        ein: payload.ein,
        businessEmail: payload.businessEmail,
        businessPhone: payload.businessPhone,
        line1: payload.street,
        line2: payload.apartmentUnit,
        city: payload.city,
        state: payload.state,
        postalCode: payload.postalCode,
        registeredDate: payload.registeredDate,
        registeredState: payload.registeredState,
      },
    },
    true,
  );

  if (!response.error) {
    return { response, error: null };
  }

  return { response: null, error: response };
};

export default upsertBusinessInfo;
