import { forwardRef } from "react";

import { ArrowDown } from "#components/base/Icon/Icon";
import Input from "#components/base/Input/Input";

import s from "./Dropdown.module.scss";
import { Props } from "./Dropdown.types";

const Dropdown = forwardRef<HTMLSelectElement, Props>(
  (
    {
      placeholder,
      options = [],
      value,
      autoSelect,
      freezeOnOne,
      DownChevron,
      ...otherProps
    },
    ref,
  ) => {
    const displayable = (() => {
      const placeholderText =
        placeholder && !autoSelect
          ? [
              <option key="p" disabled value="">
                {placeholder}
              </option>,
            ]
          : [];

      return [
        ...placeholderText,
        options.map((option) => {
          const opt = option && (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          );
          return opt;
        }),
      ];
    })();

    return (
      <Input
        className={s.dropdown}
        defaultValue={value === null || value === undefined ? "" : undefined}
        value={value}
        {...otherProps}
        before={undefined}
        after={
          !freezeOnOne &&
          (DownChevron ? (
            <DownChevron
              className={s.pointerIcon}
              color="textPrimary"
              size={24}
            />
          ) : (
            <ArrowDown
              className={s.pointerIcon}
              color="textPrimary"
              size={24}
            />
          ))
        }
        disabled={(freezeOnOne && options.length < 2) || otherProps.disabled}
        as="select"
        ref={ref}
      >
        {displayable}
      </Input>
    );
  },
);

export default Dropdown;
