import { Address } from "#api.insightsV2/client.types";
import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import {
  HTTPGetChargerEligibilityResponse,
  LOSAPIResponse,
} from "./client.types";

/**
 * Retrieves charger eligibility for address and application
 * @param applicationId application to specify for charger
 * @param address address to check for eligibility
 * @returns charger eligibility details
 */

const getChargerEligibility: (
  applicationId: string,
  address: Address,
) => LOSAPIResponse<HTTPGetChargerEligibilityResponse> = async (
  applicationId: string,
  address: Address,
) => {
  const response = await makeRequest<HTTPGetChargerEligibilityResponse>(
    HTTPMethod.POST,
    restPaths.application(applicationId).chargerInstall.getChargerEligibility,
    { body: { ...address } },
    true,
  );

  if (!response.error) {
    return { response, error: null };
  }

  return { response: null, error: response };
};

export default getChargerEligibility;
