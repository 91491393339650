import { createContext, useMemo, useState } from "react";

const DEFAULT_VALUE: {
  modalEl?: HTMLDivElement;
  removeModalEl: (el?: HTMLDivElement) => void;
  unshiftModalEl: (el: HTMLDivElement) => void;
} = {
  modalEl: undefined,
  removeModalEl: () => undefined,
  unshiftModalEl: () => undefined,
};

export const ModalProviderContext = createContext(DEFAULT_VALUE);

/**
 * ModalProvider adds context values for setting and reading the most-recently
 * rendered modal DOM node. This is useful, as certain custom inputs may require
 * a specific portal node to render into, in order to function correctly while
 * maintaining the Modal's focus trap.
 */
const ModalProvider = (props: { children?: React.ReactNode }): JSX.Element => {
  const [modalEls, setModalEls] = useState<HTMLDivElement[]>([]);

  const unshiftModalEl = (el: HTMLDivElement) => {
    setModalEls([el, ...modalEls]);
  };

  const removeModalEl = (el?: HTMLDivElement) => {
    if (!el) {
      return;
    }

    setModalEls(modalEls.filter((modal) => modal !== el));
  };

  const modalValue = useMemo(() => {
    return { modalEl: modalEls[0], removeModalEl, unshiftModalEl };
  }, [modalEls.length, modalEls[0]]);

  return <ModalProviderContext.Provider value={modalValue} {...props} />;
};

export default ModalProvider;
