import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPDocusignResDEPRECATED, LOSAPIResponse } from "./client.types";

/**
 * Placeholder endpoint until the rest hydration endpoints are merged
 * @deprecated
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const DEPRECATEDCompleteDocusign: (
  appId: string,
  regionDocumentId: string,
) => LOSAPIResponse<HTTPDocusignResDEPRECATED> = async (
  appId,
  regionDocumentId,
) => {
  const response = await makeRequest<HTTPDocusignResDEPRECATED>(
    HTTPMethod.PUT,
    restPaths.task(appId).deprecatedDocusign,
    {
      body: { regionDocumentId },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default DEPRECATEDCompleteDocusign;
