/** ******************************************************************************
Get Loan Offers
 * uses the /applications/${applicationId}/offers endpoint to get loan offers
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPModelsSearchRes, LOSAPIResponse } from "./client.types";

/**
 * Gets all UVCInfo rows
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
export interface SearchParams {
  vin?: string;
  make?: string;
  model?: string;
  year?: string;
  trim?: string;
}
const search: (params: SearchParams) => LOSAPIResponse<HTTPModelsSearchRes> =
  async (params) => {
    let queryParams = "?";
    Object.keys(params).map((key) => {
      if (params[key as keyof SearchParams]) {
        queryParams += `${key}=${params[key as keyof SearchParams]}&`;
      }
      return key;
    });

    const url = `${restPaths.models.search}${queryParams}`;
    const response = await makeRequest<HTTPModelsSearchRes>(
      HTTPMethod.GET,
      url,
      {},
      false,
    );
    if (!response.error) {
      return { response, error: null };
    }
    return { response: null, error: response };
  };

export default search;
