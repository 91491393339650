import { css } from "#themes";
import { Breakpoints } from "#themes/breakpoints";
import { Spaces } from "#themes/theme.types";

import type {
  MarginArrayValues,
  MarginObject,
  Margins,
  PaddingArrayValues,
  PaddingObject,
} from "./layout.types";

export const absoluteFill = css({
  position: "absoluteFill",
  length: 0,
});
export const fixedFill = css({
  position: "fixedFill",
  length: 0,
});

const getCssSpacings = (
  spacingType: "margin" | "padding",
  amount?: MarginArrayValues | MarginObject,
) => {
  if (!amount) {
    return css({
      length: 0,
    });
  }

  if (typeof amount === "number" || typeof amount === "string") {
    return css({
      [spacingType]: `$${amount}`,
      length: 0,
    });
  }

  if (Array.isArray(amount)) {
    return css({
      [spacingType]: amount
        .filter(Boolean)
        .map((entry) => `$${entry}`)
        .join(" "),
      length: 0,
    });
  }

  return css({
    [`${spacingType}Bottom`]:
      typeof amount.bottom !== "undefined" ? `$${amount.bottom}` : undefined,
    [`${spacingType}Left`]:
      typeof amount.left !== "undefined" ? `$${amount.left}` : undefined,
    [`${spacingType}Right`]:
      typeof amount.right !== "undefined" ? `$${amount.right}` : undefined,
    [`${spacingType}Top`]:
      typeof amount.top !== "undefined" ? `$${amount.top}` : undefined,
    length: 0,
  });
};

export const contentWidth = ({ variant = "half" } = {}) => {
  switch (variant) {
    case "dashboardSection":
      return css({
        margin: "0 auto",
        maxWidth: "$maxWidth",
        paddingX: "$20",
        width: "100%",

        // DONT CHANGE THESE
        "@minTabletSmall": { paddingX: 100 },
        "@minDesktopSmall": { paddingX: 100 },
        length: 0,
      });
    case "home":
      return css({
        margin: "0 auto",
        width: "100%",
        length: 0,
      });
    case "homeSection":
      return css({
        margin: "0 auto",
        paddingBottom: "$expanded",
        width: "100%",
        paddingX: 20,

        "@minTabletSmall": { paddingX: 40 },
        "@minDesktopSmall": { paddingX: 100 },
        length: 0,
      });
    case "wide":
      return css({
        // margin: "0 auto",
        maxWidth: "$maxWidth",
        width: "100%",
        length: 0,
      });
    case "half":
    default:
      return css({
        flexGrow: 1,
        margin: 0,
        maxWidth: "$maxWidthHalf",
        paddingBottom: "$20",
        paddingX: "$20",
        width: "100%",

        "@minTabletSmall": { paddingX: 80 },
        length: 0,
      });
  }
};

export const loanFormSubmit = css({
  marginTop: "auto",

  "@minDesktopHeight": {
    marginTop: 0,
  },
  length: 0,
});

export const gap = (amount?: Spaces) =>
  css({
    gap: amount,
    length: 0,
  });

export const margin = (amount?: MarginArrayValues | MarginObject) =>
  getCssSpacings("margin", amount);

export const marginX = (amount?: Margins) =>
  css({
    marginX: `$${amount}`,
    length: 0,
  });
export const marginY = (amount?: Margins) =>
  css({
    marginY: `$${amount}`,
    length: 0,
  });
export const marginTop = (amount?: Margins) =>
  css({
    marginTop: `$${amount}`,
    length: 0,
  });
export const marginBottom = (amount?: Margins) =>
  css({
    marginBottom: `$${amount}`,
    length: 0,
  });

export const overflowX = css({
  overflowX: "auto",
  length: 0,
});

export const padding = (amount?: PaddingArrayValues | PaddingObject) =>
  getCssSpacings("padding", amount);

export const paddingX = (amount: Spaces) =>
  css({
    paddingX: `$${amount}`,
    length: 0,
  });
export const paddingY = (amount: Spaces) =>
  css({
    paddingY: `$${amount}`,
    length: 0,
  });

export const paddingTop = (amount: Spaces) =>
  css({
    paddingTop: `$${amount}`,
    length: 0,
  });

export const paddingBottom = (amount: Spaces) =>
  css({
    paddingBottom: `$${amount}`,
    length: 0,
  });

export const hideAboveBreakpoint = (breakpoint: Breakpoints) =>
  css({
    [`@${breakpoint}`]: {
      display: "none",
    },
    length: 0,
  });

export const hideBelowBreakpoint = (breakpoint: Breakpoints) =>
  css({
    display: "none",

    [`@${breakpoint}`]: {
      display: "flex",
    },
    length: 0,
  });

export const fullWidthPercent = css({
  width: "100%",
  length: 0,
});

export const visuallyHidden = css({
  clip: "rect(0 0 0 0)",
  height: 0,
  opacity: 0,
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  width: 0,
  length: 0,
});

export const invisible = css({
  opacity: 0,
  length: 0,
});

export const flexAlignCenter = css({
  display: "flex",
  alignItems: "center",
});
