import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPBuildOfferResponse } from "./client.types";

const buildOffer = async (
  applicationId: string,
  termLengthMonths?: number,
  principalAmountCents?: number,
  deferredPaymentCents?: number,
) => {
  const response = await makeRequest<HTTPBuildOfferResponse>(
    HTTPMethod.PUT,
    restPaths.offer(applicationId).build,
    {
      body: { termLengthMonths, principalAmountCents, deferredPaymentCents },
    },
    true,
  );

  if (!response.error) {
    return { response, error: null };
  }

  return { response: null, error: response };
};

export default buildOffer;
