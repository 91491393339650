import Cookies from "js-cookie";
import { useContext, useEffect } from "react";
import { toast } from "react-toastify";

import LoadingState from "#components/partial/LoadingState/LoadingState";
import ReferralLink from "#components/partial/Referrals/ReferralLink";
import { useInitialPageInfo } from "#components/provider/InitialPageInfo";
import { ReferralProvider, useReferral } from "#components/provider/Referral";
import { ModalType } from "#components/provider/UIProviders/ModalProvider/ModalConfig";
import { ModalContext } from "#components/provider/UIProviders/ModalProvider/ModalProvider";
import Button from "#v2-components/molecules/Button/Button";
import { ButtonVariant } from "#v2-components/molecules/Button/Button.types";

import s from "./ReferralAnnounce.module.scss";
import { GetReferralLink } from "./util";

const TRACKING_COOKIE_NAME = "referralModalAnnouncementSeenLast30";
const NEW_TRACKING_LOCAL_STORAGE_NAME = "referralModalAnnouncementSeen";

const getReferralModalSeenCookie = () => {
  // set the new local storage item if the old cookie exists.
  if (Cookies.get(TRACKING_COOKIE_NAME)) {
    setReferralModalSeenCookie();
    return true;
  }
  return (
    Cookies.get(TRACKING_COOKIE_NAME) ||
    localStorage.getItem(NEW_TRACKING_LOCAL_STORAGE_NAME)
  );
};

const setReferralModalSeenCookie = () => {
  localStorage.setItem(NEW_TRACKING_LOCAL_STORAGE_NAME, "true;path=/");
};

/**
 * Referral Announce
 * Add to a page to automatically display a popup informing
 * users of the referral program if they meet certain criteria
 * (initially a) having an open loan and b) not having seen the popup before)
 */
const ReferralAnnounce = () => {
  const { data: initDataReq } = useInitialPageInfo();
  const hasAnyFundedLoans = initDataReq?.loans?.length;
  const hasSeenModal = (() => {
    if (typeof window === "undefined") return false;
    const hasSeenBanner = getReferralModalSeenCookie();
    return hasSeenBanner != null && hasSeenBanner !== "";
  })();
  const { openModal, closeModal } = useContext(ModalContext);
  const showModal = () => {
    openModal({
      type: ModalType.REFERRAL_ANNOUNCE,
      componentProps: {
        closeModal: () => {
          setReferralModalSeenCookie();
          closeModal(ModalType.REFERRAL_ANNOUNCE);
        },
      },
      modalProps: {
        title: "",
        removeChildPadding: true,
        // className: s.modalContentWrapper,
        containerClassName: s.modalWrapper,
        onUnmount: setReferralModalSeenCookie,
        providers: ({ children }) => (
          <ReferralProvider>{children}</ReferralProvider>
        ),
      },
    });
  };
  useEffect(() => {
    if (hasAnyFundedLoans && !hasSeenModal) {
      showModal();
    }
  }, [hasAnyFundedLoans, hasSeenModal]);
  return <div style={{ display: "none" }}>&nbsp;</div>;
};

export default ReferralAnnounce;

/**
 * ReferralModal
 * The actual modal that displays the referral program announcement
 * This could probably live in another file but it will literally only
 * ever be used in one other place, hopefully not a circular dep!
 * It makes sense to keep it here for now, as they do need to share
 * interrelated styles.
 */
export const ReferralModal = ({ closeModal }: { closeModal: () => void }) => {
  const { data: referallReq } = useReferral();
  const { referralCode: code } = referallReq?.data || {};
  return (
    <div className={s.modalContentWrapper}>
      <img
        src="https://images.ctfassets.net/w0ps5rolaaeh/6uLCO64no5u4hZUwexvRWs/8af2ca30d996bb959f2c4ef316b9639e/paper-plane-swoosh.svg"
        alt="paper plane illustration"
      />
      <h3>Introducing referrals!</h3>
      <p>
        For every person you refer whose loan is funded, you will receive $200
        VISA gift card and your referral will receive a $50 VISA gift card. The
        sky is the limit - there is no cap to how many people you refer and
        earn.
      </p>
      {code ? (
        <>
          <ReferralLink code={code} onCopy={setReferralModalSeenCookie} />
          <div className={s.modalButtonWrapper}>
            <Button
              variant={ButtonVariant.DARK_FILLED}
              type="button"
              onClick={() => {
                navigator.clipboard.writeText(GetReferralLink(code));
                toast.success("Referral link copied!");
                closeModal();
              }}
              data-cy="copy-invite-link"
            >
              Copy invite link
            </Button>
          </div>
        </>
      ) : (
        <LoadingState />
      )}
    </div>
  );
};
