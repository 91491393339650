/* eslint-disable func-names */
/**
 * Yup Validator Extensions
 *
 * Allows for the use of custom validator definitions
 * If you add to this file, be sure to extend the type
 * definition in src/types/yup.d.ts
 */
import { addMethod, string } from "yup";

/* MPR, 2023/05/16: This is absolutely not the correct place to be defining these */
addMethod(string, "validatePasswordUppercase", function (errorMessage: string) {
  return this.test(
    "validate-password-uppercase",
    errorMessage || "Passwords must contain at least one uppercase letter",
    function (value) {
      const { path, createError } = this;
      const test = (v: string) => /[A-Z]/.test(v);

      return test(value || "") || createError({ path, message: errorMessage });
    },
  );
});
addMethod(string, "validatePasswordLowercase", function (errorMessage: string) {
  return this.test(
    "validate-password-lowercase",
    errorMessage || "Passwords must contain at least one lowercase letter",
    function (value) {
      const { path, createError } = this;
      const test = (v: string) => /[a-z]/.test(v);

      return test(value || "") || createError({ path, message: errorMessage });
    },
  );
});
addMethod(string, "validatePasswordNumber", function (errorMessage: string) {
  return this.test(
    "validate-password-number",
    errorMessage || "Passwords must contain at least one number",
    function (value) {
      const { path, createError } = this;
      const test = (v: string) => /[0-9]/.test(v);

      return test(value || "") || createError({ path, message: errorMessage });
    },
  );
});
