/** ******************************************************************************
Update Loan Applicant
 * uses the /applications/${applicationId}/applicant endpoint to update applicant information
 ******************************************************************************* */
import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import {
  EmploymentType,
  HousingOwnershipType,
} from "#apollo/deprecatedTypesStillInUse";
import { LoanPreference } from "#components/partial/OffersIntent/OffersIntent.types";
import { restPaths } from "#constants/restPaths";

import { HTTPApplicantRes, LOSAPIResponse } from "./client.types";

const updateApplicant = {
  /**
   * Updates/sets an applicant's loan preference
   * @param applicationId loan application ID for the current application (string)
   * @param loanPreference loan preference for applicant, used for suggestions on what to select (LoanPref)
   * @returns an object containing response and error properties (HTTPResponse | null)
   */
  loanPreference: async (
    applicationId: string,
    loanPreference: LoanPreference,
  ): LOSAPIResponse<HTTPApplicantRes> => {
    const response = await makeRequest<HTTPApplicantRes>(
      HTTPMethod.PUT,
      restPaths.application(applicationId).applicant,
      {
        body: {
          loanPreference,
        },
      },
      true,
    );
    if (!response.error) {
      return { response, error: null };
    }
    return { response: null, error: response };
  },
  /**
   * Updates/sets an applicant's address information
   * @param applicationId loan application ID for the current application (string)
   * @param line1 street address for applicant (string)
   * @param line2 apartment code or second line of address (string | undefined)
   * @param city city of address (string)
   * @param county county of address (string)
   * @param stateCode two letter abbreviation for usState (string)
   * @param postalCode postal code for address (string)
   * @returns an object containing response and error properties (HTTPResponse | null)
   */
  address: async (applicationId: string): LOSAPIResponse<HTTPApplicantRes> => {
    const response = await makeRequest<HTTPApplicantRes>(
      HTTPMethod.PUT,
      restPaths.application(applicationId).applicant,
      {
        body: {
          address: true,
        },
      },
      true,
    );
    if (!response.error) {
      return { response, error: null };
    }
    return { response: null, error: response };
  },
  /**
   * Updates/sets an applicant's housing information
   * @param applicationId loan application ID for the current application (string)
   * @param type housing ownership type to update to ("Own" | "Rent")
   * @param monthlyPaymentCents monthly housing payment in cents to update to (number)
   * @returns an object containing response and error properties (HTTPResponse | null)
   */
  housing: async (
    applicationId: string,
    housingOwnershipType: HousingOwnershipType,
    monthlyPaymentCents: number,
  ): LOSAPIResponse<HTTPApplicantRes> => {
    const response = await makeRequest<HTTPApplicantRes>(
      HTTPMethod.PUT,
      restPaths.application(applicationId).applicant,
      {
        body: {
          housing: {
            housingOwnershipType,
            monthlyPaymentCents,
          },
        },
      },
      true,
    );
    if (!response.error) {
      return { response, error: null };
    }
    return { response: null, error: response };
  },
  /**
   * Updates/sets an applicant's employment information
   * @param applicationId loan application ID for the current application (string)
   * @param employmentType employment type to update to ("FullTime" | "PartTime" | "SelfEmployed" | "Unemployed")
   * @param grossAnnualCents gross annual income in cents to update to (number)
   * @returns an object containing response and error properties (HTTPResponse | null)
   */
  income: async (
    applicationId: string,
    employmentType: EmploymentType,
    grossAnnualCents: number,
    employer: string,
    occupation: string,
    otherGrossIncome: number,
    employmentDurationMonths: number,
    contactNumber?: string,
  ): LOSAPIResponse<HTTPApplicantRes> => {
    const response = await makeRequest<HTTPApplicantRes>(
      HTTPMethod.PUT,
      restPaths.application(applicationId).applicant,
      {
        body: {
          income: {
            employmentType,
            grossAnnualCents,
            employer,
            occupation,
            otherGrossIncome,
            employmentDurationMonths,
            contactNumber,
          },
        },
      },
      true,
    );
    if (!response.error) {
      return { response, error: null };
    }
    return { response: null, error: response };
  },
  /**
   * Updates/sets an applicant's soft credit consent
   * @param applicationId loan application ID for the current application (string)
   * @param softCreditConsent applicant consent to soft credit check (boolean)
   * @returns an object containing response and error properties (HTTPResponse | null)
   */
  softCreditConsent: async (
    applicationId: string,
    softCreditConsent: boolean,
  ): LOSAPIResponse<HTTPApplicantRes> => {
    const response = await makeRequest<HTTPApplicantRes>(
      HTTPMethod.PUT,
      restPaths.application(applicationId).applicant,
      {
        body: {
          softCreditConsent,
          statedNotActiveMilitaryDuty: true,
        },
      },
      true,
    );
    if (!response.error) {
      return { response, error: null };
    }
    return { response: null, error: response };
  },
  /**
   * Updates/sets an applicant's hard credit consent
   * @param applicationId loan application ID for the current application (string)
   * @param hardCreditConsent applicant consent to hard credit check (boolean)
   * @returns an object containing response and error properties (HTTPResponse | null)
   */
  hardCreditConsent: async (
    applicationId: string,
    hardCreditConsent: boolean,
  ): LOSAPIResponse<HTTPApplicantRes> => {
    const response = await makeRequest<HTTPApplicantRes>(
      HTTPMethod.PUT,
      restPaths.application(applicationId).applicant,
      {
        body: {
          hardCreditConsent,
        },
      },
      true,
    );
    if (!response.error) {
      return { response, error: null };
    }
    return { response: null, error: response };
  },
};

export default updateApplicant;
