/** ******************************************************************************
Get Selected Loan Offer
 * uses the /applications/${applicationId}/offers endpoint to get loan offers
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPGetOfferRes, LOSAPIResponse } from "./client.types";

/**
 * Gets selected loan offer for current application
 * @param applicationId loan application ID for the current application (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const getSelectedOffer: (
  applicationId: string,
) => LOSAPIResponse<HTTPGetOfferRes> = async (applicationId) => {
  const response = await makeRequest<HTTPGetOfferRes>(
    HTTPMethod.GET,
    restPaths.application(applicationId).offers.getSelectedOffer,
    {},
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getSelectedOffer;
