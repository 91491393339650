import dynamic from "next/dynamic";
import React from "react";

import { css } from "#themes";

import { Props } from "./Icon.types";

const add = dynamic(() => import("./icons/add.svg"));
const alert = dynamic(() => import("./icons/alert.svg"));
const arrowDown = dynamic(() => import("./icons/arrow-down.svg"));
const arrowLeft = dynamic(() => import("./icons/arrow-left.svg"));
const arrowLeftAlt = dynamic(() => import("./icons/arrow-left-alt.svg"));
const arrowRight = dynamic(() => import("./icons/arrow-right.svg"));
const arrowRightAlt = dynamic(() => import("./icons/arrow-right-alt.svg"));
const arrowTrendUp = dynamic(() => import("./icons/arrow-trend-up.svg"));
const arrowUp = dynamic(() => import("./icons/arrow-up.svg"));
const arrowUpRightAlt = dynamic(() => import("./icons/arrow-up-right-alt.svg"));
const article = dynamic(() => import("./icons/article.svg"));
const autopayCircularArrows = dynamic(
  () => import("./icons/autopay-circular-arrows.svg"),
);
const bolt = dynamic(() => import("./icons/bolt.svg"));
const car = dynamic(() => import("./icons/car.svg"));
const solidCar = dynamic(() => import("./icons/solid-car.svg"));
const outlineCar = dynamic(() => import("./icons/outline-car.svg"));
const calculator = dynamic(() => import("./icons/calculator.svg"));
const card = dynamic(() => import("./icons/card.svg"));
const chargerPlugGreen = dynamic(
  () => import("./icons/charger-plug-green.svg"),
);
const chargingStation = dynamic(() => import("./icons/charging-station.svg"));
const chargingStationBlue = dynamic(
  () => import("./icons/charging-station-blue.svg"),
);
const check = dynamic(() => import("./icons/check.svg"));
const checkCircle = dynamic(() => import("./icons/check-circle.svg"));
const checkCircle2 = dynamic(() => import("./icons/checkCircle2.svg"));
const chevronDown = dynamic(() => import("./icons/chevron-down.svg"));
const circleCheckmark = dynamic(() => import("./icons/circleCheckmark.svg"));
const circledOnePurple = dynamic(
  () => import("./icons/circled-one-purple.svg"),
);
const circledTwoPurple = dynamic(
  () => import("./icons/circled-two-purple.svg"),
);
const circledThreePurple = dynamic(
  () => import("./icons/circled-three-purple.svg"),
);
const clock = dynamic(() => import("./icons/clock.svg"));
const close = dynamic(() => import("./icons/close.svg"));
const cog = dynamic(() => import("./icons/cog.svg"));
const cogOutline = dynamic(() => import("./icons/cog-outline.svg"));
const disconnected = dynamic(() => import("./icons/disconnected.svg"));

const editV2 = dynamic(() => import("./icons/edit-v2.svg"));
const email = dynamic(() => import("./icons/email.svg"));
const gps = dynamic(() => import("./icons/gps.svg"));
const infoCircleBlue = dynamic(() => import("./icons/info-circle-blue.svg"));
const info2 = dynamic(() => import("./icons/info2.svg"));
const line22 = dynamic(() => import("./icons/Line22.svg"));
const locationPin = dynamic(() => import("./icons/location-pin.svg"));
const locationPinLight = dynamic(
  () => import("./icons/location-pin-light.svg"),
);
const logo = dynamic(() => import("./icons/logo.svg"));
const menu = dynamic(() => import("./icons/menu.svg"));
const minus = dynamic(() => import("./icons/minus.svg"));
const money3 = dynamic(() => import("./icons/money3.svg"));
const refresh = dynamic(() => import("./icons/refresh.svg"));
const replay = dynamic(() => import("./icons/replay.svg"));
const tenet = dynamic(() => import("./icons/tenet.svg"));
const uploadDocument = dynamic(() => import("./icons/upload.svg"));
const info = dynamic(() => import("./icons/info.svg"));
const tenetLogo = dynamic(() => import("./icons/tenetlogo.svg"));
const tenetLogoNoPadding = dynamic(
  () => import("./icons/tenetLogoNoPadding.svg"),
);
const searchCar = dynamic(() => import("./icons/searchCar.svg"));
const personCircled = dynamic(() => import("./icons/personCircled.svg"));

const reverseQuote = dynamic(() => import("./icons/reverseQuote.svg"));
const bulletArrow = dynamic(() => import("./icons/bulletArrow.svg"));
const license = dynamic(() => import("./icons/license.svg"));
const licenseBack = dynamic(() => import("./icons/licenseBack.svg"));
const arrowUpSolid = dynamic(() => import("./icons/arrowUpSolid.svg"));
const arrowDownSolid = dynamic(() => import("./icons/arrowDownSolid.svg"));
const warning = dynamic(() => import("./icons/warning.svg"));

const carv2 = dynamic(() => import("./icons/car-v2.svg"));
const tip = dynamic(() => import("./icons/tip.svg"));
const tipDollar = dynamic(() => import("./icons/tips/dollar-icon.svg"));
const tipCheck = dynamic(() => import("./icons/tips/check-icon.svg"));
const revert = dynamic(() => import("./icons/tips/revert.svg"));
const autopayClock = dynamic(() => import("./icons/autopay-clock.svg"));
const clipboard = dynamic(() => import("./icons/clipboard.svg"));
const clipboardCheck = dynamic(() => import("./icons/clipboard-check.svg"));
const getaround = dynamic(() => import("./icons/getaround.svg"));
const turo = dynamic(() => import("./icons/turo.svg"));
const x = dynamic(() => import("./icons/x.svg"));
const lockOpen = dynamic(() => import("./icons/lock-open.svg"));
const litBulb = dynamic(() => import("./icons/lit-bulb.svg"));
const litBulbOutline = dynamic(() => import("./icons/lit-bulb-outline.svg"));

// EVPP Icons
const batteryDriveUnit = dynamic(() => import("./icons/BatteryDriveUnit.svg"));
const chargePort = dynamic(() => import("./icons/charge-port.svg"));
const controlModule = dynamic(() => import("./icons/control-module.svg"));
const mediaControlUnit = dynamic(
  () => import("./icons/media-control-unit.svg"),
);
const roadsideAssistance = dynamic(
  () => import("./icons/roadside-assistance.svg"),
);
const suspension = dynamic(() => import("./icons/suspension.svg"));
const xCare = dynamic(() => import("./icons/x-care-logo.svg"));
const businessTag = dynamic(() => import("./icons/business-tag.svg"));
const xCircle = dynamic(() => import("./icons/x-circle.svg"));
const checkCircle3 = dynamic(() => import("./icons/check-circle-3.svg"));
const dollarCircle = dynamic(() => import("./icons/dollarCircle.svg"));
const boltCircle = dynamic(() => import("./icons/boltCircle.svg"));
const leafCircle = dynamic(() => import("./icons/leafCircle.svg"));
const phoneCircle = dynamic(() => import("./icons/phoneCircle.svg"));
const phone = dynamic(() => import("./icons/phone.svg"));
const facebookDark = dynamic(() => import("./icons/facebook-dark.svg"));
const twitterDark = dynamic(() => import("./icons/twitter-dark.svg"));
const hamburgerMenu = dynamic(() => import("./icons/hamburger-menu.svg"));
const download = dynamic(() => import("./icons/download-icon.svg"));
const doorHandle = dynamic(() => import("./icons/door-handle.svg"));
const singleFamilyHome = dynamic(
  () => import("./icons/single-family-home.svg"),
);
const multiFamilyHome = dynamic(() => import("./icons/multi-family-home.svg"));
const commercialHome = dynamic(() => import("./icons/commercial-home.svg"));
const unknownHome = dynamic(() => import("./icons/unknown-home.svg"));
const tenetCircle = dynamic(() => import("./icons/tenet-circle.svg"));
const roundedMenu = dynamic(() => import("./icons/menu-rounded.svg"));
const tenetConnect = dynamic(() => import("./icons/tenet-connect.svg"));
const tenetConnectDark = dynamic(
  () => import("./icons/tenet-connect-dark.svg"),
);
const treslName = dynamic(() => import("./icons/tresl-name-logo.svg"));
const gasPump = dynamic(() => import("./icons/maki_fuel.svg"));

const createIconComponent = (Icon: React.ElementType): React.FC<Props> =>
  function iconComponent({
    color,
    size = "inline",
    fill = "CurrentColor",
    ...otherProps
  }) {
    return (
      <Icon
        aria-hidden
        css={css({
          color: color ? `${color}` : undefined,
          fill,
          size: size === "inline" ? "1em" : size,
          length: 0,
        })}
        focusable="false"
        {...otherProps}
      />
    );
  };

export const SearchCar = createIconComponent(searchCar as React.ElementType);
export const TenetLogo = createIconComponent(tenetLogo as React.ElementType);
export const TenetLogoNoPadding = createIconComponent(
  tenetLogoNoPadding as React.ElementType,
);
export const Line22 = createIconComponent(line22 as React.ElementType);

export const Article = createIconComponent(article as React.ElementType);
export const Add = createIconComponent(add as React.ElementType);
export const Alert = createIconComponent(alert as React.ElementType);
export const ArrowDown = createIconComponent(arrowDown as React.ElementType);

export const ArrowUpSolid = createIconComponent(
  arrowUpSolid as React.ElementType,
);
export const ArrowDownSolid = createIconComponent(
  arrowDownSolid as React.ElementType,
);
export const ArrowLeft = createIconComponent(arrowLeft as React.ElementType);
export const ArrowLeftAlt = createIconComponent(
  arrowLeftAlt as React.ElementType,
);
export const ArrowTrendUp = createIconComponent(
  arrowTrendUp as React.ElementType,
);
export const ArrowRight = createIconComponent(arrowRight as React.ElementType);
export const ArrowRightAlt = createIconComponent(
  arrowRightAlt as React.ElementType,
);

export const ArrowUp = createIconComponent(arrowUp as React.ElementType);

export const ArrowUpRightAlt = createIconComponent(
  arrowUpRightAlt as React.ElementType,
);
export const Car = createIconComponent(car as React.ElementType);
export const SolidCar = createIconComponent(solidCar as React.ElementType);
export const OutlineCar = createIconComponent(outlineCar as React.ElementType);
export const Card = createIconComponent(card as React.ElementType);
export const ChargerPlugGreen = createIconComponent(
  chargerPlugGreen as React.ElementType,
);
export const ChargingStation = createIconComponent(
  chargingStation as React.ElementType,
);
export const ChargingStationBlue = createIconComponent(
  chargingStationBlue as React.ElementType,
);
export const Check = createIconComponent(check as React.ElementType);
export const ChevronDown = createIconComponent(
  chevronDown as React.ElementType,
);
export const CheckCircle2 = createIconComponent(
  checkCircle2 as React.ElementType,
);
export const CircledOnePurple = createIconComponent(
  circledOnePurple as React.ElementType,
);
export const CircledTwoPurple = createIconComponent(
  circledTwoPurple as React.ElementType,
);
export const CircledThreePurple = createIconComponent(
  circledThreePurple as React.ElementType,
);
export const Clock = createIconComponent(clock as React.ElementType);
export const Close = createIconComponent(close as React.ElementType);
export const Cog = createIconComponent(cog as React.ElementType);
export const CogOutline = createIconComponent(cogOutline as React.ElementType);
export const Disconnected = createIconComponent(
  disconnected as React.ElementType,
);
export const Email = createIconComponent(email as React.ElementType);
export const GPS = createIconComponent(gps as React.ElementType);
export const Info = createIconComponent(info as React.ElementType);
export const InfoCircleBlue = createIconComponent(
  infoCircleBlue as React.ElementType,
);
export const Info2 = createIconComponent(info2 as React.ElementType);
export const Logo = createIconComponent(logo as React.ElementType);
export const Menu = createIconComponent(menu as React.ElementType);
export const Minus = createIconComponent(minus as React.ElementType);
export const ReverseQuote = createIconComponent(
  reverseQuote as React.ElementType,
);
export const Phone = createIconComponent(phone as React.ElementType);

export const Money3 = createIconComponent(money3 as React.ElementType);
export const Refresh = createIconComponent(refresh as React.ElementType);
export const Replay = createIconComponent(replay as React.ElementType);
export const Tenet = createIconComponent(tenet as React.ElementType);
export const CircleCheckmark = createIconComponent(
  circleCheckmark as React.ElementType,
);

export const UploadDocument = createIconComponent(
  uploadDocument as React.ElementType,
);

export const CheckCircle = createIconComponent(
  checkCircle as React.ElementType,
);

export const AutopayCircularArrows = createIconComponent(
  autopayCircularArrows as React.ElementType,
);

export const Bolt = createIconComponent(bolt as React.ElementType);

export const LocationPin = createIconComponent(
  locationPin as React.ElementType,
);
export const LocationPinLight = createIconComponent(
  locationPinLight as React.ElementType,
);

export const PersonCircled = createIconComponent(
  personCircled as React.ElementType,
);

export const BulletArrow = createIconComponent(
  bulletArrow as React.ElementType,
);

export const License = createIconComponent(license as React.ElementType);

export const LicenseBack = createIconComponent(
  licenseBack as React.ElementType,
);

export const EditV2 = createIconComponent(editV2 as React.ElementType);

export const Warning = createIconComponent(warning as React.ElementType);
export const CarV2 = createIconComponent(carv2 as React.ElementType);
export const Tip = createIconComponent(tip as React.ElementType);
export const TipDollar = createIconComponent(tipDollar as React.ElementType);
export const TipCheck = createIconComponent(tipCheck as React.ElementType);
export const Revert = createIconComponent(revert as React.ElementType);

// EVPP Icons
export const ChargePort = createIconComponent(chargePort as React.ElementType);
export const ControlModule = createIconComponent(
  controlModule as React.ElementType,
);
export const MediaControlUnit = createIconComponent(
  mediaControlUnit as React.ElementType,
);
export const BatteryDriveUnit = createIconComponent(
  batteryDriveUnit as React.ElementType,
);
export const RoadsideAssistance = createIconComponent(
  roadsideAssistance as React.ElementType,
);
export const Suspension = createIconComponent(suspension as React.ElementType);
export const XCare = createIconComponent(xCare as React.ElementType);
export const AutopayClock = createIconComponent(
  autopayClock as React.ElementType,
);
export const Clipboard = createIconComponent(clipboard as React.ElementType);
export const ClipboardCheck = createIconComponent(
  clipboardCheck as React.ElementType,
);
export const BusinessTag = createIconComponent(
  businessTag as React.ElementType,
);

export const Getaround = createIconComponent(getaround as React.ElementType);
export const Turo = createIconComponent(turo as React.ElementType);
export const XCircle = createIconComponent(xCircle as React.ElementType);
export const CheckCircle3 = createIconComponent(
  checkCircle3 as React.ElementType,
);
export const X = createIconComponent(x as React.ElementType);
export const LockOpen = createIconComponent(lockOpen as React.ElementType);
export const LitBulb = createIconComponent(litBulb as React.ElementType);
export const LitBulbOutline = createIconComponent(
  litBulbOutline as React.ElementType,
);
export const DollarCircle = createIconComponent(
  dollarCircle as React.ElementType,
);
export const LeafCircle = createIconComponent(leafCircle as React.ElementType);
export const BoltCircle = createIconComponent(boltCircle as React.ElementType);
export const PhoneCircle = createIconComponent(
  phoneCircle as React.ElementType,
);
export const TwitterDark = createIconComponent(
  twitterDark as React.ElementType,
);
export const FacebookDark = createIconComponent(
  facebookDark as React.ElementType,
);
export const HamburgerMenu = createIconComponent(
  hamburgerMenu as React.ElementType,
);
export const Download = createIconComponent(download as React.ElementType);
export const DoorHandle = createIconComponent(doorHandle as React.ElementType);

export const SingleFamilyHome = createIconComponent(
  singleFamilyHome as React.ElementType,
);
export const MultiFamilyHome = createIconComponent(
  multiFamilyHome as React.ElementType,
);
export const CommercialHome = createIconComponent(
  commercialHome as React.ElementType,
);
export const UnknownHome = createIconComponent(
  unknownHome as React.ElementType,
);
export const TenetCircle = createIconComponent(
  tenetCircle as React.ElementType,
);
export const Calculator = createIconComponent(calculator as React.ElementType);

export const RoundedMenu = createIconComponent(
  roundedMenu as React.ElementType,
);

export const TenetConnect = createIconComponent(
  tenetConnect as React.ElementType,
);

export const TenetConnectDark = createIconComponent(
  tenetConnectDark as React.ElementType,
);

export const TreslName = createIconComponent(treslName as React.ElementType);
export const GasPump = createIconComponent(gasPump as React.ElementType);
