export const marginX = (value: string | number) => ({
  marginLeft: value,
  marginRight: value,
});

export const marginY = (value: string | number) => ({
  marginBottom: value,
  marginTop: value,
});

export const paddingX = (value: string | number) => ({
  paddingLeft: value,
  paddingRight: value,
});

export const paddingY = (value: string | number) => ({
  paddingBottom: value,
  paddingTop: value,
});

export const size = (value: string | number) => ({
  height: value,
  width: value,
});

export const position = (value: string) => {
  switch (value) {
    case "absoluteFill":
      return {
        bottom: 0,
        left: 0,
        position: "absolute",
        right: 0,
        size: "100%",
        top: 0,
      };
    case "fixedFill":
      return {
        bottom: 0,
        left: 0,
        position: "fixed",
        right: 0,
        size: "100%",
        top: 0,
      };
    default:
      return { position: value };
  }
};
