import { transitions } from "#constants";
import { css } from "#themes";

export const fade = css({
  [`&.${transitions.keys.transition}`]: {
    transition: "opacity 350ms ease",
  },

  [`&.${transitions.keys.start}`]: {
    opacity: 0,
  },

  [`&.${transitions.keys.end}`]: {
    opacity: 1,
  },
});

export const growFromCenter = css({
  [`&.${transitions.keys.transition}`]: {
    transformOrigin: "center",
    transition: "opacity 300ms ease, transform 250ms ease",
  },

  [`&.${transitions.keys.start}`]: {
    opacity: 0,
    transform: "scale3d(0.75, 0.5, 0)",
  },

  [`&.${transitions.keys.end}`]: {
    opacity: 1,
    transform: "none",
  },
});

export const modalShow = css({
  [`&.${transitions.keys.transition}`]: {
    transition: "opacity 350ms ease, transform 350ms ease",
  },

  [`&.${transitions.keys.start}`]: {
    transform: "translate3d(0, 100%, 0)",
  },

  [`&.${transitions.keys.end}`]: {
    transform: "none",
  },

  "@minTabletLarge": {
    [`&.${transitions.keys.start}`]: {
      opacity: 0,
      transform: "scale3d(0.95, 0.95, 1) translate3d(0, 10%, 0)",
    },

    [`&.${transitions.keys.end}`]: {
      opacity: 1,
    },
  },
});

export const sidebarShow = css({
  [`&.${transitions.keys.transition}`]: {
    transition: "opacity 350ms ease, transform 350ms ease",
  },

  [`&.${transitions.keys.start}`]: {
    transform: "translate3d(100%, 0, 0)",
  },

  [`&.${transitions.keys.end}`]: {
    transform: "none",
  },
});
