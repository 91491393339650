import { useMemo, useState } from "react";
import useStorageState from "react-use-storage-state";

import {
  AddonProviderStateController,
  ChargerDecision,
  ChargerInstallDecision,
  ChargerInstallOption,
  ChargerOption,
  GapDecision,
  GapOption,
  WarrantyDecision,
  WarrantyOption,
} from "#components/provider/AddonProvider/AddonTypes";
import safelyParseJSON from "#components/util/safelyParseJson";

export const useAddonsProviderStateController =
  (): AddonProviderStateController => {
    // Persisted Data

    const [gap, setGap] = useStorageState<GapOption | undefined>(
      "addOnProviderGap",
      safelyParseJSON<GapOption>(
        sessionStorage.getItem("addOnProviderGap") || "",
      ) || undefined,
      sessionStorage,
    );

    const [selectedGap, setSelectedGap] = useStorageState<
      GapDecision | undefined
    >(
      "addOnProviderSelectedGap",
      safelyParseJSON<GapDecision | undefined>(
        sessionStorage.getItem("addOnProviderSelectedGap") || "",
      ) || undefined,
      sessionStorage,
    );

    const [evppPlan, setEvppPlan] = useStorageState<WarrantyOption | undefined>(
      "addOnProviderEvpp",
      safelyParseJSON<WarrantyOption | undefined>(
        sessionStorage.getItem("addOnProviderEvpp") || "",
      ) || undefined,
      sessionStorage,
    );

    const [selectedEvpp, setSelectedEvpp] = useStorageState<
      WarrantyDecision | undefined
    >(
      "addOnProviderSelectedEvpp",
      safelyParseJSON<WarrantyDecision | undefined>(
        sessionStorage.getItem("addOnProviderSelectedEvpp") || "",
      ) || undefined,
      sessionStorage,
    );

    const [chargerInstall, setChargerInstall] = useStorageState<
      ChargerInstallOption | undefined
    >(
      "addOnProviderChargerInstall",
      safelyParseJSON<ChargerInstallOption | undefined>(
        sessionStorage.getItem("addOnProviderChargerInstall") || "",
      ) || undefined,
      sessionStorage,
    );

    const [selectedChargerInstall, setSelectedChargerInstall] = useStorageState<
      ChargerInstallDecision | undefined
    >(
      "addOnProviderSelectedChargerInstall",
      safelyParseJSON<ChargerInstallDecision | undefined>(
        sessionStorage.getItem("addOnProviderSelectedChargerInstall") || "",
      ) || undefined,
      sessionStorage,
    );

    const [charger, setCharger] = useStorageState<ChargerDecision | undefined>(
      "addOnProviderCharger",
      safelyParseJSON<ChargerDecision | undefined>(
        sessionStorage.getItem("addOnProviderCharger") || "",
      ) || undefined,
      sessionStorage,
    );

    const [toggledCharger, setToggledCharger] = useStorageState<
      ChargerOption | undefined
    >(
      "addOnProviderToggledCharger",
      safelyParseJSON<ChargerOption | undefined>(
        sessionStorage.getItem("addOnProviderToggledCharger") || "",
      ) || undefined,
      sessionStorage,
    );

    const [addonError, setAddonError] = useState<string | undefined>();

    // loading states

    const [initialized] = useState(true);

    const [gapLoading, setGapLoading] = useStorageState<boolean>(
      "addonProviderGapLoading",
      safelyParseJSON<boolean>(
        sessionStorage.getItem("addonProviderGapLoading") || "",
      ) || false,
      sessionStorage,
    );

    const [evppLoading, setEvppLoading] = useStorageState<boolean>(
      "addonProviderEvppLoading",
      safelyParseJSON<boolean>(
        sessionStorage.getItem("addonProviderEvppLoading") || "",
      ) || false,
      sessionStorage,
    );

    const [chargerInstallLoading, setChargerInstallLoading] =
      useStorageState<boolean>(
        "addonProviderChargerInstallLoading",
        safelyParseJSON<boolean>(
          sessionStorage.getItem("addonProviderChargerInstallLoading") || "",
        ) || false,
        sessionStorage,
      );

    const [chargerLoading, setChargerLoading] = useStorageState<boolean>(
      "addonProviderChargerLoading",
      false,
      sessionStorage,
    );

    const [addonsLoading, setAddonsLoading] = useStorageState<boolean>(
      "addonProviderLoading",
      safelyParseJSON<boolean>(
        sessionStorage.getItem("addonProviderLoading") || "",
      ) || false,
      sessionStorage,
    );

    const loading = useMemo(() => {
      return (
        addonsLoading ||
        gapLoading ||
        evppLoading ||
        chargerInstallLoading ||
        chargerLoading
      );
    }, [
      addonsLoading,
      gapLoading,
      evppLoading,
      chargerInstallLoading,
      chargerLoading,
    ]);

    return {
      state: {
        addonError,
        gap,
        selectedGap,
        evppPlan,
        selectedEvpp,
        chargerInstall,
        selectedChargerInstall,
        charger,
        toggledCharger,
        initialized,
      },
      set: {
        setAddonError,
        setGap,
        setSelectedGap,
        setEvppPlan,
        setSelectedEvpp,
        setChargerInstall,
        setSelectedChargerInstall,
        setToggledCharger,
        setCharger,
      },
      // Network State
      setLoading: {
        setAddonsLoading,
        setGapLoading,
        setEvppLoading,
        setChargerInstallLoading,
        setChargerLoading,
      },
      loading: {
        addonsLoading,
        gapLoading,
        evppLoading,
        chargerInstallLoading,
        chargerLoading,
        all: loading,
      },
    };
  };
