/** ******************************************************************************
Verify User With Code 
 * uses the /user/verify endpoint to verify users email or phone
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";
import { VerificationType } from "#v2-components/molecules/VerificationModal/VerificationModal.types";

import { HTTPResendCode, LOSAPIResponse } from "./client.types";

/**
 * Verifies a user's email address change
 * @param  verificationType of code to be resent
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const resendCode: (
  verificationType: VerificationType,
) => LOSAPIResponse<HTTPResendCode> = async (verificationType) => {
  const response = await makeRequest<HTTPResendCode>(
    HTTPMethod.PUT,
    restPaths.user.resend,
    {
      body: { verificationType },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default resendCode;
