/** ******************************************************************************
Post Dealership Info
 * uses the /applications/${applicationId}/dealerInfo/create endpoint to create dealership info
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { DealershipInfo } from "#components/partial/DealershipForm/DealershipForm.types";
import { restPaths } from "#constants/restPaths";

import { LOSAPIResponse } from "./client.types";

/**
 * Confirms  loan offer for the current application
 * @param applicationId loan application ID for the current application (string)
 * @param  dealershipInfo all the dealership info
 *  * @returns an object containing response and error properties (HTTPResponse | null)
 */
const postDealershipInfo: (
  applicationId: string,
  dealershipInfo: DealershipInfo,
  // Fix typing here at some point
) => LOSAPIResponse<DealershipInfo> = async (applicationId, dealershipInfo) => {
  const response = await makeRequest<DealershipInfo>(
    HTTPMethod.POST,
    restPaths.application(applicationId).dealershipInfo.create,
    {
      body: dealershipInfo,
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default postDealershipInfo;
