/** ******************************************************************************
Submit One Time Payment
 * sends a payment
 ******************************************************************************* */

import { HTTPMethod, HTTPResponse } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPSubmitOneTimePayment } from "./client.types";

const sendOneTimePayment: (
  loanId: string,
  amountCents: number,
  paymentMethodId: string,
  isPrincipalPayment: boolean,
) => Promise<{
  response: null | HTTPResponse<HTTPSubmitOneTimePayment>;
  error: null | HTTPResponse<HTTPSubmitOneTimePayment>;
}> = async (loanId, amountCents, paymentMethodId, isPrincipalPayment) => {
  const response = await makeRequest<HTTPSubmitOneTimePayment>(
    HTTPMethod.POST,
    restPaths.payments(loanId).oneTimePayment,
    {
      body: {
        amountCents,
        paymentMethodId,
        isPrincipalPayment,
      },
    },
    true,
    "lms",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default sendOneTimePayment;
