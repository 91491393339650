/** ******************************************************************************
Update User Phone Number
 * uses the /pr (update user) endpoint to update a user's phone number
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPUpdateUserRes, LOSAPIResponse } from "./client.types";

/**
 * Updates/sets a user's phone number
 * @param phone phone number to update to (string, format is 19995559999)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const updatePhone: (params: {
  appId?: string;
  phone: string;
}) => LOSAPIResponse<HTTPUpdateUserRes> = async (params) => {
  const response = await makeRequest<HTTPUpdateUserRes>(
    HTTPMethod.PUT,
    restPaths.user.updatePhone,
    {
      body: { appId: params.appId, phoneNumber: params.phone },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default updatePhone;
