import React, { createContext, useContext, useState } from "react";

const DataContext = createContext<boolean>(false);
const UpdateContext = createContext<((ready: boolean) => void) | null>(null);

export const ReadyProvider = ({ children }: { children: React.ReactNode }) => {
  const [offerLimits, setOfferLimits] = useState<boolean>(false);
  return (
    <DataContext.Provider value={offerLimits}>
      <UpdateContext.Provider value={setOfferLimits}>
        {children}
      </UpdateContext.Provider>
    </DataContext.Provider>
  );
};

export const useReadyContext = () => {
  return {
    data: useContext(DataContext),
    update: useContext(UpdateContext),
  };
};
