/**
 * These are global keys used for page metadata, to allow them to be overridden
 * by lower components. Please add to this list if you need to add a global tag
 * of any kind, so we can track them in one location.
 */
export enum meta {
  W3_TITLE = "W3_TITLE" /* W3 being used as shorthand for standard HTML meta tags */,
  OG_TITLE = "OG_TITLE" /* OG = Facebook Open Graph */,
  TC_TITLE = "TC_TITLE" /* TC = Twitter Card */,
  W3_DESCRIPTION = "W3_DESCRIPTION",
  OG_DESCRIPTION = "OG_DESCRIPTION",
  TC_DESCRIPTION = "TC_DESCRIPTION",
  OG_IMAGE = "OG_IMAGE",
  OG_IMAGE_WIDTH = "OG_IMAGE_WIDTH",
  OG_IMAGE_HEIGHT = "OG_IMAGE_HEIGHT",
  OG_IMAGE_MIME = "OG_IMAGE_MIME",
  OG_IMAGE_FALLBACK = "OG_IMAGE_FALLBACK",
  OG_IMAGE_WIDTH_FALLBACK = "OG_IMAGE_WIDTH_FALLBACK",
  OG_IMAGE_HEIGHT_FALLBACK = "OG_IMAGE_HEIGHT_FALLBACK",
  OG_IMAGE_MIME_FALLBACK = "OG_IMAGE_MIME_FALLBACK",
  TC_IMAGE = "TC_IMAGE",
  OG_TYPE = "OG_TYPE",
  TC_CARD = "TC_CARD",
  SO_STRUCTURED_ARTICLE_DATA = "SO_STRUCTURED_ARTICLE_DATA" /* SO = Schema.org */,
  SO_STRUCTURED_ORG_DATA = "SO_STRUCTURED_ORG_DATA",
  SO_STRUCTURED_REVIEW_DATA = "SO_STRUCTURED_REVIEW_DATA",
}
