/** ******************************************************************************
Add Email to Wait List
 * uses the /states/wait-list endpoint to add an email to the wait list
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPStatesWaitingListRes, LOSAPIResponse } from "./client.types";

/**
 * Adds an email to the supported states waiting list
 * @param email email to add to wait list (string)
 * @param stateAbbreviation abbreviation of the state that is not currently supported (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const addEmailToWaitList: (
  email: string,
  stateAbbreviation: string,
) => LOSAPIResponse<HTTPStatesWaitingListRes> = async (
  email,
  stateAbbreviation,
) => {
  const response = await makeRequest<HTTPStatesWaitingListRes>(
    HTTPMethod.PUT,
    restPaths.states.waitList,
    {
      body: {
        email,
        stateAbbreviation,
      },
    },
    false,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default addEmailToWaitList;
