import dynamic from "next/dynamic";

import TenetLogo from "./logo.json";
import TenetLogoGrey from "./logo-grey.json";

const Lottie = dynamic(() => import("lottie-react"), { ssr: false });

export enum LottieVariant {
  BLACK = "BLACK",
  GREY = "GREY",
}

const LottieLogo = ({
  height,
  width,
  variant = LottieVariant.BLACK,
}: {
  height: number;
  width: number;
  variant?: string;
}) => {
  switch (variant) {
    case LottieVariant.BLACK:
      return (
        <Lottie
          animationData={TenetLogo}
          loop
          autoplay
          style={{ height, width }}
        />
      );
    case LottieVariant.GREY:
      return (
        <Lottie
          animationData={TenetLogoGrey}
          loop
          autoplay
          style={{ height, width }}
        />
      );
    default:
      return (
        <Lottie
          animationData={TenetLogo}
          loop
          autoplay
          style={{ height, width }}
        />
      );
  }
};

export default LottieLogo;
