import { useEffect, useRef } from "react";

/**
 * This hook is used for cases where we need to determine the previous value of
 * a prop, state, or other variable that may change across component updates.
 */
function usePrevious<T = unknown>(value: T) {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current as T;
}

export default usePrevious;
