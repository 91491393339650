/** ******************************************************************************
Update SSN
 * uses the /user/ssn (update user) endpoint to update a user's Social Security Number
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPUpdateUserRes, LOSAPIResponse } from "./client.types";

/**
 * Updates/sets a user's social security number
 * @param ssn social security number to update to (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */

const updateSsn: (
  ssn: string,
  appId: string,
) => LOSAPIResponse<HTTPUpdateUserRes> = async (ssn, appId) => {
  const response = await makeRequest<HTTPUpdateUserRes>(
    HTTPMethod.PUT,
    restPaths.user.updateSsn,
    {
      body: { ssn, appId },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};
export default updateSsn;
