import noop from "lodash/noop";

const getStorageObjects = () => {
  if (typeof window === "undefined") {
    return {
      sessionStorage: {
        setItem: noop,
        getItem: noop,
        clear: noop,
      } as typeof sessionStorage,
      localStorage: {
        setItem: noop,
        getItem: noop,
        clear: noop,
      } as typeof localStorage,
    };
  }
  return {
    sessionStorage: window.sessionStorage,
    localStorage: window.localStorage,
  };
};

const globals = getStorageObjects();

export default globals;
