/** ******************************************************************************
Set Uploaded Status
 * Sends the loan pro customer document id to inform the BE it has been uploaded
 ******************************************************************************* */
// import { getAuth } from "#auth/utils";
import { HTTPMethod, HTTPResponse } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import CauseError from "#components/util/CauseError";
import { restPaths } from "#constants/restPaths";

/**
 * Hits the Tenet API to get an upload link from LoanPro
 * @param loanApplicationId
 * @param loanUserDocument the document id. I dont know why this was named this way
 * @param imageData basic file information needed to provision a link
 * @returns a link to upload a document, and a "key" that is just path in Loan
 * Pro's S3 that will be [loanApplicationId]/[filename]
 */
const updateUploaded: (
  loanApplicationId: string,
  loanUserDocumentId: string,
  loanProDocId: string,
) => Promise<[null | string, null | HTTPResponse<string>]> = async (
  loanApplicationId,
  loanUserDocumentId,
  loanProDocId,
) => {
  try {
    const response = await makeRequest<string>(
      HTTPMethod.POST,
      restPaths.document(loanApplicationId, loanUserDocumentId).updateUploaded,
      {
        body: {
          id: loanProDocId,
        },
      },
      true,
    );
    if (response.error) {
      return [null, response];
    }
    return ["ok", null];
  } catch (e) {
    throw new CauseError("Unexpected error when updating document status", {
      cause: e as Error,
    });
  }
};

export default updateUploaded;
