export enum LoanType {
  Purchase = "Purchase",
  Refinance = "Refinance",
}

export enum LoanClassV2 {
  Commercial = "CommercialHybrid",
  CommercialHybrid = "CommercialHybrid",
  Individual = "Individual",
}

export enum HostingPlatform {
  Getaround = "Getaround",
  Turo = "Turo",
}

export enum VehicleUsage {
  Personal = "Personal",
  Carsharing = "Carsharing",
  Ridesharing = "Ridesharing",
  Fleet = "Fleet",
}

export enum StepEnum {
  LOAN_TYPE = "LOAN_TYPE",
  LOAN_CLASS = "LOAN_CLASS",
  USE = "USE",
  HOSTING_ELIGIBILITY = "HOSTING_ELIGIBILITY",
  BUSINESS_ELIGIBILITY = "BUSINESS_ELIGIBILITY",
  INELIGIBLE = "INELIGIBLE",
  PLATFORM = "PLATFORM",
}
