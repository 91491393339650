import LOSClient from "#api.los/client";
import {
  CreateApplicationDraftParams,
  CreateApplicationParams,
  UpdateTermsParams,
} from "#components/provider/ApplicationProvider/ApplicationTypes";

const Network = {
  createApplication: (data: CreateApplicationParams) => {
    return LOSClient.application.createApplication(data);
  },
  createDraft: (data: CreateApplicationDraftParams) => {
    return LOSClient.application.createDraft(data);
  },
  get: (applicationId: string) => {
    return LOSClient.application.get(applicationId);
  },
  getAll: () => {
    return LOSClient.application.getAll();
  },
  cancel: (applicationId: string) => {
    return LOSClient.application.cancelApplication(applicationId);
  },
  updateTerms: (data: UpdateTermsParams) => {
    return LOSClient.application.updateTerms(data);
  },
};

export { Network };
