import noop from "lodash/noop";
import { createContext, useContext, useEffect, useMemo, useState } from "react";

import { useInitialPageInfo } from "#components/provider/InitialPageInfo";
import safelyParseJSON from "#components/util/safelyParseJson";
import {
  assignABucket,
  COOKIE_FALLBACK_KEY,
  getCookieExperiments,
  registerExperiment,
  useExperimentEvents,
  useExperiments,
} from "#services/ExperimentService/ExperimentService";

type Experiments = ReturnType<typeof useExperiments>;
type Track = ReturnType<typeof useExperimentEvents>;
type ExperimentContextType = {
  experiments: Experiments;
  track: Track;
};

export const ExperimentContext = createContext<ExperimentContextType>({
  experiments: {},
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  track: noop,
});

interface ExperimentProviderProps {
  children: React.ReactNode;
}

export const ExperimentProvider = ({ children }: ExperimentProviderProps) => {
  const experiments = useExperiments();
  const track = useExperimentEvents();
  const { loading } = useInitialPageInfo();
  const [singleLoadExperiments, setSingleLoadExperiments] = useState<
    Record<string, string>
  >(
    (localStorage.getItem(COOKIE_FALLBACK_KEY)
      ? safelyParseJSON(localStorage.getItem(COOKIE_FALLBACK_KEY) as string)
      : {}) as Record<string, string>,
  );
  const [cookieRefresh, setCookieRefresh] = useState(0);
  const [pendingTracks, setPendingTracks] = useState<
    Parameters<typeof track>[]
  >([]);

  const cookieExperiments = useMemo(() => {
    return getCookieExperiments();
  }, [loading, cookieRefresh, experiments]);

  const context = useMemo(() => {
    /* MPR, 2023/08/14: Whats _this_ now, you ask? Every time we access an experiment, check if
     * we have the cookie. If not, send up the registered bucket to the backend,  which
     * will set the cookie. This is a little roundabout but extremely convienient, so
     * I can live with the side effect, since while it is unusual, because we are doing
     * this in this file, the behavior should be easy to track back to here.
     *
     * If you're debugging how experiments go from the FE to the BE initially: this is it:
     */
    const experimentInterceptUpdateProxy = new Proxy(experiments, {
      get: (target, prop, receiver) => {
        // we have not recieved this experimet from the backend before. Set it.
        if (cookieExperiments[prop] == null && !loading) {
          // this check is a formality - it could technically be a symbol, but we'll error before this if it is.
          if (typeof prop === "string") {
            const previousBucket = sessionStorage.getItem(`temp-xp-${prop}`);
            if (previousBucket) {
              // we're in the middle of a request
              return previousBucket;
            }
            if (singleLoadExperiments[prop]) {
              // this state should only occur if cookies are disabled entirely. In that case, we fall back
              // to whatever the very first value of this experiment the browser ever saw via local storage.
              // This prevents repeatedly trying to load the experiment. (retry cookie set regardless)
              document.cookie = `xp-${prop}=${singleLoadExperiments[prop]}`;
              return singleLoadExperiments[prop];
            }

            const bucket = assignABucket(prop);
            // we need to use session storage very briefly so that if we read the experiment again before the BE
            // returns, we don't set the bucket again
            sessionStorage.setItem(`temp-xp-${prop}`, bucket.bucketName);
            // similarly, for local or other situations in which our cookie will not be immediately
            // set, just set an insecure version here. This will be overwritten on response from server
            document.cookie = `xp-${prop}=${bucket.bucketName}`;
            registerExperiment(prop, bucket.bucketName).finally(() => {
              // clean up once the BE comes back. This is unneccissary for anything
              // other than tidyness, since it will clear after the session and we
              // want the bucket to persist anyway. Still, better to be certain
              // about the source of truth.
              sessionStorage.removeItem(`temp-xp-${prop}`);
              setCookieRefresh(cookieRefresh + 1);
              const nextFallbackSingleLoad = {
                ...singleLoadExperiments,
                [prop]: bucket.bucketName,
              };
              localStorage.setItem(
                COOKIE_FALLBACK_KEY,
                JSON.stringify(nextFallbackSingleLoad),
              );
              setSingleLoadExperiments(nextFallbackSingleLoad);
            });
            return bucket.bucketName;
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return Reflect.get(target, prop, receiver);
      },
    });
    return {
      experiments: experimentInterceptUpdateProxy,
      track: async (...args: Parameters<typeof track>) => {
        if (loading) {
          // if init has not returned, we do not have experiments yet. Wait until the user has actually
          // seen the page before sending the track. This logic can be removed once experiments are instant. (returned on html)
          setPendingTracks([...pendingTracks, args]);
          return true;
        }
        return track(...args);
      },
    };
  }, [experiments, track]);

  useEffect(() => {
    if (!loading && pendingTracks.length > 0) {
      pendingTracks.forEach((args) => {
        track(...args);
      });
      setPendingTracks([]);
    }
  }, [loading, pendingTracks, track]);

  return (
    <ExperimentContext.Provider value={context}>
      {children}
    </ExperimentContext.Provider>
  );
};

export const useExperimentContext = () => useContext(ExperimentContext);
