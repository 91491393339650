import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPCancelApplicationRes, LOSAPIResponse } from "./client.types";

/**
 * @param applicationId application Id to specify for cancellatio.
 * @returns nothing, should just cancel application.
 */
const cancelApplication: (
  applicationId: string,
) => LOSAPIResponse<HTTPCancelApplicationRes> = async (applicationId) => {
  const response = await makeRequest<HTTPCancelApplicationRes>(
    HTTPMethod.PUT,
    restPaths.application(applicationId).cancelApplication.put,
    { body: { cancelApplication: true } },
    true,
  );

  if (!response.error) {
    return { response, error: null };
  }

  return { response: null, error: response };
};

export default cancelApplication;
