export const getIntercomSnippet = (): string =>
  `
(function(){
  if(!~window.location.href.indexOf('tenet.com') && !~window.location.href.indexOf('nonprodtenet.com'))
   return; 
  var w=window;
  var ic=w.Intercom;
  if(typeof ic==="function"){
    ic('reattach_activator');
    ic('update',w.intercomSettings);
  }else{
    var d=document;
    var i=function(){
      i.c(arguments);
    };
    i.q=[];
    i.c=function(args){
      i.q.push(args);
    };
    w.Intercom=i;
    var l=function(){
      var s=d.createElement('script');
      s.type='text/javascript';
      s.async=true;
      s.src='https://widget.intercom.io/widget/icxn6an9';
      var x=d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s,x);
    };
    if(document.readyState==='complete'){
      l();
    }else if(w.attachEvent){
      w.attachEvent('onload',l);
    }else{
      w.addEventListener('load',l,false);
    }}})();
        `;

export const getGoogleTagSnippet = (): string =>
  `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-LWHWWVZJ6Z');
`;
