/** ******************************************************************************
Initialize Oauth User
 * OAuth users will have a corresponding firebase user, but not one in our system
 * because logging in will not fire the same hook that our firebase register does
 * However, because we are correctly signed in, we can make authenticated calls
 * without needing to explicitly pass our firebase ID, and thus can safely create
 * the user in our system.
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";
import ReferralService from "#services/ReferralService";

import { HTTPUserGet, LOSAPIResponse } from "./client.types";

const initUser: (
  email: string,
  firstName?: string,
  lastName?: string,
  phone?: string,
) => LOSAPIResponse<HTTPUserGet> = async (
  email,
  firstName,
  lastName,
  phone,
) => {
  const referralCode = ReferralService.getReferralCode();

  const response = await makeRequest<HTTPUserGet>(
    HTTPMethod.PUT,
    restPaths.user.init,
    {
      body: { email, firstName, lastName, phone, referralCode },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default initUser;
