const REFERRAL_CODE_KEY = "tenet-custom-referral-code";

const storeReferralCode = (code: string) => {
  if (typeof window !== "undefined") {
    window.localStorage.setItem(REFERRAL_CODE_KEY, code);
  }
};

const getReferralCode = () => {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(REFERRAL_CODE_KEY);
  }
  return "";
};

const ReferralService = { storeReferralCode, getReferralCode };

export default ReferralService;
