/** ******************************************************************************
Get Dealership Info
 * uses the /applications/${applicationId}/dealerInfo/get endpoint to get dealership info
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPDealershipInfoResponse, LOSAPIResponse } from "./client.types";

/**
 * Fetches dealership info
 * @param applicationId loan application ID for the current application (string)
 *  * @returns an object containing response and error properties (HTTPResponse | null)
 */

const getDealershipInfo: (
  applicationId: string,
) => LOSAPIResponse<HTTPDealershipInfoResponse> = async (applicationId) => {
  const response = await makeRequest<HTTPDealershipInfoResponse>(
    HTTPMethod.GET,
    restPaths.application(applicationId).dealershipInfo.get,
    {},
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getDealershipInfo;
