/** ******************************************************************************
Update User Address
 * uses the /user/address/:userAddressId endpoint to update a user's address details
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPUpdateUserAddressRes, LOSAPIResponse } from "./client.types";

/**
 * Updates an existing userAddress for the user
 * @param city city of updated address
 * @param county county, TODO drop this from the DB, do we need this for anything?
 * @param stateCode two letter abbreviation for State of address
 * @param postalCode postal code / zip code
 * @param line1 line 1 of address, street name
 * @param line2 line 2, apartment / unit number
 * @param moveInDate move in date to this address, required for all new addresses
 * @param moveOutDate move out date for this address, undefined for current address
 * @returns userAddress
 */
const updateAddressDetails: (
  userAddressId: string,
  city: string,
  stateCode: string,
  postalCode: string,
  line1: string,
  line2: string,
  moveInDate: Date,
  moveOutDate?: Date,
) => LOSAPIResponse<HTTPUpdateUserAddressRes> = async (
  userAddressId,
  city,
  stateCode,
  postalCode,
  line1,
  line2,
  moveInDate,
  moveOutDate,
) => {
  const response = await makeRequest<HTTPUpdateUserAddressRes>(
    HTTPMethod.PUT,
    restPaths.user.updateAddressDetails(userAddressId),
    {
      body: {
        city,
        stateCode,
        postalCode,
        line1,
        line2,
        moveInDate,
        moveOutDate,
      },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default updateAddressDetails;
