/** ******************************************************************************
Update Applicant First Name, Last Name, and Date of Birth
 * uses the applications/:applicationId/:applicantId/dfl (update applicant) endpoint to update
 * an applicant's First Name, Last Name, and Date of Birth, as well as the associated user's name and DOB
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPApplicantRes, LOSAPIResponse } from "./client.types";

/**
 * Updates/sets an applicant's first name, last name, and date of birth
 * @param firstName first name to update to (string)
 * @param lastName last name to update to (string)
 * @param dateOfBirth date of birth to update to (this is of type Date NOT string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const updateApplicantNameAndDob: (
  applicationId: string,
  applicantId: string,
  firstName: string,
  lastName: string,
  dateOfBirth: Date,
  attribution?: string,
  attributionText?: string,
) => LOSAPIResponse<HTTPApplicantRes> = async (
  applicationId,
  applicantId,
  firstName,
  lastName,
  dateOfBirth,
  attribution,
  attributionText,
) => {
  const response = await makeRequest<HTTPApplicantRes>(
    HTTPMethod.PUT,
    restPaths.application(applicationId).updateApplicantNameAndDOB(applicantId),
    {
      body: { firstName, lastName, dateOfBirth, attribution, attributionText },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};
export default updateApplicantNameAndDob;
