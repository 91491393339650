import { css } from "#themes";

export const accounts = css({
  backgroundColor: "$backgroundSecondary",
  borderRadius: "10px",
  width: "100%",
  paddingX: "20px",
});

export const account = css({
  height: "100px",
  alignItems: "center",
  display: "flex",
  position: "relative",

  hr: {
    position: "absolute",
    bottom: "0",
    opacity: 0.1,
  },
});
