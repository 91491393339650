import DocumentDuplicateIcon from "@heroicons/react/24/outline/DocumentDuplicateIcon";
import { toast } from "react-toastify";

import s from "./ReferralLink.module.scss";
import { GetReferralLink } from "./util";

const ReferralLink = ({
  code,
  onCopy,
}: {
  code: string;
  onCopy?: () => void;
}) => {
  const referralLink = GetReferralLink(code);
  return (
    <div className={s.linkBox}>
      <div>{referralLink}</div>{" "}
      <DocumentDuplicateIcon
        width="24"
        onClick={() => {
          navigator.clipboard.writeText(referralLink);
          toast.success("Referral link copied!");
          if (onCopy) {
            onCopy();
          }
        }}
      />
    </div>
  );
};

export default ReferralLink;
