/** ******************************************************************************
Update Unassociated Vehicle
 * uses the /drafts/vehicle endpoint to update an unassociated vehicle 
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPUpdateVehicleRes, LOSAPIResponse } from "./client.types";

/**
 * Updates an unassociated vehicle
 * @param vehicleId vehicle ID for the unassociated vehicle (string)
 * @param vehicle vehicle properties that are to be updated.
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const updateUnassociatedVehicle: (
  vehicleId: string,
  vehicle: {
    mileage?: number;
    trim?: string;
  },
) => LOSAPIResponse<HTTPUpdateVehicleRes> = async (
  vehicleId,
  { mileage, trim },
) => {
  const response = await makeRequest<HTTPUpdateVehicleRes>(
    HTTPMethod.PUT,
    restPaths.application().unassociatedVehicle,
    {
      body: {
        id: vehicleId,
        mileage,
        trim,
      },
    },
    false,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};
export default updateUnassociatedVehicle;
