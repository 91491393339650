import { Transition as HeadlessTransition } from "@headlessui/react";
import React, { Fragment } from "react";

import { transitions } from "#constants";

import { Props } from "./Transition.types";

/**
 * The Transition component lets you add enter/leave transitions to
 * conditionally rendered elements, using CSS classes to control the actual
 * transition styles in the different stages of the transition.
 *
 * The component is a simple wrapper for the `Transition` component from the
 * Headless UI library, with preset values for the various "enter" and "leave"
 * classnames. These classnames can be referenced when creating your own
 * transition styles by importing these transition keys from "#constants".
 *
 * By default, `Transition` only works with a single child. If multiple children
 * are to be controlled by a single `Transition` component, you must nest each
 * of them in an individual `Transition.Child` component first.
 *
 * See:
 * - https://headlessui.dev/react/transition
 */
const Transition = (props: Props) => (
  <HeadlessTransition
    as={Fragment}
    enter={`${transitions.keys.transition} ${transitions.keys.enter}`}
    enterFrom={`${transitions.keys.start} ${transitions.keys.enterStart}`}
    enterTo={`${transitions.keys.end} ${transitions.keys.enterEnd}`}
    leave={`${transitions.keys.transition} ${transitions.keys.leave}`}
    leaveFrom={`${transitions.keys.end} ${transitions.keys.leaveStart}`}
    leaveTo={`${transitions.keys.start} ${transitions.keys.leaveEnd}`}
    {...props}
  />
);

const Child = (props: Props) => (
  <HeadlessTransition.Child
    as={Fragment}
    enter={`${transitions.keys.transition} ${transitions.keys.enter}`}
    enterFrom={`${transitions.keys.start} ${transitions.keys.enterStart}`}
    enterTo={`${transitions.keys.end} ${transitions.keys.enterEnd}`}
    leave={`${transitions.keys.transition} ${transitions.keys.leave}`}
    leaveFrom={`${transitions.keys.end} ${transitions.keys.leaveStart}`}
    leaveTo={`${transitions.keys.start} ${transitions.keys.leaveEnd}`}
    {...props}
  />
);

Transition.Child = Child;

export default Transition;
