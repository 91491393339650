/** ******************************************************************************
Import Plaid Accounts
 * uses the plaid client to import account information
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPGetPaymentMethodsResponse, LMSAPIResponse } from "./client.types";

/**
 * Imports account information via the plaid client
 * @param accountIds an array of account Ids
 * @param publicToken a plaid public token
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const verifyPaymentMethod: (
  paymentMethodId: string,
  accessToken: string,
  providerAccountId: string,
) => LMSAPIResponse<HTTPGetPaymentMethodsResponse> = async (
  paymentMethodId,
  accessToken,
  providerAccountId,
) => {
  const response = await makeRequest<HTTPGetPaymentMethodsResponse>(
    HTTPMethod.PUT,
    restPaths.paymentMethods().verifyPaymentMethod,
    {
      body: {
        paymentMethodId,
        accessToken,
        providerAccountId,
      },
    },
    true,
    "lms",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default verifyPaymentMethod;
