const black = "#222222";
const blackDark = "#131313";
const grey = "#BABABA";
const greyDark = "#878787";
const greyLight = "#D9D9D9";
const greyLightest = "#EBEBEB";

// TODO: These are used in some tables/charts. Enable these
// and add a "data" pallette range to app when needed.
// const blue = "#6FBAD7";
const blueDark = "#007FB0";
// const blueLight = "#DBF4FD";
const electricBlue = "#23A0C8";

const tealLight = "#B1E3F5";
// const teal = "#4FAED2"; // This was replaced by electricBlue, don't use anymore.
const tealDark = "#33677A";
const tealGrey = "#9AAEB6";

const charcoal70 = "#545454";
const cyan = "#DBF4FD";
const green = "#44B384";
const lavender = "#DCE0FC";
const pink = "#EE8CD7";
const pinkDark = "#BF47A3";
const pinkLight = "#FCE8F7";
const purple = "#8E36EC";
const purpleDark = "#8E36EC";
const purpleLight = "#E8D2FF";
const red = "#E0464F";
const white = "#FFFFFF";
const yellow = "#FFB257";
const lilac = "#5C4AEA";
const neutralCharcoal200 = "#E6E6E8";

const colors = {
  // Colors used as screen backgrounds.
  backgroundInverse: black,
  backgroundPrimary: white,
  backgroundSecondary: greyLightest,
  backgroundFooter: blackDark,

  // Colors related to brand identity, used throughout the app.
  brandAccentPrimary: lavender,
  brandAccentSecondary: cyan,
  brandPrimary: purple,
  brandPrimaryDark: purpleDark,
  brandPrimaryLight: purpleLight,
  brandSecondary: pink,
  brandSecondaryDark: pinkDark,
  brandSecondaryLight: pinkLight,
  brandGradient:
    "linear-gradient(95.93deg, $brandAccentSecondary -42.94%, $brandPrimaryLight 104.81%)",

  // Colors used to indicate a status change in the UI
  // (eg. in form validation, errors, etc.)
  statusNegative: red,
  statusPositive: green,
  statusWarning: yellow,

  // Colors used for text & icon content.
  textInverse: white,
  textNegative: red,
  textPositive: green,
  textPrimary: black,
  textSecondary: greyDark,
  textHighlight: blueDark,
  textRecurrent: lilac,

  // Colors used broadly for many UI elements
  // (eg. in borders, card backgrounds, etc).
  uiInverse: white,
  uiOverlay: "rgba(0, 0, 0, 0.7)",
  uiPrimary: grey,
  uiSecondary: greyDark,
  uiSelected: purple,
  uiSubtle: greyLightest,
  uiTertiary: greyLight,

  // Neutral colors
  charcoal70,
  neutralCharcoal200,

  // Linear Graph Colors
  linearDataLight: tealLight,
  linearData: electricBlue,
  linearDataDark: tealDark,
  linearDataGrey: tealGrey,

  // CSS keywords.
  inherit: "inherit",
  transparent: "transparent",
};

export default colors;
