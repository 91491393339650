/** ******************************************************************************
Get Payment Methods
 * Gets a list of all configured ACH accounts for the current user
 ******************************************************************************* */

import { HTTPMethod, HTTPResponse } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPGetPaymentMethodsResponse } from "./client.types";

const getPaymentMethods: () => Promise<{
  response: null | HTTPResponse<HTTPGetPaymentMethodsResponse>;
  error: null | HTTPResponse<HTTPGetPaymentMethodsResponse>;
}> = async () => {
  const response = await makeRequest<HTTPGetPaymentMethodsResponse>(
    HTTPMethod.GET,
    restPaths.paymentMethods().getPaymentMethods,
    {},
    true,
    "lms",
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getPaymentMethods;
