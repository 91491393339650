import { ApplicationStatus } from "#api.los/losDto.types";
import { Network } from "#components/provider/ApplicationProvider/ApplicationProvider.network";
import {
  ApplicationProviderFunctions,
  ApplicationProviderStateController,
  CreateApplicationDraftParams,
  CreateApplicationParams,
  CreatedApplication,
  UpdateTermsParams,
} from "#components/provider/ApplicationProvider/ApplicationTypes";
import { getErrorMessage, trackedErrorAlert } from "#util/alerts/alerts";

/* eslint-disable no-console, consistent-return */
export const useApplicationProviderFunctions = (
  stateController: ApplicationProviderStateController,
): ApplicationProviderFunctions => {
  const { applicationId } = stateController.state;
  const { setApplicationLoading } = stateController.setLoading;

  const refreshApplication = async (): Promise<void> => {
    if (!applicationId) {
      // todo: get correct error message... maybe use error codes?
      const msg = "No active Application Id Set";
      stateController.set.setApplication(undefined);
      stateController.set.setApplicationError(msg);
      return;
    }
    setApplicationLoading(true);

    const resp = await Network.get(applicationId);

    if (resp.error) {
      console.error("3de538d9-5158-466e-9246-0559764e7cb9", resp.error);

      // todo: get correct error message... maybe use error codes?
      const msg = "Unable to Load Application";

      stateController.set.setApplicationError(msg);
      setApplicationLoading(false);

      return;
    }

    const app = resp.response?.body?.data;

    if (!app) {
      // todo: get correct error message... maybe use error codes?
      const msg = "Unable to Load Application";

      stateController.set.setApplicationError(msg);
      setApplicationLoading(false);

      return;
    }
    if (
      app.applicationStatus === ApplicationStatus.ACTIVE ||
      app.applicationStatus === ApplicationStatus.PASSTHROUGH
    ) {
      stateController.set.setApplication(app);
    } else {
      stateController.set.setRejectedApplication(app);
      stateController.set.setRejectedApplicationId(app.id);
    }
    clearError();
    setApplicationLoading(false);
  };

  const cancel = async (appId?: string): Promise<void> => {
    if (!applicationId && !appId) {
      // todo: get correct error message... maybe use error codes?
      const msg = "No Application Id Set";

      stateController.set.setApplicationError(msg);
      return;
    }
    setApplicationLoading(true);
    // one of the two are not undefined
    const _appId = (appId ?? applicationId)!;
    const resp = await Network.cancel(_appId);

    if (resp.error) {
      console.error("ec0b045a-4329-445a-b595-0f98ce68aa22", resp.error);

      // todo: get correct error message... maybe use error codes?
      const msg = "Unable to Delete Application";

      stateController.set.setApplicationError(msg);
      setApplicationLoading(false);

      return;
    }

    // clear application from session
    stateController.set.setApplication(undefined);
    stateController.set.setApplicationId(undefined);
    clearError();
    setApplicationLoading(false);
  };

  const createApplication = async (
    data: CreateApplicationParams,
  ): Promise<CreatedApplication | undefined> => {
    const resp = await Network.createApplication(data);

    if (resp.error || !resp.response?.body?.data) {
      console.error("53a6dd4f-2364-4b13-9ede-589af47ef250", resp.error);

      // todo: get correct error message... maybe use error codes?
      const msg = "Unable to Create Application";

      stateController.set.setApplicationError(msg);
      setApplicationLoading(false);

      trackedErrorAlert(
        getErrorMessage(resp.error),
        "CREATE_APPLICATION_ERROR",
        undefined,
        "Error creating application, please try again",
        true,
      );

      return;
    }

    const application = resp.response.body.data;

    stateController.set.setApplicationId(application.id);
    clearError();
    setApplicationLoading(false);

    return {
      id: resp.response.body.data.id,
      createdAt: new Date(resp.response.body.data.createdAt),
      status: resp.response.body.data.status,
      loanType: resp.response.body.data.loanType,
    };
  };
  const createDraft = async (
    data: CreateApplicationDraftParams,
  ): Promise<void> => {
    setApplicationLoading(true);
    const resp = await Network.createDraft(data);

    if (resp.error) {
      console.error("ea9cce80-8a6d-49e9-b7ab-f037ded87348", resp.error);

      // todo: get correct error message... maybe use error codes?
      const msg = "Unable to Create Application Draft";

      stateController.set.setApplicationError(msg);
      setApplicationLoading(false);
    }

    if (!resp.response?.body?.data) {
      // todo: get correct error message... maybe use error codes?
      const msg = "Unable to Create Application Draft";

      stateController.set.setApplicationError(msg);
      setApplicationLoading(false);

      return;
    }
    const app = resp.response.body.data;
    clearError();
    stateController.set.setApplicationId(app.id);
  };
  const getAllApplications = async (): Promise<void> => {
    setApplicationLoading(true);

    const resp = await Network.getAll();

    if (resp.error) {
      console.error("515afb3a-2ca1-46a1-b14a-3f9fede04645", resp.error);
      // todo: get correct error message... maybe use error codes?
      const msg = "Unable to Get All Applications";

      stateController.set.setApplicationError(msg);
      setApplicationLoading(false);
      return;
    }

    if (!resp.response?.body?.data) {
      // todo: get correct error message... maybe use error codes?
      const msg = "Unable to Get All Applications";

      stateController.set.setApplicationError(msg);
      setApplicationLoading(false);
      return;
    }

    const apps = resp.response.body.data;

    const app = apps.find(
      (a) => a.applicationStatus === ApplicationStatus.ACTIVE,
    );

    stateController.set.setApplicationId(app?.id);
    clearError();
    setApplicationLoading(false);
  };

  const updateTerms = async (data: UpdateTermsParams): Promise<void> => {
    stateController.set.setApplicationError(undefined);
    setApplicationLoading(true);

    const resp = await Network.updateTerms(data);

    if (resp.error) {
      console.error("6a64a002-1479-4077-8d7e-f2f9a368a9e3", resp.error);
      // todo: get correct error message... maybe use error codes?
      const msg = "Unable to Update Application Terms";

      stateController.set.setApplicationError(msg);
      setApplicationLoading(false);
      return;
    }

    if (!resp.response?.body?.data) {
      // todo: get correct error message... maybe use error codes?
      const msg = "Unable to Update Application Terms";

      stateController.set.setApplicationError(msg);
      setApplicationLoading(false);
      return;
    }
    const app = resp.response.body.data;

    stateController.set.setApplication(app);
    clearError();
    setApplicationLoading(false);
  };

  const setApplicationId = (appId?: string): void => {
    stateController.set.setApplicationId(appId);
  };

  const clearError = () => {
    if (stateController.state.applicationError) {
      stateController.set.setApplicationError(undefined);
    }
  };

  return {
    cancel,
    createApplication,
    createDraft,
    getAllApplications,
    setApplicationId,
    refreshApplication,
    updateTerms,
  };
};
