/** ******************************************************************************
Get Autopay
 * Gets an AutopayConfig and ApplicationRefAutopay join
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPGetAutopay, LOSAPIResponse } from "./client.types";

const getAutopay: (appId: string) => LOSAPIResponse<HTTPGetAutopay> = async (
  appId,
) => {
  const response = await makeRequest<HTTPGetAutopay>(
    HTTPMethod.GET,
    restPaths.application(appId).payments.getAutopay,
    {},
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getAutopay;
