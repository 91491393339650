/** ******************************************************************************
Get Active Loan Applicant ("LoanUser")
 * Retrieves the Applicant entity, which joins a "User" and a "LoanApplication"
 * hence "LoanUser" in DB
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { LOSAPIResponse } from "./client.types";

export enum InquiryStatus {
  PENDING = "PENDING",
  VERIFIED = "VERIFIED",
  FAILED = "FAILED",
}

export type IdInquiryDto = {
  id: string;
  extId: string;
  applicantRefId?: string;
  status: InquiryStatus;
};

interface HTTPVerifyIdentityRes {
  data: IdInquiryDto;
}

const verifyIdentity: ({
  applicationId,
  applicantId,
  inquiryId,
}: {
  applicationId: string;
  applicantId: string;
  inquiryId: string;
}) => LOSAPIResponse<HTTPVerifyIdentityRes> = async ({
  applicationId,
  applicantId,
  inquiryId,
}) => {
  const response = await makeRequest<HTTPVerifyIdentityRes>(
    HTTPMethod.POST,
    restPaths.application(applicationId).verifyIdentity(applicantId),
    { body: { extId: inquiryId } },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default verifyIdentity;
