import { useContext, useEffect, useMemo } from "react";
import { createRegisteredContext } from "react-singleton-context";

import { useApplicationProviderFunctions } from "#components/provider/ApplicationProvider/ApplicationProvider.function";
import { useApplicationProviderStateController } from "#components/provider/ApplicationProvider/ApplicationProvider.state";
import {
  ApplicationProviderFunctions,
  ApplicationProviderLoading,
  ApplicationProviderState,
  ApplicationProviderStateController,
  CreateApplicationDraftParams,
  CreateApplicationParams,
  UpdateTermsParams,
} from "#components/provider/ApplicationProvider/ApplicationTypes";

type ApplicationProviderContext = Omit<
  ApplicationProviderStateController,
  "set" | "setLoading"
> & {
  applicationFunctions: ApplicationProviderFunctions;
};

const defaultProvider: ApplicationProviderContext = {
  state: {
    initialized: false,
  },
  loading: {
    applicationLoading: false,
    all: false,
  },
  applicationFunctions: {
    /* eslint-disable */
    cancel: async () => undefined,
    createApplication: async (_data: CreateApplicationParams) => undefined,
    createDraft: async (data: CreateApplicationDraftParams) => undefined,
    getAllApplications: async () => undefined,
    refreshApplication: async () => undefined,
    setApplicationId: () => undefined,
    updateTerms: async (data: UpdateTermsParams) => undefined,
    /* eslint-enable */
  },
};

const ApplicationContext = createRegisteredContext<ApplicationProviderContext>(
  "application-provider-context",
  defaultProvider,
);

export const ApplicationProvider = ({
  children,
  activeStep,
  alwaysRefresh = false,
  applicationId,
}: {
  children: React.ReactNode;
  activeStep?: string;
  alwaysRefresh?: boolean;
  applicationId?: string;
}) => {
  const stateController = useApplicationProviderStateController();
  const applicationFunctions = useApplicationProviderFunctions(stateController);

  const value = useProviderInterface(
    stateController.state,
    applicationFunctions,
    stateController.loading,
  );

  useEffect(() => {
    if (activeStep) {
      // eslint-disable-next-line no-console
      console.log("ApplicationProvider instantiated: ", activeStep);
    }
  }, [activeStep]);

  useEffect(() => {
    if (
      applicationId &&
      applicationId !== "undefined" &&
      stateController.state.initialized
    ) {
      applicationFunctions.setApplicationId(applicationId);
    }
  }, [applicationId, stateController.state.initialized]);

  useEffect(() => {
    if (
      stateController.state.initialized &&
      stateController.state.applicationId &&
      (!stateController.state.application || alwaysRefresh)
    ) {
      // eslint-disable-next-line no-console
      console.log("refreshing Application");
      applicationFunctions.refreshApplication();
    }
  }, [stateController.state.initialized, stateController.state.applicationId]);

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
};

const useProviderInterface = (
  state: ApplicationProviderState,
  applicationFunctions: ApplicationProviderFunctions,
  loading: ApplicationProviderLoading,
): ApplicationProviderContext => {
  return useMemo(() => {
    return {
      state,
      loading,
      applicationFunctions,
    };
  }, [JSON.stringify(state.application), state.applicationId, loading.all]);
};

export const useApplicationContext = () => useContext(ApplicationContext);
