import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { PrelimGAPInsuranceDecisionInputDto } from "#components/provider/AddonProvider/AddonTypes";
import { restPaths } from "#constants/restPaths";

import { HTTPPostGAPBody, LOSAPIResponse } from "./client.types";

/**
 * Retrieves addons from an application
 * @param applicationId application to specify for GAP
 * @param payload payload for posting to GAP
 * @returns ok/error tuple. Value of tuple is the HTTP response details in either case
 */
const postPrelimGAP: (
  applicationId: string,
  payload: PrelimGAPInsuranceDecisionInputDto,
) => LOSAPIResponse<HTTPPostGAPBody> = async (
  applicationId: string,
  payload: PrelimGAPInsuranceDecisionInputDto,
) => {
  const response = await makeRequest<HTTPPostGAPBody>(
    HTTPMethod.POST,
    restPaths.application(applicationId).GAP.prelimPost,
    { body: payload },
    true,
  );

  if (!response.error) {
    return { response, error: null };
  }

  return { response: null, error: response };
};

export default postPrelimGAP;
