/** ******************************************************************************
Loan Management System (LMS) HTTP Client Wrapper
 * Package Root - import this!
 * Structures the LMS REST API client. Client is organized primarily by entity,
 * though there are a handful of top level functions like `isAuthenticated`
 ******************************************************************************* */

import type { Auth } from "firebase/auth";

import { getAuth } from "#auth/utils";
import { stack } from "#util/env";

import getLoans from "./loan.getAll";
import getDetails from "./loan.getDetails";
import getLoan from "./loan.getLoan";
import cancelAutopay from "./payments.cancelAutopay";
import createPaymentMethods from "./payments.createPaymentMethods";
import deletePaymentMethod from "./payments.deletePaymentMethod";
import editPaymentMethod from "./payments.editPaymentMethod";
import getPaymentAuthToken from "./payments.getPaymentAuthToken";
import getPaymentMethods from "./payments.getPaymentMethods";
import get from "./payments.getPayments";
import importPlaidAccounts from "./payments.importPlaidAccounts";
import sendOneTimePayment from "./payments.oneTimePayment";
import setupAutopay from "./payments.setupAutopay";
import updateAutopay from "./payments.updateAutopay";
import verifyPaymentMethod from "./payments.verifyPaymentMethod";
import downloadPayoffDocument from "./payoff.downloadDocument";
import getPayoffDocumentLink from "./payoff.getDocument";
import initPayoffDocument from "./payoff.initDocument";
import getReferralDetails from "./referrals.getDetails";

const authKey = Symbol("auth");
const tokenKey = Symbol("token");

interface APIClientCommon {
  [authKey]: undefined | Auth;
  [tokenKey]: undefined | string;
  isAuthenticated: () => Promise<boolean>;
  getToken: () => undefined | string;
  logOut: () => void;
}

/**
 * Structures the API client
 * Organized around entity, e.g. "loan", then specific action
 * e.g. "update email", so `client.user.updateEmail`
 */
const clientCommon: APIClientCommon = {
  [authKey]: undefined,
  [tokenKey]: undefined,
  /** Checks to see if the user is logged in
   * Note that this will return true even if
   * the user's session has expired.
   */
  isAuthenticated: async () => {
    if (clientCommon[tokenKey]) {
      return true;
    }
    const auth = await getAuth();
    const token = await auth.currentUser?.getIdToken();
    clientCommon[authKey] = auth;
    clientCommon[tokenKey] = token;
    return !!token;
  },
  getToken: () => {
    /* MPR, 2022/8/19: note that we do _not_ invoke isAuthenticated here.
     * it is the responsibility of the consumer to ensure that this token
     * exists before reading it. Additonally, it not being present is not
     * explicitly an error - it may be expected. */
    return clientCommon[tokenKey];
  },
  logOut: async (shouldRedirect = true) => {
    const auth = await getAuth();
    auth.signOut();
    document.cookie = "";
    localStorage.clear();
    sessionStorage.clear();
    const url = new URL(window.location.href);
    url.searchParams.delete("id");
    if (shouldRedirect) document.location = url.href;
  },
};
const clientMethods = {
  loan: {
    getDetails,
    getLoans,
    getLoan,
  },
  payments: {
    get,
    getPaymentAuthToken,
    importPlaidAccounts,
    createPaymentMethods,
    getPaymentMethods,
    deletePaymentMethod,
    oneTimePayment: sendOneTimePayment,
    setupAutopay,
    updateAutopay,
    cancelAutopay,
    editPaymentMethod,
    verifyPaymentMethod,
  },
  payoff: {
    initPayoffDocument,
    getPayoffDocumentLink,
    downloadPayoffDocument,
  },
  referrals: {
    getDetails: getReferralDetails,
  },
};

const client = {
  ...clientCommon,
  ...clientMethods,
};

export type APIClient = typeof client;

if (typeof window !== "undefined" && stack !== "prod" && stack !== undefined) {
  /* see src/api.los/client.ts for an explanation of what is happening here */
  /* eslint-disable */
  /* @ts-ignore */
  window.tenet = window.tenet || {};
  /* eslint-disable */
  /* @ts-ignore */
  window.tenet.api = window.tenet.api || {};
  /* eslint-disable */
  /* @ts-ignore */
  window.tenet.api.lms = client;
  /* eslint-enable */
}

export default client;
