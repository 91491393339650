/** ******************************************************************************
Confirm Loan Offer
 * uses the /applications/${applicationId}/offers/${offerId} endpoint to confirm a loan offer
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import {
  HTTPConfirmOfferRes,
  LOSAPIResponse,
  OfferAction,
  Partner,
} from "./client.types";

/**
 * Confirms  loan offer for the current application
 * @param applicationId loan application ID for the current application (string)
 * @param offerId loan offer ID (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const confirmOffer: (
  applicationId: string,
  offerId: string,
  partner?: Partner,
) => LOSAPIResponse<HTTPConfirmOfferRes> = async (
  applicationId,
  offerId,
  // partner,
) => {
  const action = { action: OfferAction.Confirm };
  const response = await makeRequest<HTTPConfirmOfferRes>(
    HTTPMethod.PUT,
    restPaths.application(applicationId).offers.confirm(offerId),
    {
      body: action,
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default confirmOffer;
