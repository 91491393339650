const fontWeights = {
  regular: 400,
  bold: "bold",

  // Named aliases for font weights.
  body1: "$regular",
  body2: "$regular",
  body3: "$regular",
  heading1: "$regular",
  heading2: "$regular",
  heading3: "$regular",
  label: "$regular",
};

export default fontWeights;
