import { useEffect, useState } from "react";

/**
 * The ClientOnly component is a simple wrapper that conditionally returns its
 * children in only a browser environment (ie, an environment where the `window`
 * global object exists). This allows its child content to only be mounted and
 * rendered only on the client, and bypass any server-side rendering, which may
 * be undesirable for certain use cases.
 */
const ClientOnly = ({ children }: { children?: React.ReactNode }) => {
  const [windowType, setWindowType] = useState("undefined");

  useEffect(() => {
    if (typeof window !== windowType) {
      setWindowType(typeof window);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return windowType === "undefined" || !children
    ? null
    : (children as JSX.Element);
};

export default ClientOnly;
