import { ChangeEvent, createElement, useCallback, useState } from "react";
import { PlaidAccount } from "react-plaid-link";

import Divider from "#components/base/Divider/Divider";

import * as css from "./PaymentsAccountSelector.styles";
import type { Props } from "./PaymentsAccountSelector.types";
import { AccountRenderer } from "./PaymentsAccountSelector.util";

export default function PaymentsAccountSelector({
  accounts,
  onChange,
  multi = true,
  className,
  selectedId,
  renderAccount,
}: Props) {
  const [selectedAccounts, updateSelectedAccounts] = useState<PlaidAccount[]>(
    () => {
      if (selectedId) {
        const account = accounts?.find(({ id }) => id === selectedId);

        if (account) {
          return [account];
        }
      }

      return [];
    },
  );

  const onCheckChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      account: PlaidAccount,
      checked?: boolean,
    ) => {
      let newAccounts;
      if (multi) {
        if (!checked) {
          newAccounts = [...selectedAccounts, account];
        } else {
          const indexOfAccount = selectedAccounts.findIndex(
            (acc) => acc.id === account.id,
          );
          newAccounts = selectedAccounts.splice(indexOfAccount, 1);
        }
      } else {
        newAccounts = selectedAccounts?.[0]?.id === account.id ? [] : [account];
      }
      updateSelectedAccounts(newAccounts);

      if (typeof onChange === "function") {
        onChange(newAccounts);
      }
    },
    [selectedAccounts, onChange, multi],
  );

  return (
    <ul css={css.accounts} className={className ?? undefined}>
      {accounts?.map((account, i) => {
        const props = {
          account,
          checked: selectedAccounts.some((acc) => acc.id === account.id),
          onChange: onCheckChange,
        };

        return (
          <li key={account.id} css={css.account}>
            {renderAccount
              ? renderAccount({ ...props.account, ...props })
              : createElement(AccountRenderer, { ...props.account, ...props })}

            {accounts.length !== i + 1 ? <Divider /> : null}
          </li>
        );
      })}
    </ul>
  );
}
