import { forwardRef } from "react";

import { flex } from "#styles";

import * as css from "./Button.styles";
import { Component as ComponentType, Props } from "./Button.types";

/**
 * Buttons allow users to take actions, and make choices, with a single tap.
 *
 * When a `ref` prop is provided, it will be forwarded to the root element. Any
 * other properties supplied will be provided to the root element (ie, the `as`
 * prop value). This includes all appropriate HTML attributes or aria tags. This
 * component may have it's underlying DOM customized via the 'as' prop.
 */
const Button: ComponentType = forwardRef(
  <T extends React.ElementType = "button">(
    {
      as,
      type,
      variant = "primary",
      size,
      width,
      "aria-label": ariaLabel,
      ...otherProps
    }: Props<T>,
    ref?: PolymorphicRef<T>,
  ) => {
    const Component = as || "button";
    const fallbackType = Component === "button" && !type ? "button" : undefined;

    return (
      <Component
        css={[flex.flexCenter, css.button]}
        cssProps={{ variant, size, width }}
        ref={ref}
        type={type || fallbackType}
        {...otherProps}
        aria-label={ariaLabel}
      />
    );
  },
);

export default Button;
