/** ******************************************************************************
Get Active Loan Application
 * uses the /applications/${applicationId} endpoint to get loan application
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPLoanApplicationResponse, LOSAPIResponse } from "./client.types";

/**
 * Gets the active loan application for current application
 * @param applicationId loan application ID for the current application (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const getApplication: (
  applicationId: string,
) => LOSAPIResponse<HTTPLoanApplicationResponse> = async (applicationId) => {
  const response = await makeRequest<HTTPLoanApplicationResponse>(
    HTTPMethod.GET,
    restPaths.application(applicationId).get,
    {},
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default getApplication;
