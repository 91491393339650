import { forwardRef } from "react";

import Dropdown from "#components/base/Dropdown/Dropdown";
import Text from "#components/base/Text/Text";
import { flex, layout } from "#styles";

import * as css from "./DropdownField.styles";
import type { Props } from "./DropdownField.types";

const DropdownField = forwardRef<HTMLSelectElement, Props>(
  (
    {
      className,
      errors,
      label,
      name,
      variant = "primary",
      "aria-label": ariaLabel,
      DownChevron,
      ...otherProps
    },
    ref,
  ) => {
    const hasError = !!errors?.[name];
    return (
      <div
        className={className}
        css={[flex.flexStartVertical, css.root]}
        cssProps={{ variant }}
      >
        {label &&
          (typeof label === "string" ? (
            <Text as="label" htmlFor={name} variant="label">
              {label}
            </Text>
          ) : (
            label
          ))}

        <div css={[flex.flexStartVertical, layout.gap(4)]}>
          <Dropdown
            error={hasError}
            name={name}
            ref={ref}
            {...otherProps}
            aria-label={ariaLabel}
            DownChevron={DownChevron}
          />

          {hasError && (
            <Text color="textNegative" variant="body2">
              {errors?.[name]?.message}
            </Text>
          )}
        </div>
      </div>
    );
  },
);

export default DropdownField;
