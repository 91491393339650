/** ******************************************************************************
Get All Loan Application
 * Gets all applications associated with the current user, including vehicles
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPLoanApplicationsResponse, LOSAPIResponse } from "./client.types";

const getApplications: () => LOSAPIResponse<HTTPLoanApplicationsResponse> =
  async () => {
    const response = await makeRequest<HTTPLoanApplicationsResponse>(
      HTTPMethod.GET,
      restPaths.applications.get,
      {},
      true,
    );
    if (!response.error) {
      return { response, error: null };
    }
    return { response: null, error: response };
  };

export default getApplications;
