/** ******************************************************************************
Get Referral Details
 * gets the info for a user to make a referral
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPReferralDetailsResponse, LMSAPIResponse } from "./client.types";

const getDetails: () => LMSAPIResponse<HTTPReferralDetailsResponse> =
  async () => {
    const response = await makeRequest<HTTPReferralDetailsResponse>(
      HTTPMethod.GET,
      restPaths.referrals.get,
      {},
      true,
      "lms",
    );
    if (!response.error) {
      return { response, error: null };
    }
    return { response: null, error: response };
  };

export default getDetails;
