/** ******************************************************************************
Update User First Name, Last Name, and Date of Birth
 * uses the /user/dfl (update user) endpoint to update
 * a user's First Name, Last Name, and Date of Birth
 ******************************************************************************* */

import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPUpdateUserRes, LOSAPIResponse } from "./client.types";

/**
 * Updates/sets a user's first name, last name, and date of birth
 * @deprecated Replaced with applicant.updateNameAndDOB in Digifi
 * @param firstName first name to update to (string)
 * @param lastName last name to update to (string)
 * @param dateOfBirth date of birth to update to (this is of type Date NOT string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const updateNameAndDob: (
  firstName: string,
  lastName: string,
  dateOfBirth: Date,
) => LOSAPIResponse<HTTPUpdateUserRes> = async (
  firstName,
  lastName,
  dateOfBirth,
) => {
  const response = await makeRequest<HTTPUpdateUserRes>(
    HTTPMethod.PUT,
    restPaths.user.updateNameAndDob,
    {
      body: { firstName, lastName, dateOfBirth },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};
export default updateNameAndDob;
