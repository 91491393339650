import { DocumentPurpose } from "#api.los/application.getTasks";
import { HTTPMethod } from "#api.shared/client.types";
import makeRequest from "#api.shared/makeRequest";
import { restPaths } from "#constants/restPaths";

import { HTTPCreateTaskDocUploadLink, LOSAPIResponse } from "./client.types";
/**
 * Gets tasks for current application
 * @param applicationId loan application ID for the current application (string)
 * @returns an object containing response and error properties (HTTPResponse | null)
 */
const createTaskDocUploadLink: (
  applicationId: string,
  taskId: string,
  contentLength: number,
  docType: string,
  docPurpose: DocumentPurpose,
) => LOSAPIResponse<HTTPCreateTaskDocUploadLink> = async (
  applicationId,
  taskId,
  contentLength,
  docType,
  docPurpose,
) => {
  const response = await makeRequest<HTTPCreateTaskDocUploadLink>(
    HTTPMethod.POST,
    restPaths.document(applicationId).createTaskDocUploadLink,
    {
      body: {
        taskId,
        contentLength,
        docType,
        docPurpose,
      },
    },
    true,
  );
  if (!response.error) {
    return { response, error: null };
  }
  return { response: null, error: response };
};

export default createTaskDocUploadLink;
