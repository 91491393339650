import { Dialog } from "@headlessui/react";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";

import { Close } from "#components/base/Icon/Icon";
import Transition from "#components/util/Transition/Transition";
import useModalContext from "#hooks/useModalContext/useModalContext";
import usePreventScroll from "#hooks/usePreventScroll/usePreventScroll";
import { flex, layout, transition } from "#styles";

import s from "./Modal.module.scss";
import * as css from "./Modal.styles";
import { Props } from "./Modal.types";

const Modal = ({
  children,
  className,
  footer,
  header,
  width,
  onClose,
  manualClose,
  // Used to account for weird behavior on mobile modals. Please deprecate this component as soon as possible.
  // Only triggered on X button click.
  forceClose,
  open = false,
  variant = "primary",
  inlineHeader,
  ...otherProps
}: Props) => {
  const [delta, setDelta] = useState(0);
  const [lastModalEl, setLastModalEl] = useState<HTMLDivElement>();
  const handlers = useSwipeable({
    onSwipedDown: () => {
      onClose?.(false);
      manualClose?.(false);
      setDelta(0);
      forceClose?.(false);
    },
    onSwiping: ({ deltaY }) => {
      setDelta(Math.max(Math.round(deltaY), 0));
    },
  });
  const { unshiftModalEl, removeModalEl } = useModalContext();

  // Set the current modalʼs containing element on the ModalProvider. Any
  // component can `useModalContext()` and then get access to the active modalʼs
  // DOM ref. This is useful for components like `Tooltip`s, `Combobox`s,
  // `Picker`s, or any others that need to be rendered inside the same React
  // Portal as their modal parent.
  const innerRef = (modalEl: HTMLDivElement) => {
    if (modalEl === null) {
      removeModalEl(lastModalEl);
    } else {
      unshiftModalEl(modalEl);
    }

    setLastModalEl(modalEl);

    return () => removeModalEl(lastModalEl);
  };

  const handleCloseButtonClicked = () => {
    if (manualClose) {
      manualClose(false);
    } else {
      handleClose();
    }
    forceClose?.(false);
  };

  const handleClose = () => {
    setDelta(0);
    onClose?.(false);
  };

  usePreventScroll(open);

  // this provides a default focusable element. We might want to override this. Maybe not.
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  /**
   * MT - Oh boy, for some reason the usePlaidIntegration hook on the PaymentsAutoPaymentsModal is causing the body.style.overflow to be set
   * to "hidden" when redirecting away from the Dashboard, which locks scrolling. This is most likely a Plaid bug, not a Tenet bug,
   * but this cleanup function at least overrides the style change that is being forced by the Plaid integration and unlocks scrolling.
   * We need the useEffect on this page (not the PaymentsAutoPaymentsModal), because we cannot conditionally render a useEffect hook (rules of hooks),
   * and this did not work in the parent components either.
   */
  useEffect(() => {
    return () => {
      if (document) {
        document.body.style.overflow = "";
      }
    };
  }, []);

  return (
    <Transition show={open}>
      {() => (
        // Children are specified as a function in order to prevent the
        // `Transition` component from clobbering the ref value.
        <Dialog
          css={[flex.flexCenter, layout.fixedFill, css.dialog]}
          onClose={handleClose}
          open={open}
          ref={innerRef}
          initialFocus={closeButtonRef}
          {...otherProps}
        >
          <Transition.Child>
            <Dialog.Overlay
              css={[layout.absoluteFill, transition.fade, css.overlay]}
            />
          </Transition.Child>

          <Transition.Child>
            <div
              className={className}
              css={[
                flex.flexStartVertical,
                variant === "primary" ? transition.modalShow : transition.fade,
                css.modalContainer,
              ]}
              cssProps={{ variant }}
              style={
                delta ? { transform: `translateY(${delta}px)` } : undefined
              }
            >
              <div
                className={classNames(s.modalHeaderWrapper, {
                  [s.modalHeaderInlineShift]: inlineHeader,
                })}
              >
                <button
                  className={s.headerCloseButton}
                  aria-label="Close Dialog"
                  css={css.closeButtonMobile}
                  cssProps={{ variant }}
                  onClick={handleCloseButtonClicked}
                  type="button"
                  {...(variant === "primary" ? handlers : {})}
                  ref={closeButtonRef}
                >
                  <Close size={24} />
                </button>

                <div css={css.headerContainer} cssProps={{ variant }}>
                  <Dialog.Title
                    css={[flex.flexGrow, flex.alignCenter, flex.flexCenter]}
                  >
                    {header &&
                      (typeof header === "function"
                        ? header({
                            onClose: handleCloseButtonClicked,
                            open,
                          })
                        : header)}
                  </Dialog.Title>

                  <button
                    className={s.headerCloseButton}
                    aria-label="Close Dialog"
                    css={css.closeButtonDesktop}
                    // style={{
                    //   ...(!!forceClose && {
                    //     display: "flex",
                    //   }),
                    // }}
                    cssProps={{ variant }}
                    onClick={handleCloseButtonClicked}
                    type="button"
                    data-cy="close-button"
                  >
                    <Close size={24} />
                  </button>
                </div>
              </div>

              <div
                className="global-Modal-Child_Wrapper"
                style={{ width, maxWidth: "100%" }}
                css={css.modalBody}
                cssProps={{ variant }}
              >
                {children}
              </div>
              <div css={[css.footerContainer]} cssProps={{ variant }}>
                {footer && typeof footer === "function"
                  ? footer({
                      onClose: !manualClose ? handleClose : manualClose,
                      open,
                    })
                  : footer}
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      )}
    </Transition>
  );
};

export default Modal;
